/* *::before{
    margin: 0;
    box-sizing: border-box;
} */

@media screen and (max-width:1970px){


    .nav{
        z-index: 1;
    }
    .nav li{
        list-style: none;
        width:fit-content;
        border-radius: 17px;
        padding: 4px 10px;
      
        display: flex;
    }
    .dash_sidepart li a{
        width:340px;
        padding: 6px 22px;
        display: flex;
        border-radius: 17px;

        justify-content: space-between;
    }
    .nav li  a span{
        margin-left: 6px;
        color:white;
        width: 320px;
        font-size: 1.4rem;
    
    }
    a{
        text-decoration: none;
        color:rgb(211, 207, 207);
    }
    .nav{
     display: flex; 
       background-color: rgba(55, 55, 56, 0.726);
        height:100vh;
        padding: 30px 0 0 20px;
        width: 470px;
    }
    .nav li a:hover{
        background-color: rgb(110, 107, 106);
        
    }
    .nav ul li:first-child img{
        width:72%;
        height: 63px;
        margin-left: 9px;
    }
    
    .nav_toggler div{
        width:3rem;
        height: 0.2rem;
        margin: 0.4rem;
        transition: 0.4s ease-in;
    }
    .nav ul li:first-child:hover{
        background-color:transparent;
        }
    .nav_toggler{
        cursor: pointer;
        display: none;
        margin-right: 20px;
    }
    .sidebar-icons{
        margin-top: 0px;
    }
    .nav_menu{
        display: flex;
    flex-direction: column;
        
        gap:21px;
        width: 210px;
    
         padding: 0px 30px 20px 30px ; 
      height: 92%;
        border-radius: 8px;
    }
    .nav li span:hover{
    color:orange;
    transition:color 0.6s ease;
    cursor: pointer;
    }
    .vanish{
        display: none;
    }
    .nav ul li:first-child{
        display: flex;
        justify-content: center;
    }
    .dashicon{
        width:48px;
        height: 70px;
        align-self: center;
        margin-right: 17px;
    }
    .sidebar-icons{
        width:34px;
        height: 46px;
    }
    .dash_sidepart li:first-child a:hover{
        background-color: transparent;
        cursor: default;
    }
    }


@media screen and (max-width:1370px){


.nav{
    z-index: 1;
}
.nav li{
    list-style: none;
    width:205px;
    border-radius: 17px;
    padding: 4px 10px;
    font-size: 0.8rem;
    display: flex;
}
.dash_sidepart li a{
    width:200px;
    padding: 6px 22px;
    display: flex;
    border-radius: 17px;
    
}
.nav li  a span{
    margin-left: 6px;
    color:white;
    width: 220px;
    font-size: 0.8rem;

}

a{
    text-decoration: none;
    color:rgb(211, 207, 207);
}
.nav{
 display: flex; 
   background-color: rgba(55, 55, 56, 0.726);
    height:100vh;
    padding: 30px 0 0 0px;
    width: 270px;
}
.dash_sidepart li a:hover{
    background-color: rgb(110, 107, 106);
    
}
.nav ul li:first-child img{
    width:72%;
    height: 63px;
    margin-left: 9px;
}

.nav_toggler div{
    width:3rem;
    height: 0.2rem;
    margin: 0.4rem;
    transition: 0.4s ease-in;
}
.nav ul li:first-child:hover{
    background-color:transparent;
    }
    .sidebar-icons{
        width:20px;
        height: 26px;
    }
.nav_toggler{
    cursor: pointer;
    display: none;
    margin-right: 20px;
}
.sidebar-icons{
    margin-top: 0px;
}
.nav_menu{
    display: flex;
flex-direction: column;
    
    gap:6px;
    width: 210px;

     padding: 0px 30px 20px 30px ; 
  height: 92%;
    border-radius: 8px;
}
.nav li span:hover{
color:orange;
transition:color 0.6s ease;
cursor: pointer;
}
.vanish{
    display: none;
}
.nav ul li:first-child{
    display: flex;
    justify-content: center;
}
.dashicon{
    width:48px;
    height: 70px;
    align-self: center;
    margin-right: 17px;
}.dash_sidepart li:first-child a:hover{
    background-color: transparent;
    cursor: default;
}
}
@media screen and (max-width:438px){
    .nav_toggler{
        display: block;
        cursor: pointer;
        z-index: 1;
        margin-right: 40px;
    }
    .nav_menu{
        position: absolute;
        top:-40px;
        left: 0;
        padding: 85px 0 0 0;
        background-color: #384358;
        width: 100vw;
        height:93vh;
        flex-direction: column;
        gap:20px;
        align-items: center;
        transform: translateY(-100%);
        transition: 0.5s ease-in;
        z-index: 1;
    
    }
    .nav_menu a{
        color: white;
        
    }
    .nav_menu li span{
        margin-left: 12px;
        color:white;
      
    }
    .nav_menu a:hover{
        margin: 0 0 0 14px;
        transition: 1s ease;
        color:orange;
        
    }
    .vanish{
        display: flex;
    }


.nav_active{
    transform: translateX(0);
}

.toggle .line1{
    transform: rotate(-45deg) translate(-4px,5px);
}
.toggle .line2{
    opacity: 0;
}
.toggle .line3{
    transform: rotate(45deg) translate(-4px,-5px);
}
   
.sidebar-icons{
    width:30px;
    height:30px;

}
}


@media screen and (max-width:328px){
    .nav_menu li span{
       font-size: 0.7rem;
    }
    .nav_menu{
       height:37rem;
        padding: 65px 0 0 0;
}}