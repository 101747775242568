

@media screen and (max-width:2225px){
    .info-header-text{
        width:546px;
        font-size:2.1rem;
    }
.info-header-text h1{
    font-size: 4.2rem;

}
.info-last-div div{
    width: 80%;
}
.infoheader{
    height: 54vh;
    width: 100vw;
    display: flex;
    align-items: center;
    padding-left:75px;
    color:white;
}
.info-header-text{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 33%;
    width:830px;
    text-align: left;
}
.info-header-text h1 span{
    border-bottom: 1px solid black;
    padding-bottom: 4px;
}
}


@media screen and (max-width:1984px){
    .info-header-text{
        width:546px;
        font-size:1.6rem;
    }
.info-header-text h1{
    font-size: 3.2rem;

}
.info-last-div div{
    width: 80%;
}
.infoheader{
    height: 62vh;
    width: 100vw;
    display: flex;
    align-items: center;
    padding-left:75px;
    color:white;
}
.info-header-text{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 33%;
    width:690px;
    text-align: left;
}
.info-header-text h1 span{
    border-bottom: 1px solid black;
    padding-bottom: 4px;
}
}



@media screen and (max-width:1655px){
    .info-header-text{
        width:466px;
        font-size:1.2rem
    }
.info-header-text h1{
    font-size: 2.2rem;

}

}



    @media screen and (max-width:1365px){    
    .info-header-text h1{
        font-size: 1.4rem;
    }
    .infoheader{
    height: 70vh;
    width: 100vw;
    display: flex;
    align-items: center;
    padding-left:75px;
    color:white;
}
.info-header-text{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 33%;
    width:630px;
    font-size: 1rem;
    text-align: left;
}
.info-header-text h1 span{
    border-bottom: 1px solid black;
    padding-bottom: 4px;
}
    }



@media screen and (max-width:482px){
    .infoheader{
    height: 275px;
    padding: 0;
    }

    .info-header-text{
        width:386px;
        font-size:0.77rem
    }
    .infoheader{
        padding:0  35px;
       
    }
}
@media screen and (max-width:340px){
    .infoheader{

    height: 175px;
    padding: 0 30px;
    }

    .info-header-text{
        width:336px;
        font-size:0.6rem
    }
    
}