@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Kalam:wght@300;400;700&family=Nanum+Myeongjo&display=swap');


@media screen and (max-width:2225px){

    .homenav{
        height: 123px;
        width:98vw;
        background-color: transparent;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 42px;
        padding-right: 22px;
        position: absolute;
        background-color: transparent;
    }
    
.homenav .logoWithwhite img, .nav_acc img {
    width:274px;
    height: 245px;
}
.homenav .navigation_links_list{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    width:548px;
    
    padding:22px 61px ;
    color:black;
    border-radius: 62px;
    font-size: 1.1rem;
} 
.navigation_links_list a{
    color:black;
   
}
.navigation_links_list a:hover{
   color: #000398;
   
}
.navigation_account_and_lang{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width:162px;
    
}

.navigation_account_and_lang .navIcons{
   
    width:42px;
    height: 34px;
cursor: pointer;
}
.navigation_account_and_lang .navIcons {
    transition: filter 0.3s ease; /* Add transition for smooth effect */
  }
  
  .navigation_account_and_lang .navIcons:hover {
    cursor: pointer;
    filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.683)); /* Adds a shadow effect */
  }
.nav_linkAndAcco{
    display: flex;
    justify-content: space-between;
    width:65%;
padding-right: 42px;
}
.displayLists{
    display: flex;
    flex-direction: column;
    gap:22px;
    background-color: saddlebrown;
    width:100vw;
    height: 100vh;
    z-index: 220;
    transform: translateY(-100%);
    transition: 0.5s ease-in;
}
.logoWithblack img{
    width:220px;
    height: 186px;
    margin-bottom: -4px;
}

}

@media screen and (max-width:1780px){
    .homenav{
        height: 83px;
        
        padding: 10px 34px;
        padding-right: 16px;
    }
    .homenav .logoWithwhite img{
        width:226px;
        height: 180px;
    }
    .homenav .navigation_links_list{
        width:548px;
        padding:19px 61px ;
        font-size: 0.9rem;
    } 
    .navigation_account_and_lang{
        display: flex;
        justify-content: space-between;
        width:170x;
    }
    .navigation_account_and_lang .navIcons{
   
        width:32px;
        height: 32px;
    }

    .nav_linkAndAcco{
        width:68%;
    }
    
   
}

@media screen and (max-width:1654px){
    .nav_linkAndAcco{
        width:72%;
    }
    .accountname{
        display: flex;
        align-items: center;
    }
    .accountname a{
        font-size: 0.9rem;
      }
      .navigation_account_and_lang{
        display: flex;
        justify-content: space-between;
        width:117px;
    }
 .navigation_account_and_lang .navIcons{
   
        width:28px;
        height: 22px;
       
    }
    .logoWithblack img {
        margin-bottom: -8px;
    }
}
@media screen and (max-width:1476px){
    .logoWithblack img{
        margin-bottom: -10px;
        width:194px;
        height: 168px;

    }
}
@media screen and (max-width:1390px){
    
    .navigation_account_and_lang .navIcons{
        width:20px;
        height: 20px;
    }
    .navigation_account_and_lang{
        width:89px;
        
    }
    
    .homenav .navigation_links_list{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width:488px;
        
        padding:12px 42px ;
        color:black;
        border-radius: 62px;
        font-size: 0.7rem;
        margin-left:40px;
    } 
    .navigation_links_list a{
        color:black;
       
    }
    .homenav .logoWithwhite img{
        width:144px;
        height: 136px;
    }
    .nav_linkAndAcco{
        width:74%;
    }
    .navIcons{
        width:26px;
        height: 18px;
        cursor: pointer;
    }
  
    .logoWithblack img{
        margin-bottom: -18px;
        width:150px;
        height: 142px;

    }
}

@media screen and (max-width: 870px) {
    .homenav{
        height: 88px;
        padding: 0 14px;
    }
    .navigation_account_and_lang img{
        width:20px;
        height: 20px;
    }
    .navigation_account_and_lang{
        width:84px;
        margin-right: -34px;
        
    }
    .homenav .navigation_links_list{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width:380px;
        
        padding:16px 30px ;
        color:black;
        border-radius: 62px;
        font-size: 0.7rem;
        margin-left: -2px;
    } 
    .navigation_links_list a{
        color:black;
       
    }
    .homenav .navigation_logo img{
        width:134px;
        height: 148px;
    }
  }


  @media screen and (max-width:482px){
    .homenav{
        
        padding: 0 16px;
        padding-left:  4px;
      
            height: 84px;
    }
    .logout-cont{
        
        top:25%;
        left:20%;
        width: 265px;
        height: 140px;
        
        padding: 0 25px 10px 0px ;
        
        font-size: 0.7rem;
    }
    
    .navigation_account_and_lang img{
        width:33px;
        height: 33px;
    }
    .navigation_account_and_lang{
        width:224px;
        align-self: center;
        margin-top: 112px;
    }
   
    .homenav .navigation_links_list{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width:100%;
        height: 47%;
        
        background-color: transparent;
        padding:62px 0px ;
        color:black;
        border-radius: 62px;
        border: none;
        font-size: 1rem;
    } 
    .navigation_links_list a{
        color:black;
       
    }
    .homenav .navigation_logo img{
        width:115px;
        height: 114px;
        margin-bottom: -12px;
    }
      .displayLists{
        display: flex;
        flex-direction: column;
        gap:22px;
        background-color: #DAD2D8 ;
        width:100vw;
        height: 100vh;
        z-index:60;
        position: absolute;
        top:0;
        left:0;
        animation: slideDown 1s both;
        
    }.displayLists .accountname a{
        color:rgb(129, 129, 129)
    }
    @keyframes slideDown {
        0% {
          transform: translateY(-100%);
          opacity: 0;
        }
        100% {
          transform: translateY(0);
          opacity: 1;
        }
      }/* Slide up animation */


    .menu-toggle{

z-index: 22;
width: 37px;

height: 32px;
background-color: transparent;
border: none;
display: flex;
justify-content: center;
align-items: center;
    }
    .navToggleBtn{
        width: 26px;
        height: 26px;
    }
    .Navopenbtn{
        width: 20px;
        height: 20px;
    }
    .closeBar{
position: absolute;
top:18px;
right:25px;
transition: ease 2s;
    }
  }
  @media screen and (max-width:482px){
    .homenav{
        
        padding: 0 12px;
        padding-left:  4px;
        height: 84px;
    } 
    .navigation_account_and_lang{
        width:204px;
    }
   
}
 









@media screen and (max-width:345px){
    .homenav{
        padding: 0 12px;
        padding-left:  4px;
       
    }
    .homenav .navigation_links_list{
        padding-top: 30px;
        
        height: 58%;
    }
    .logout-cont{
        
        top:25%;
        left:10%;
        width: 265px;
        height: 140px;
        padding: 0 20px 10px 0px ;
        font-size: 0.7rem;
    }
    .navigation_account_and_lang img{
        width:24px;
        height: 24px;
    cursor: pointer;
    }
    .navigation_account_and_lang{
        width:160px;
        margin-right: 6px;
    }
}




