/* App.css */

.sendmessagecontainer {
    max-width: 400px;
    margin: auto;
    
    background-color: #f0f0f0;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .send-messtable{
    /* background-image: url("../../components/images/Newfolder/glass.jfif");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; */
    
   background-color: rgb(255, 255, 255);
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    width:88%;

    padding-bottom: 0px;
    overflow-y: hidden;
  }
  .topsendmess{
    z-index: 40;
    background-color: rgba(255, 255, 255, 0.632);
    width: 100%;
    height: 100%;
z-index: 90;
  }
  .title {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
  }
  
  .input-container {
    margin-bottom: 15px;
  }
  
  .input-container .label {
    display: block;
    margin-bottom: 5px;
    color: #555;
  }
  
  .input-container div .input {
    width: 68%;
    padding:4px  8px ;
    font-size: 14px;
    margin-bottom: 13px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  .input-container .messagebox{
height:120px;
width: 514px;
grid-column-start: 1;
grid-column-end: 4;
border-radius: 8px ;
padding: 10px 12px;
/* border: 1px solid rgb(44, 156, 156);;; */
margin-left: 30px;
outline: none;
  }
  .add-button {
    background-color: #4caf50;
    color: #fff;
    padding: 8px;
    cursor: pointer;
    border-radius: 4px;
    border: none;
    width:80px;
    display: none;
    
  }
  
  .sendmess-button {
    background-color: black;
    color: #fff;
    padding: 10px;
    cursor: pointer;
    border-radius: 4px;
    font-size: 0.8rem;
    border: none;
    width: 112px;
    height: 33px;
    align-self: center;
    position: absolute;
    bottom:0px;
    right:40px
  }
  .sendmess-button:hover{
    border-color: white;
    transition: 0.4s;
  }
  .messagesucc{
    color:white;
    background-color: green;
    padding: 10px 12px;
    border-radius: 8px;
    position: absolute;
    top:10%;
    left:50%;
  }
  .headAndsearch2{
    padding: 10px 0 10px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width:100%
  }
  .dash-tables2{
    position: absolute;
  top:14%;
  left:25%;
  z-index: 92;
  width: 62%;
  }
  .exportAndClose{
    display: flex;
    gap:20px;
    width:20%
  }
  .dash-tables2 table td{
    padding: 0 40px;
    max-width:309px
  }
  

  .inputForm-container{
    display: grid;
    grid-template-columns: 322px 322px;
    grid-template-rows: 112px 112px 112px;
    position: relative;
  }

  .inputForm-container .input-container{
    width: 100%;
  }

  .inputForm-container .input{
width:230px;
padding: 4px 8px;
border-radius: 0px;
border:none;
border-bottom: 2px solid  rgb(44, 156, 156);;
outline: none;
  }

  .dashboard-main-shortcuts2 ul  {
    width:60%;
   
}

.sendFormbottom{
  width: 100%;
  background-color: rgb(44, 156, 156);
  height: 12px;
}

