.smp{
    background-color: saddlebrown;
}
.pdfviewerpage{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 0 20px 61px 20px;
}
.pdfviewbtn{
    background-color: blue;
    border-radius: 8px;
    padding: 4px 12px;
    width:90px;
    margin-right: 34px; 
    cursor: pointer;
}
.pdfviewbtn a{
    color: white;
}
.pdfviewbtn:hover{
    background-color: rgb(2, 2, 127);
}