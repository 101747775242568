
@import url('https://fonts.googleapis.com/css2?family=Prata&display=swap');

@media screen and (max-width:2225px){


    .aboutcomp{
        display:flex;
        flex-direction:column;
        background-color: white;
        padding: 100px 105px;
        
    gap:80px;
    
    }
    .about-top, .about-bottom{
        display: flex;
        gap:75px;
        align-items: center;
    }
    .about-top img, .about-bottom img{
    width: 770px;
    height: 435px;
    
    }
    
    .about-bottom_text, .about-top_text{
        width:770px;
        display:flex;
        flex-direction:column;
        gap:17px;
        text-align: start;
        align-items: flex-start;
        font-size:1.2rem;
    

}

    .about-bottom_text h1, .about-top_text h1{
        font-family: 'Prata', serif;    
    }

     .btn{
        background-color:#03045E;
        width:170px;
        height: 66px;
        font-size: 25px;
        border-radius: 8px;
        border: none;
        color:white;
        text-decoration:none;
        display:flex;
        justify-content:center;
    align-items:center;
   
    }
    
    .btn:hover{
        cursor:pointer;
        color:white;
        background-color: black;
      }
    
      .about-wiggle{
          width: -moz-fit-content;
          width: -moz-fit-content;
width: fit-content;
        position: absolute;
        top:70rem;
        left:-60px;
        
        
    
    }

    .about-wiggle img{
        opacity:0.14;
        width:470px;
        height: 400px;
    }
/* 
    .about-bottom p strong{
        color: #03045E;
    } */
    
}


@media screen and (max-width:1800px){
    .about-wiggle{
        width: -moz-fit-content;
        width: -moz-fit-content;
width: fit-content;
      position: absolute;
      top:62rem;
      left:-60px;
      
  
  }
  .about-wiggle img{
      opacity:0.14;
      width:470px;
      height: 400px;
  }
}
@media screen and (max-width:1690px){
    .about-wiggle{
      top:54rem;
      left:-60px;
      
  
  }
  .about-wiggle img{
      height: 383px;
  }
}


@media screen and (max-width:1519px){


.aboutcomp{
    display:flex;
    flex-direction:column;
    padding: 100px 105px;
    
gap:80px;

}
.about-top, .about-bottom{
    display: flex;
    gap:75px;
    align-items: center;
}
.about-top img, .about-bottom img{
width: 500px;
height: 315px;
}
.about-bottom_text, .about-top_text{
    width:500px;
    display:flex;
    flex-direction:column;
    gap:17px;
    text-align: start;
    align-items: flex-start;
    font-size:0.9rem;
}
.about-bottom_text h1, .about-top_text h1{
    font-family: 'Prata', serif;

}
 .btn{
    width:130px;
    height: 40px;
    font-size: 20px;
    border-radius: 8px;
    border: none;
    color:white;
    padding-top:2px;
    text-decoration:none;
    display:flex;
    justify-content:center;
align-items:center;
}




  .about-wiggle{
      width: -moz-fit-content;
      width: -moz-fit-content;
width: fit-content;
    position: absolute;
    top:45rem;
    left:-60px;
    

}
.about-wiggle img{
    opacity:0.14;
    width:270px;
    height: 340px;
}
}  


@media screen and (max-width:1530px){

    .aboutcomp{
        display:flex;
        flex-direction:column;
        padding: 100px 105px;
        
    gap:80px;
    
    }
    .about-top, .about-bottom{
        display: flex;
        gap:165px;
        align-items: center;
    }
    .about-top img, .about-bottom img{
    width: 560px;
    height: 345px;
    }
    .about-bottom_text, .about-top_text{
        width:500px;
        display:flex;
        flex-direction:column;
        gap:17px;
        text-align: start;
        align-items: flex-start;
        font-size:1.2rem;
    }
    .about-bottom_text h1, .about-top_text h1{
        font-family: 'Prata', serif;
    
    }


    .about-wiggle{
        width: -moz-fit-content;
        width: -moz-fit-content;
width: fit-content;
      position: absolute;
      top:49rem;
      left:-60px;
      
  
  }
  .about-wiggle img{
      height: 323px;
  }
}
@media screen and (max-width:1480px){
    .about-wiggle{
        width: -moz-fit-content;
        width: -moz-fit-content;
width: fit-content;
      position: absolute;
      top:46rem;
      left:-60px;
      
  
  }
  .about-wiggle img{
      height: 333px;
  }.about-bottom_text, .about-top_text{
    
    font-size:0.9rem;
}


}
@media screen and (max-width:1380px){
    .about-top img, .about-bottom img{
        width: 486px;
        height: 265px;
        }
  .about-wiggle img{
      height: 333px;
  }
  .about-top, .about-bottom{
    display: flex;
    gap:95px;
    align-items: center;
}
.aboutcomp{
gap:40px;

}.about-bottom_text, .about-top_text{
    width:480px;
   
}
.btn{
    background-color:#03045E;
    width: -moz-fit-content;
    width:fit-content;
    height: fit-content;
    padding: 8px 38px;
    font-size: 0.9rem;
    
}

}
@media screen and (max-width:835px){
    .aboutcomp{
        display:flex;
        flex-direction:column;
        padding: 80px 65px;
       
    }

    .about-top img, .about-bottom img{
        width: 280px;
        height: 215px;
        }
        .about-bottom_text, .about-top_text{
            width:320px;
            
        }
  }

 
  @media screen and (max-width:482px){
    .about-top, .about-bottom{
        display: flex;
        flex-direction:column;
        gap:50px
        
    }
    .about-wiggle{
        z-index: 30;
        top:53rem;
        left:-50px;
    z-index: 30;
    }
    .about-wiggle img{
        
        width:240px;
        height: 270px;
    }.aboutImage2{
        order: 2;
    }
    .aboutsecText{
        order: 1;
    }
  }


  @media screen and (max-width:392px){
    .about-wiggle{
       top:48rem;
        
    }
  }
@media screen and (max-width:365px){
    .about-top img, .about-bottom img{
        width: 240px;
        height: 215px;
        }
        .about-bottom_text, .about-top_text{
            width:265px;
            
        }
        
.about-bottom_text, .about-top_text{
    font-size: 0.8rem;
}
}