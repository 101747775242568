
.navigation-bar{
    width:calc(100% + 20px) ;
    height: 30px;
    display: flex;
   justify-content: flex-end;
   align-items: center;
   border-bottom: 2px solid rgba(211, 209, 209, 0.765);
   background-color: rgb(240, 240, 240);
   z-index: 4;
   margin-left: -20px;
   
}
.navigation-bar ul{
    display: flex;
    justify-content: space-between;
    width: 110px;
}
.navigation-bar li{
    cursor: pointer;
}
.navigation-bar ul li:hover{
    cursor: pointer;
}
.navigation-bar ul li{
    list-style-type: none;
    font-size: 0.9rem;
    color:rgb(208, 156, 87)
}
.navigation-bar ul li .icons-nav{
width:35px;
height: 20px;
}
.logout-cont{
    position: absolute;
    top:25%;
    left:40%;
    width: 385px;
    height: 140px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
    padding: 0 25px 10px 0px ;
    box-shadow: -4px 4px 8px rgb(184, 182, 182);
    background-color: #e5e2e4 ;
    border-radius: 8px;
    border: 1px solid rgb(182, 180, 180);
    color: rgb(255, 255, 255);
    z-index: 1;
    font-size: 0.9rem;
}
.logout-cont h2{
    border-bottom: 2px solid rgb(52, 52, 52);
    width:80%;
    padding-bottom: 5px;
    align-self: center;
}
.logout-cont div{
    display: flex;
    justify-content: space-between;
    width:120px;
    align-self: flex-end;
    font-size: 0.6rem;
}
.logout-cont div button{
border: 1px solid rgb(196, 194, 194);
padding: 2px 15px;
font-size: 0.6rem;
border-radius: 4px;
}
.logout-cont div a{
    background-color: rgb(152, 152, 152);
    color: white;
    border-radius: 4px;
padding: 2px 15px;

}
.logout-cont div a:hover{
    cursor: pointer;
}
