.footer {
    background-color: black;
    color: white;
    padding: 20px;
  }
  
  .footer-top {
    display: flex;
    justify-content: flex-start;
  }
  
  .footer-logo {
    margin-top: -90px;
    width: 230px;
    height: 300px; /* Adjust the size as needed */
  }
  
  .footer-content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; /* Allow content to wrap on smaller screens */
    margin-top: -80px;
  }
  
  .footer-section {
    flex: 1;
    min-width: 200px; /* Ensure a minimum width for each section */
    padding: 0 20px;
  }
  
  .footer-section h4 {
    margin-bottom: 10px;
    font-size: 2rem;
  }
  
  .footer-section ul , .footer-section li{
    list-style-type: none;
    padding: 0;
    font-size: 1.6rem;
  }
  
  .footer-section ul li {
    margin-bottom: 5px;
  }
  
  .footer-section ul li a {
    color: white;
    text-decoration: none;
  }
  
  .footer-section ul li a:hover,.footer-section li a:hover{
    color: brown;
    cursor: pointer;
  }
  
  .footer-bottom {
    text-align: center;
    margin-top: 20px;
    font-size: 1.3rem;
  }
  
  @media (max-width: 1768px) {
    .footer-section h4 {
        margin-bottom: 10px;
        font-size: 1.2rem;
      }
    .footer-section ul , .footer-section li{
        list-style-type: none;
        padding: 0;
        font-size: 1rem;
      }
    .footer-bottom {
        text-align: center;
        margin-top: 20px;
        font-size: 0.8rem;
      }.footer-logo {
        margin-top: -40px;
        width: 140px;
        height: 180px; /* Adjust the size as needed */
      }
  }
  @media (max-width: 468px) {
    .footer{

      padding-top: 0;/* Center the logo on smaller screens */
    }
    .footer-top {
      justify-content: center; 
    }
  
    .footer-section {
      flex: 1 1 100%; /* Make each section take full width on small screens */
      text-align: center; /* Center text in each section */
      padding: 10px 0;
    }
  
    .footer-content {
      margin-top: -40px;
    }.footer-section h4 {
        margin-bottom: 10px;
        font-size: 1.4rem;
      }
    .footer-section ul , .footer-section li{
        list-style-type: none;
        padding: 0;
        font-size: 1.2rem;
      }
    .footer-bottom {
        text-align: center;
        margin-top: 20px;
        font-size: 0.9rem;
      }.footer-logo {
        margin-top: -25px;
        width: 194px;
        height: 240px; /* Adjust the size as needed */
      }
  }
  