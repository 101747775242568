@media screen and (max-width:1970px){

    .signup-log2 form{
        background-color: rgb(71, 75, 106);
        width: 34%;
       
        border-radius: 8px;
        font-size: 1.1rem;
      }
      .reset-div  input , .inp-btn input {
        font-size: 1.2rem;
        padding: 6px 12px;
      }
      .reset-div  button , .inp-btn button {
        font-size: 1rem;
        padding: 6px 12px;
        height: 38px;
        width:189px;
        cursor:pointer;
      }
    }



@media screen and (max-width:1360px){
    
    .signup-log2 form{
    background-color: rgb(71, 75, 106);
    width: 36%;
   
    border-radius: 8px;
    font-size: 0.9rem;
  }
  .reset-div  input , .inp-btn input {
    font-size: 0.9rem;
    padding: 4px 8px;
  }
  .reset-div  button , .inp-btn button {
    font-size: 0.8rem;
    padding: 6px 12px;
    height: 38px;
    width:189px;
    cursor:pointer;
  }
}

@media screen and (max-width:482px){
    
    .signup-log2 form{
    background-color: rgb(71, 75, 106);
    width: 80%;
   
    border-radius: 8px;
    font-size: 0.9rem;
  }
  .reset-div  input , .inp-btn input {
    font-size: 0.9rem;
    padding: 4px 8px;
    width:90%;
  }
  .reset-div  button , .inp-btn button {
    font-size: 0.8rem;
    padding: 6px 12px;
    height: 38px;
    width:189px;
    cursor:pointer;
  }
}

@media screen and (max-width:378px){
    
  .signup-log2 form{
  background-color: rgb(71, 75, 106);
  width: 80%;
 
  border-radius: 8px;
  font-size: 0.9rem;
}
.reset-div div{
  width: 100%;
}
.reset_inpcode{
  width: 40%;
}
.reset-div  input , .inp-btn input {
  font-size: 0.9rem;
  padding: 4px 8px;
}
.reset-div  button , .inp-btn button {
  font-size: 0.7rem;
  border-radius: 8px;
  border: none;
  padding: 6px 12px;
  height: 38px;
  width:120px;
  cursor:pointer;
}
}

