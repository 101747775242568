.settings-form h2{
    border-bottom: 2px solid grey;
    width: 95%;
    padding: 0px 0 17px 0;
}
.setting-main{
    display: flex;
    flex-direction: column;
    
    height: 84vh;
}
.settings-form {
    width: 79%;
}
.settingsadmin-form{
    margin: 0px 0 25px 0;
    
}
.adminupdayesucc{
   
    border-radius: 8px;
    padding: 7px 18px;
    color:white;
    display: flex;
    gap:40px;
}
.adminupdayesucc span{
    cursor: pointer;
    
}
.add-form-top{
    display: flex;
    gap:40px;
    margin-top: 35px;
}
.add-form-top input,.settings-bottominp input{
    width:170px;
    padding: 10px 13px;
    border-radius: 4px;
    margin-left: 10px;
}
.nav ul li{
    width: 145px;
}
.settings-form form{
    height: 300px;
}
.settings-bottominp{
    width:470px;
    display: flex;
    flex-direction: column;
    gap:30px;
    align-items: flex-start;
    
}
.settings-bottominp div{
    display: flex;
    align-items: center;
}
.add-cus-form-container h2{
    margin-top: 22px;
}
.settings-form form .add-form-top input, .settings-bottominp input{
   height: 7px;
   width: 170px;
  border:1px solid gainsboro ;
  background-color: gainsboro;
 outline: none;
}
.button-setting{
    display: flex;
    gap:130px;
}
.button-setting button{
padding: 7px 18px;
cursor: pointer;
}