.terms{
    width:100%;
    padding: 45px 0 75px 80px;
    display: flex;
    flex-direction: column;
    gap: 70px;
    text-align: left;
}
.terms-header{
    display: flex;
    flex-direction: column;
    gap:25px;
    align-items: flex-start;
    width:870px
}

.terms-header h1{
    font-size: 6rem;
    font-weight: bolder;
}
.term-contents{
    display: flex;
    flex-direction: column;
    gap:40px;
}
.term-contents div{
width:70%;
display: flex;
flex-direction: column;
gap:15px
}
.term-contents div p{
    font-size: 1rem;
    
    }
    

    
@media screen and (max-width:835px){
    .terms-header{
        width:540px
    }
    
    .terms-header h1{
        font-size: 5rem;
    }
}


@media screen and (max-width:395px){
    .terms-header{
        width:220px;
        font-size:0.7rem;
    }
    
    .terms-header h1{
        font-size: 2rem;
        font-weight: bolder;
    }
    
.term-contents div p{
    font-size: 0.8rem;
    
    }
}