@media screen and (max-width:2225px){

    .langaugesel{
        height: fit-content;
    
    }
    .langaugesel_lang{
    position: fixed;
    top:20%;
    left:40%;
    
    
    background-color: rgb(255, 255, 255);
    border-radius: 12px ;
    width:432px;
    height:372px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 200;
    padding: 14px 24px;
    background-image: url('../images/Newfolder/vectorbg.png');
background-position: right;
background-size: cover;
    }
    .langicon{
        margin-left: -84px;
    }
    .langaugesel h2{
        display: flex;
        align-items: center;
        cursor: pointer;
    }
    .langaugesel_lang p{
        color: black;
        font-weight: bold;
        font-size: 2.2rem;
    }
    .lan_closebtn{
        align-self: flex-end;
        background-color: rgb(154, 26, 26);
        padding: 12px 18px;
        width: 140px;
        height: 40px;
        border: none;
        border-radius: 8px;
        cursor: pointer;
        color: white;
    }
    .lan_closebtn:hover{
        background-color: rgb(114, 9, 9);
    }
    .langaugesel_lang div{
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        gap:22px;
    }
    .langaugesel_lang div button{
        background-color: #DAD2D8;
        color: black;
        width: 170px;
        height: 70px;
        font-size: 1.6rem;
        border: none;
        border-radius: 8px;
        cursor: pointer;
        padding: 12px 12px;


}
.langaugesel_lang div button:hover{
    background-color: black;
    color: white;
}
}

@media screen and (max-width:1365px){
    .langaugesel{
    height: fit-content;

}
.langaugesel_lang{

width:266px;
height:262px;
display: flex;
flex-direction: column;
align-items: center;
z-index: 200;
padding: 14px 24px;

}
.langicon{
    margin-left: -70px;
}
.langaugesel h2{
    display: flex;
    align-items: center;
    cursor: pointer;
}
.langaugesel_lang p{
    color: black;
    font-weight: bold;
    font-size: 1.4rem;
}
.lan_closebtn{
    align-self: flex-end;
    padding: 4px 12px;
    width: -moz-fit-content;
    width: -moz-fit-content;
width: fit-content;
    border: none;
    border-radius: 8px;
    cursor: pointer;
}
.langaugesel_lang div{
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    gap:22px;
}
.langaugesel_lang div button{
    
    width: 91px;
    height: 32px;
    font-size: 0.8rem;
    cursor: pointer;
    padding: 8px 12px;
}

@media screen and (max-width:482px){
    .langaugesel_lang{
        position: fixed;
        top:20%;
        left:20%;
        
        padding: 23px 14px;
        }
        .langaugesel_lang div{
           
            margin-left:0;
        }
        
        
}
@media screen and (max-width:380px){
    .langaugesel_lang{
        position: fixed;
        top:20%;
        left:17%;
        
        padding: 23px 14px;
        }
        .langaugesel_lang div{
           
            margin-left:0;
        }
}
@media screen and (max-width:330px){
    .langaugesel_lang{
        position: fixed;
        top:20%;
        left:10%;
        width:240px;
        padding: 23px 14px;
        }
        .langaugesel_lang div{
           
           margin-left:0;
        }
}
}