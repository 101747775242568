.success-message-inalteruser{
    color: white;
    position: absolute;
    top:25%;
    left:50%;
    background-color: rgb(143, 229, 143);
    border: 1px solid rgb(147, 146, 146);
    border-radius: 7px;
    padding: 14px 40px;
     z-index: 97;
   /* #DAD2D8 */
    display: flex;
    flex-direction: column;
    gap:12px;
    align-items: flex-end;
}.success-message-inalteruser p{
    font-size: 1.4rem;
    background-color: transparent;
    color: black;
cursor:auto;
}
.success-message-inalteruser p:hover{
    background-color: transparent;
    cursor: auto;
}
.dash-tablesalterstat{
    width:98%
}
.status-button{
    width:120px
}
.success-message-inalteruser button{
    border-radius: 8px;
    font-size: 1rem;
    padding: 4px 8px;
    cursor: pointer;
    
}
.success-message-inalteruser button:hover{
    background-color: rgb(177, 177, 177);
    color: white;
}

@media screen and (max-width:1470px){

.success-message-inalteruser{
    color: white;
    position: absolute;
    top:25%;
    left:40%;
    background-color: rgb(143, 229, 143);
}
.success-message-inalteruser p{
    font-size: 1rem;
}.success-message-inalteruser button{
    
    font-size: 0.8rem;
    padding: 4px 8px;

}
}