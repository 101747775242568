@media screen and (max-width:1970px){

    .nav-bar{
        height:61px;
        width:55%;
        display: flex;
        justify-content:space-between;
        align-items: center;
        margin-right:40px;
        padding: 5px 40px;
        align-self: flex-end;
    }
    .nav-bar img{
        width:140px;
        height:60px;
    }
    
    .dashboard{
        height: 100vh;
        display: flex;
        flex-direction: column;
        width:100vw;
        padding: 0;
    }
    
    .dashboard_contents{
        display:flex;
        gap:0px;
        padding: 0;
        height: 100%;
        background-color: rgb(240, 240, 240);
    }
    .success-messagee{
        color: white;
        position: absolute;
        top:15px;
        left:40%;
        background-color: green;
        border: 1px solid rgb(147, 146, 146);
        border-radius: 7px;
        padding: 10px 20px;
        z-index: 7;
    }
    
    
    .main-section{
        display: grid;
        grid-template-columns: 540px 540px;
        grid-template-rows:270px 270px;
        height: fit-content;
       margin-left: -20px;
        
    }
    .main-section img{
        width:500px;
        height:240px
    }
    .Mobile_checkcontainer {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100vh;
        background-color: #f8d7da;
        color: #721c24;
        text-align: center;
        padding: 20px;
        box-sizing: border-box;
      }
      
    .Mobile_checkcontainer .message {
        font-size: 20px;
        font-weight: bold;
      }
    
    .dashboard-main{
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    
    }
    /*dashboard boxs section */
    .dashboard-main-shortcuts{
        width: 100%;
        display: flex;
        align-items: center;
      justify-content: space-between;
        text-align: center;
        height: 165px;
        }
    
    
        .dashboard-main-shortcuts ul {
            display: flex;
            gap:30px
            
            
            }
    .dashboard-main-shortcuts h1:nth-child(1){
    font-size: 2.3rem;
    align-self: flex-start;
    margin:15px  0px 0 45px;
    }
    .dashboard-main-shortcuts ul li {
        display: flex;
        justify-content: space-between;
        padding: 18px 25px;
        width:325px;
        height: 128px;
        border-radius: 6px;
        color: white;
    }
    .dashboard-main-shortcuts ul .feedback_viewBox{
        margin-right: 40px;
    }
    .dashboard-main-shortcuts ul li div{
        display: flex;
        flex-direction: column;
        gap:20px
    }
    
    /*send message pop*/
    .sndmessage-function{
        background-color: saddlebrown;
        height: 330px;
    }
    .sendmess-cont{
        position: absolute;
        top:10%;
        left:25%;
        width: 53%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 25px 0px 0px ;
        box-shadow: -8px 8px 13px rgb(148, 148, 148);
        background-color: white;
        border-radius: 8px;
        border: 1px solid rgb(163, 163, 163);
        color: black;
        z-index: 1;
        font-size: 0.9rem;
       max-height: 480px;
    }
     .sendmess-cont form textarea{
            margin-top: 70px;
          }
    
    .blurry{
        opacity: 0.4;
    }
    
    .sms-sendbtn:hover{
        cursor: pointer;
    }
    /* .blurry .sendmcont{
        opacity: 0.9;
    } */
    
    .sendmess-cont form{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap:4px;
    }
    .comp-ownername{
        display: flex;
       gap:20px;
    }
    .comp-ownername div{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap:7px
    }
    .sendmess-cont form input{
        padding: 4px 8px;
        border: 1px solid rgb(212, 211, 211);
        border-radius: 8px;
        width: 45%;
        margin-left: 7px;
        color:rgb(168, 167, 166);
        background-color: rgb(230, 232, 232);
    }
    .sendmess-cont form div input{
        width: 200px;
    }
    
    .radio-opt{
        display: flex;
        justify-content: center;
        gap:20px;
        height: 70px;
    margin-top: 25px;
    }
    
    .radio-opt div{
       
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 120px;
        gap:10px
    }
    .sendmess-cont form textarea{
        background-color: white;
        margin-top: 1px;
        align-self: center;
        border-radius: 7px;
        border: 1px solid rgb(201, 199, 199);
        padding: 8px 15px;
        color: rgb(117, 118, 118);
        outline: none;
    
    }
    
    .cancel-ok{
        display: flex;
        justify-content: space-between;
        width:130px;
        align-self: flex-end;
        font-size: 0.6rem;
        margin: 25px 0;
    }
    .cancel-ok button{
    border: 1px solid rgb(196, 194, 194);
    padding: 2px 15px;
    font-size: 0.6rem;
    border-radius: 4px;
    width: -moz-fit-content;
    width: -moz-fit-content;
width: fit-content;
    }
    .cancel-ok a{
        background-color: rgb(244, 39, 39);
        color: white;
        border-radius: 4px;
    padding: 0px 15px;
    padding-top: 5px;
    }
    .cancel-ok a:hover{
        cursor: pointer;
    }
    
    
    .dashboard-main-shortcuts ul li:nth-child(1){
        background-color: rgb(44, 156, 156);
    }
    
    
    
    .dashboard-main-shortcuts ul{
        width: 420px;
        margin-right: 25px;
    }
    .dashboard-main-shortcuts ul li:nth-child(2){
        background-color: rgb(45, 70, 168);
    }
    .dashboard-main-shortcuts ul li img{
        width:83px;
        height:93px;
        opacity: 0.4;
        grid-column-start:2 ;
    grid-column-end: 2;
    grid-row-end: 1;
    grid-row-end: 2;
    }
    
    .dashboard-main-shortcuts ul li h2 {
    grid-column-start:1 ;
    grid-column-end: 1;
    grid-row-end: 1;
    grid-row-end: 1;
    font-size:1.6rem;
    }
    .dashboard-main-shortcuts ul li p:nth-child(2) {
    grid-column-start:1 ;
    grid-column-end: 1;
    grid-row-end: 2;
    grid-row-end: 2;
    font-size: 1rem;
    }
    .dash-tables{
        display: flex;
        flex-direction: column;
        gap:20px;
        width: 94%;
        box-shadow: 0 0 12px rgb(170, 170, 170);
        height:780px;
        overflow-y:auto;
        overflow-x:hidden;
        margin-top: 15px;
        background-color: white
        
    }
    
    .twodivs{
        display: flex;
        gap:70px;
        width:90%;
        align-items: center;
        
    }
    
    .dashboard-main table{
       height: 100%;
       border: none;
       
    }
    
    .dashboard-main table tr:nth-child(even){
        background-color: rgb(243, 219, 203);
        
    }
    .status-button {
        border: none;
        border-radius: 5px;
        padding: 4px 14px;
        cursor: pointer;
        font-weight: bold;
        transition: background-color 0.3s;
        height: 28px;
        font-size: 0.8rem;
      }
      .status-change{
        display: flex;
        align-items: center;
        gap:17px
      }
      .accept {
        background-color: #4CAF50; /* Green */
        color: white;
      }
      
      .reject {
        background-color: #f44336; /* Red */
        color: white;
      }
      
      .pending {
        background-color: #FFC107; /* Yellow */
        color: black;
      }
      
      .status-button:hover {
        opacity: 0.8;
      }
      .success-message {
      visibility: hidden;
    }
    
    .visible {
      visibility: visible;
    }
    .success-message{
        display: flex;
        flex-direction: column;
        align-items: center;
        top:10px;
        left:40%
    }
    
    .dash-tablesalterstat{
        width:80vw
    }
    .dash-tablesalterstat table{
        width: 100%;
    }
    .dashboard-main table tr td, .dashboard-main table tr th{
        padding: 8px 0;
        font-size: 1.2rem;
        width:170px;
       
    }
    .dash-tables table th{
        border-bottom: 2px solid rgba(163, 162, 162, 0.717);
        }
        .dashboard-main table {
            border-collapse: collapse;
            width:97%;
            align-self: center;
            margin-top: 10px;
          }
          .dash-message{
    padding:2px 7px;
    background:rgb(220, 219, 219);
    color:black;
    border:1px solid grey;
    font-size:0.6rem;
    border-radius:4px
          }
          .dash-message:hover{
    background-color: black;
    color: white;
    transition: 0.4s;
    
          }
          .dash-tables h3{
            align-self: flex-start;
            margin-left: 20px;
            margin-right: auto;
          }
          .dash-tables table td, th {
            padding: 0;
          }
          .headAndsearch{
              display: flex;
            gap:15px;
            padding-right: 50px;        
            padding-top: 10px;        
            
            height: 40px;
            align-items: center;
        }
        .headAndsearch p{
            background-color: rgb(61, 61, 235);
            color:white;
            padding: 8px 12px;
            border-radius: 4px;
            font-size: 1rem;
        }
        .headAndsearch p:hover{
            cursor: pointer;
            background-color: rgb(31, 31, 162);
        }
        .checkd-btn button{
            background-color:green;
            padding:6px 10px;
            color:white;
            border:none;
            border-radius:6px;
            font-size:0.7rem
        }
          .feedback_desc, .feedback_id{
              border: 1px solid rgba(218, 144, 144, 0.817);
              padding: 0 10px;
            }
            /*popup checkmark succes*/
            /* Add this to your CSS file or style tag */
            
            .popup {
                display: none;
                position: fixed;
                top: 15%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        padding: 20px 40px;
        color: green;
        border: 1px solid #ccc;
        border-radius: 8px;
        box-shadow: -5px 4px 10px rgba(0, 0, 0, 0.1);
    }
    
    .popup.visible {
        display: block;
    }
    
    .popup-content {
        text-align: center;
    }
    
    .popup button {
        margin-top: 10px;
        padding: 8px 16px;
        background-color: #007bff;
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;
    }
    
    
    .checkd-btn button:hover{
        background-color: rgb(2, 81, 2);
        cursor: pointer;
    }
    .red-data{
        color: red;
    }
    .custom-table-data{
        color: green;
    }
    
    .yellow-data{
        color: rgb(179, 179, 6);
    }
    .sendmess-cont{
        height: 33vh;
    }
    .comp-ownername{
        height: 200px;
        display: grid;
            font-size: 0.8rem;
            grid-template-columns: 270px 270px;
            grid-template-rows:70px 70px;
        }
        .comp-ownername input, .comp-ownername textarea{
            background-color: #ccc;
        }
        
        .dash-tables table td{
          max-width: 120px;
          text-wrap: wrap;
          height: 100px;
         padding: 0 10px;
        }
        
        .popupfor_redirct {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(to right, #ff7e5f, #feb47b);
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 999;
          }
          
          .popup-redirect-content {
            text-align: center;
            padding: 20px;
            background: white;
            border-radius: 8px;
            max-width: 300px; /* Adjusted for smaller screens */
            width: 90%;
          }
          
          .popupfor_redirct p {
            margin-bottom: 20px;
          }
          
          .popup-redirect-content a {
            background-color: #4CAF50;
            border: none;
            color: white;
            width: -moz-fit-content;
            width: -moz-fit-content;
width: fit-content;
            padding: 17px 20px;
            text-align: center;
            text-decoration: none;
            display: inline-block;
            font-size: 0.8rem;
            border-radius: 5px;
            cursor: pointer;
          }
          
          .popup-redirect-content a:hover {
            background-color: #45a049;
          }
       
        .Mobile_checkcontainer {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap:12px;
            height: 100vh;
            background-color: #f8d7da;
            color: #721c24;
            text-align: center;
            padding: 20px;
            box-sizing: border-box;
          }
          
        .Mobile_checkcontainer .message {
            font-size: 20px;
            font-weight: bold;
          }
    .Mobile_checkcontainer button{
        background-color: black;
        color: white;
        width:120px;
        height: 34px;
        padding: 4px 12px;
        border-radius: 8px;
    }
        .file_att_path{
          overflow-x: auto;   
        }
        .dash-tables table td{
            max-width: 120px;
            text-wrap: wrap;
            height: 190px;
           padding: 0 10px;
          }
        .userstatusappl {
            display: flex;
            justify-content: center;
            align-items: center;
        }
}
    @media screen and (max-width:1870px){
        
        .status-button {
            border: none;
            border-radius: 5px;
            padding: 4px 8px;
            cursor: pointer;
            font-weight: bold;
            transition: background-color 0.3s;
            height: 28px;
            font-size: 0.7rem;
          }
          .file_att_path a{
            color:blue;
            text-decoration: underline;
          }
    }


    @media screen and (max-width:1620px){
        
        .status-button {
            border: none;
            border-radius: 5px;
            padding: 4px 8px;
            cursor: pointer;
            font-weight: bold;
            transition: background-color 0.3s;
            height: 28px;
            font-size: 0.6rem;
          }
    }
    @media screen and (max-width:1490px){
    
       .nav-bar{
    height:61px;
    width:55%;
    display: flex;
    justify-content:space-between;
    align-items: center;
    margin-right:40px;
    padding: 5px 40px;
    align-self: flex-end;
}
.nav-bar img{
    width:140px;
    height:60px;
}

.dashboard{
    height: 100vh;
    display: flex;
    flex-direction: column;
    width:100vw;
    padding: 0;
}

.dashboard_contents{
    display:flex;
    gap:0px;
    padding: 0;
    height: 100%;
    background-color: rgb(240, 240, 240);
}
.success-messagee{
    color: white;
    position: absolute;
    top:15px;
    left:40%;
    background-color: green;
    border: 1px solid rgb(147, 146, 146);
    border-radius: 7px;
    padding: 10px 20px;
    z-index: 7;
}


.main-section{
    display: grid;
    grid-template-columns: 540px 540px;
    grid-template-rows:270px 270px;
    height: fit-content;
   margin-left: -20px;
    
}
.main-section img{
    width:500px;
    height:240px
}

.dashboard-main{
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

}
/*dashboard boxs section */
.dashboard-main-shortcuts{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    height: 125px;
    }


.dashboard-main-shortcuts ul {
display: flex;
gap:30px


}
.dashboard-main-shortcuts h1:nth-child(1){
font-size: 1.6rem;
align-self: flex-start;
margin:15px  0px 0 45px;
}
.dashboard-main-shortcuts ul li {
    display: flex;
    justify-content: space-between;
    padding: 7px 25px;
    width:255px;
    height: 90px;
    border-radius: 6px;
    color: white;
background-color: saddlebrown;
}
.dashboard-main-shortcuts ul li div{
    display: flex;
    flex-direction: column;
    gap:20px
}

/*send message pop*/
.sndmessage-function{
    background-color: saddlebrown;
    height: 330px;
}
.sendmess-cont{
    position: absolute;
    top:10%;
    left:25%;
    width: 53%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 25px 0px 0px ;
    box-shadow: -8px 8px 13px rgb(148, 148, 148);
    background-color: white;
    border-radius: 8px;
    border: 1px solid rgb(163, 163, 163);
    color: black;
    z-index: 1;
    font-size: 0.9rem;
   max-height: 480px;
}
 .sendmess-cont form textarea{
        margin-top: 70px;
      }

.blurry{
    opacity: 0.4;
}

.sms-sendbtn:hover{
    cursor: pointer;
}
/* .blurry .sendmcont{
    opacity: 0.9;
} */

.sendmess-cont form{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap:4px;
}
.comp-ownername{
    display: flex;
   gap:20px;
}
.comp-ownername div{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:7px
}
.sendmess-cont form input{
    padding: 4px 8px;
    border: 1px solid rgb(212, 211, 211);
    border-radius: 8px;
    width: 45%;
    margin-left: 7px;
    color:rgb(168, 167, 166);
    background-color: rgb(230, 232, 232);
}
.sendmess-cont form div input{
    width: 200px;
}

.radio-opt{
    display: flex;
    justify-content: center;
    gap:20px;
    height: 70px;
margin-top: 25px;
}

.radio-opt div{
   
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 120px;
    gap:10px
}
.sendmess-cont form textarea{
    background-color: white;
    margin-top: 1px;
    align-self: center;
    border-radius: 7px;
    border: 1px solid rgb(201, 199, 199);
    padding: 8px 15px;
    color: rgb(117, 118, 118);
    outline: none;

}

.cancel-ok{
    display: flex;
    justify-content: space-between;
    width:130px;
    align-self: flex-end;
    font-size: 0.6rem;
    margin: 25px 0;
}
.cancel-ok button{
border: 1px solid rgb(196, 194, 194);
padding: 2px 15px;
font-size: 0.6rem;
border-radius: 4px;
width: -moz-fit-content;
width: -moz-fit-content;
width: fit-content;
}
.cancel-ok a{
    background-color: rgb(244, 39, 39);
    color: white;
    border-radius: 4px;
padding: 0px 15px;
padding-top: 5px;
}
.cancel-ok a:hover{
    cursor: pointer;
}


.dashboard-main-shortcuts ul li:nth-child(1){
    background-color: rgb(44, 156, 156);
}



.dashboard-main-shortcuts ul{
    width: 320px;
    margin-right: 25px;
}
.dashboard-main-shortcuts ul li:nth-child(2){
    background-color: rgb(45, 70, 168);
}
.dashboard-main-shortcuts ul li img{
    width:70px;
    height: 55px;
    opacity: 0.4;
    grid-column-start:2 ;
grid-column-end: 2;
grid-row-end: 1;
grid-row-end: 2;
}

.dashboard-main-shortcuts ul li h2 {
grid-column-start:1 ;
grid-column-end: 1;
grid-row-end: 1;
grid-row-end: 1;
font-size: 1.2rem;
}
.dashboard-main-shortcuts ul li p:nth-child(2) {
grid-column-start:1 ;
grid-column-end: 1;
grid-row-end: 2;
grid-row-end: 2;
font-size: 0.7rem;
}
.dash-tables{
    display: flex;
    flex-direction: column;
    gap:20px;
    max-width: 97%;
    box-shadow: 0 0 12px rgb(170, 170, 170);
    height:380px;
    overflow-y:auto;
    overflow-x:hidden;
    margin-top: 15px;
    background-color: white
    
}

.twodivs{
    display: flex;
    gap:70px;
    width:90%;
    align-items: center;
    
}

.dashboard-main table{
   height: 100%;
   border: none;
   
}

.dashboard-main table tr:nth-child(even){
    background-color: rgb(243, 219, 203);
    
}
.status-button {
    border: none;
    border-radius: 5px;
    padding: 2px 7px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s;
    height: 17px;
    font-size: 0.6rem;
  }
  .status-change{
    display: flex;
    align-items: center;
    gap:17px
  }
  .accept {
    background-color: #4CAF50; /* Green */
    color: white;
  }
  
  .reject {
    background-color: #f44336; /* Red */
    color: white;
  }
  
  .pending {
    background-color: #FFC107; /* Yellow */
    color: black;
  }
  
  .status-button:hover {
    opacity: 0.8;
  }
  .success-message {
  visibility: hidden;
}

.visible {
  visibility: visible;
}
.success-message{
    display: flex;
    flex-direction: column;
    align-items: center;
    top:10px;
    left:40%
}

.dash-tablesalterstat{
    width:80vw
}
.dash-tablesalterstat table{
    width: 100%;
}
.dashboard-main table tr td, .dashboard-main table tr th{
    padding: 8px 0;
    font-size: 0.8rem;
    width:170px;
   
}
.dash-tables table th{
    border-bottom: 2px solid rgba(163, 162, 162, 0.717);
    }
    .dashboard-main table {
        border-collapse: collapse;
        width:97%;
        align-self: center;
        margin-top: 10px;
      }
      .dash-message{
padding:2px 7px;
background:rgb(220, 219, 219);
color:black;
border:1px solid grey;
font-size:0.6rem;
border-radius:4px
      }
      .dash-message:hover{
background-color: black;
color: white;
transition: 0.4s;

      }
      .dash-tables h3{
        align-self: flex-start;
        margin-left: 20px;
        margin-right: auto;
      }
      .dash-tables table td, th {
        padding: 0;
      }
      .headAndsearch{
          display: flex;
        gap:15px;
        padding-right: 50px;        
        padding-top: 10px;        
        
        height: 40px;
        align-items: center;
    }
    .headAndsearch p{
        background-color: rgb(61, 61, 235);
        color:white;
        padding: 4px 8px;
        border-radius: 4px;
        font-size: 0.6rem;
    }
    .headAndsearch p:hover{
        cursor: pointer;
        background-color: rgb(31, 31, 162);
    }
    .checkd-btn button{
        background-color:green;
        padding:6px 10px;
        color:white;
        border:none;
        border-radius:6px;
        font-size:0.7rem
    }
      .feedback_desc, .feedback_id{
          border: 1px solid rgba(218, 144, 144, 0.817);
          padding: 0 10px;
        }
        /*popup checkmark succes*/
        /* Add this to your CSS file or style tag */
        
        .popup {
            display: none;
            position: fixed;
            top: 15%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px 40px;
    color: green;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: -5px 4px 10px rgba(0, 0, 0, 0.1);
}

.popup.visible {
    display: block;
}

.popup-content {
    text-align: center;
}

.popup button {
    margin-top: 10px;
    padding: 8px 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}


.checkd-btn button:hover{
    background-color: rgb(2, 81, 2);
    cursor: pointer;
}
.red-data{
    color: red;
}
.custom-table-data{
    color: green;
}

.yellow-data{
    color: rgb(179, 179, 6);
}
.sendmess-cont{
    height: 33vh;
}
.comp-ownername{
    height: 200px;
    display: grid;
        font-size: 0.8rem;
        grid-template-columns: 270px 270px;
        grid-template-rows:70px 70px;
    }
    .comp-ownername input, .comp-ownername textarea{
        background-color: #ccc;
    }
    
    .dash-tables table td{
      max-width: 120px;
      text-wrap: wrap;
      height: 100px;
     padding: 0 10px;
    }
    
    .popupfor_redirct {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to right, #ff7e5f, #feb47b);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 999;
      }
      
      .popup-redirect-content {
        text-align: center;
        padding: 20px;
        background: white;
        border-radius: 8px;
        max-width: 300px; /* Adjusted for smaller screens */
        width: 90%;
      }
      
      .popupfor_redirct p {
        margin-bottom: 20px;
      }
      
      .popup-redirect-content a {
        background-color: #4CAF50;
        border: none;
        color: white;
        width: -moz-fit-content;
        width: -moz-fit-content;
width: fit-content;
        padding: 17px 20px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 0.8rem;
        border-radius: 5px;
        cursor: pointer;
      }
      
      .popup-redirect-content a:hover {
        background-color: #45a049;
      }
      
      
    .dashboard-main table tr{
        text-wrap: wrap;
      height: fit-content;
    
    }

    .file_att_path{
      overflow-x: auto;   
    }
    .userstatusappl {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    }   


    @media screen and (max-width:482px){
        .success-message{
            
            top:55px;
            left:15%;
            ;
        }
    
    
    }