@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');

@media screen and (max-width:2225px){
    
    .contactus_section{
      
background-color: white;  

/* justify-content: flex-end; */
background:url('../../components/images/Newfolder/aboutimg1.jpg');
  position: relative;
background-size:cover;
 background-repeat:no-repeat ;
 
width:100vw;
height:65vh;

}
.contactus_section_socials a img {
    transition: filter 0.3s ease; /* Add transition for smooth effect */
  }
  
  .contactus_section_socials a img:hover {
    cursor: pointer;
    filter: drop-shadow(0 0 10px rgba(17, 17, 17, 0.683)); /* Adds a shadow effect */
  }
.contactus_section_contents{
    position: absolute;
   
    display: grid;
    left:3%;
    top:100px;
    border: none;
    z-index: 5;
    grid-template-columns: 660px 660px ;
    grid-template-rows: 120px 120px ;
    margin-top: 80px;
}
.contactus_section_header{
   
    display: flex;
    flex-direction: column;
    font-size: 2rem;
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start:1 ;
    grid-row-end:1 ;
   
}
.contactus_section_header span{
    height: 2px;
    width: 120px;
    color: rgba(3, 5, 94, 0.676);
    align-self: center;
    margin-top: 42px;
    border: 1px solid rgba(221, 221, 221, 0.785);
    box-shadow: 2px 8px 8px rgb(0, 0, 0);
    border-radius: 100px;
}
.contactus_section_header h1{
    font-family: "Anton", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 6.9rem;
    color: #DAD2D8;;
}
.contactus_section_contacts{
    display: grid;
    grid-template-columns: 796px 796px ;
    grid-template-rows: 220px 220px ;
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start:1 ;
    grid-row-end:1 ;
    width: 80%;
    height: 100%;
}
.contactus_section_contacts div{
    display: flex;
    align-items: center;
    gap:48px;
    width: 730px;
    height: 62px;
}
.contactus_section_contacts div a{
    background-color: #DAD2D8;
    color: black;
    padding: 20px 46px;
    border-radius: 13px;
   

    font-size: 1.9rem;
}
.contactus_section_contacts div a:hover{
    color: #DAD2D8;
    background-color: black;
}
.contactus_section_contacts div:last-child{
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start:1 ;
    grid-row-end:1 ;
}
.contactus_section_socials{
    margin-top:320px;
    margin-left: 122px;
   
    display: grid;
    grid-template-columns:130px 130px 130px ;
    grid-template-rows:110px 110px  ;
    gap:0;
    width: 370px;
    height: 130px;
    flex-direction: column;
}
.contactus_section_socials a{
    height: 80px;
    
}
.contactus_section_socials a img{
    height: 85%;
    width: 65px;
}
.contactus_section_socials a:nth-child(2) img,
.contactus_section_socials a:nth-child(3) img{
    height: 80%;
    width: 65px;
}
.contactus_section_socials a:nth-child(4){
    grid-row-start: 2;
    grid-row-end: 2;
    grid-column-start: 1;
    grid-column-end: 1;
    margin-left: 90px;
}
.contactus_section_socials a:nth-child(4) img{
    height: 80%;
    width:65px;
}
.contactus_section_socials a:nth-child(5){
    grid-row-start: 2;
    grid-row-end: 2;
    grid-column-start: 2;
    grid-column-end: 2;
    margin-left: 70px;
}
.contactus_section_socials a:nth-child(5) img{
    width:125px;
    height: 80%;
}


.contactus_section_right{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 4;
    background-color:  rgba(3, 5, 94, 0.676);
}
.contact_icons{
    color: #DAD2D8;
}
}

@media screen and (max-width:2134px){
    .contactus_section_contents{
        
        margin-top: 40px;
    }
    .contactus_section_socials a{
        height: 70px;
        
    }
    .contactus_section_socials a img{
        height: 80%;
        width: 65px;
    }
    .contactus_section_socials a:nth-child(2) img,
    .contactus_section_socials a:nth-child(3) img{
        height: 75%;
        width: 55px;
    }
    .contactus_section_socials a:nth-child(4){
        grid-row-start: 2;
        grid-row-end: 2;
        grid-column-start: 1;
        grid-column-end: 1;
        margin-left: 90px;
    }
    .contactus_section_socials a:nth-child(4) img{
        height: 70%;
        width:55px;
    }
    .contactus_section_socials a:nth-child(5){
        grid-row-start: 2;
        grid-row-end: 2;
        grid-column-start: 2;
        grid-column-end: 2;
        margin-left: 70px;
    }
    .contactus_section_socials a:nth-child(5) img{
        width:105px;
        height: 70%;
    }
}
@media screen and (max-width:1984px){
    
    .contactus_section{
      
background-color: white;  

/* justify-content: flex-end; */
background:url('../../components/images/Newfolder/aboutimg1.jpg');
  position: relative;
background-size:cover;
 background-repeat:no-repeat ;
 
width:100vw;
height:85vh;

}

.contactus_section_contents{
    position: absolute;
   
    display: grid;
    left:3%;
    top:100px;
    border: none;
    z-index: 5;
    grid-template-columns: 660px 660px ;
    grid-template-rows: 120px 120px ;
    margin-top: 80px;
}
.contactus_section_header{
   
    display: flex;
    flex-direction: column;
    font-size: 2rem;
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start:1 ;
    grid-row-end:1 ;
   
}
.contactus_section_header span{
    height: 2px;
    width: 120px;
    color: rgba(3, 5, 94, 0.676);
    align-self: center;
    margin-top: 42px;
    border: 1px solid rgba(221, 221, 221, 0.785);
    box-shadow: 2px 8px 8px rgb(0, 0, 0);
    border-radius: 100px;
}
.contactus_section_header h1{
    font-family: "Anton", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 5.4rem;
    color: #DAD2D8;;
}
.contactus_section_contacts{
    display: grid;
    grid-template-columns: 662px 662px ;
    grid-template-rows: 220px 220px ;
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start:1 ;
    grid-row-end:1 ;
    width: 80%;
    height: 100%;
}
.contactus_section_contacts div{
    display: flex;
    align-items: center;
    gap:48px;
    width: 730px;
    height: 62px;
}
.contactus_section_contacts div a{
    background-color: #DAD2D8;
    color: black;
    padding: 20px 46px;
    border-radius: 13px;
   

    font-size: 1.4rem;
}
.contactus_section_contacts div a:hover{
    color: #DAD2D8;
    background-color: black;
}
.contactus_section_contacts div:last-child{
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start:1 ;
    grid-row-end:1 ;
}

.contactus_section_right{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 4;
    background-color:  rgba(3, 5, 94, 0.676);
}
.contact_icons{
    color: #DAD2D8;
}

.contactus_section_socials{
margin-top:270px;
margin-left: 172px;
grid-template-columns:110px 110px 110px ;
grid-template-rows:90px 90px  ;
}
.contactus_section_socials a:nth-child(4){
    
    margin-left: 80px;
}
.contactus_section_socials a:nth-child(5){
 
    margin-left: 60px;
}
}

@media screen and (max-width:1789px){
    .contactus_section_contacts div a{
       
        padding: 12px 34px;
        font-size: 1.4rem;
    }
    .contactus_section_contacts{
        display: grid;
        grid-template-columns: 632px 632px ;
        grid-template-rows: 220px 220px ;
    }
  
    
.contactus_section_socials{
    margin-top:230px;
    margin-left: 172px;
   
    }
    
   
}
@media screen and (max-width:1699px){
    .contactus_section{

        height:90vh;
        
        }
        
    .contactus_section_contacts div a{
       
        padding: 14px 38px;
        font-size: 1rem;
    }
   .contactus_section_contacts{
        display: grid;
        grid-template-columns: 520px 520px ;
        grid-template-rows: 180px 180px ;
        grid-column-start: 2;
        grid-column-end: 2;
        grid-row-start:1 ;
        grid-row-end:1 ;
        width: 70%;
        height: 100%;
        margin-top: 30px;
    }
    .contactus_section_contents{
        margin-top: 20px;
       }
    .contactus_section_header span{
        
        margin-top: 22px;
    }
    .contact_icons{
        width:30px;
        height: 30px;
    }
    .contactus_section_socials{
        
        grid-template-columns:90px 90px 90px ;
        grid-template-rows:70px 70px  ;
        }

    .contactus_section_socials a{
        height: 70px;
        
    }
    .contactus_section_socials a img{
        height: 80%;
        width: 65px;
    }
    .contactus_section_socials a:nth-child(2) img,
    .contactus_section_socials a:nth-child(3) img{
        height: 75%;
        width: 55px;
    }
    
    
    .contactus_section_socials a{
        height: 50px;
        
    }
    .contactus_section_socials a img{
        height: 80%;
        width: 55px;
    }
    .contactus_section_socials a:nth-child(2) img,
    .contactus_section_socials a:nth-child(3) img{
        height: 65%;
        width: 45px;
    }
    .contactus_section_socials a:nth-child(4){
        grid-row-start: 2;
        grid-row-end: 2;
        grid-column-start: 1;
        grid-column-end: 1;
        margin-left: 65px;
    }
    .contactus_section_socials a:nth-child(4) img{
        height: 70%;
        width:55px;
    }
    .contactus_section_socials a:nth-child(5){
        grid-row-start: 2;
        grid-row-end: 2;
        grid-column-start: 2;
        grid-column-end: 2;
        margin-left: 45px;
    }
    .contactus_section_socials a:nth-child(5) img{
        width:105px;
        height: 70%;
    }
}

@media screen and (max-width:1560px){
    .contactus_section{

        height:85vh;
        
        }
    .contactus_section_contacts div a{
       
        padding: 12px 33px;
        font-size: 0.9rem;
    }
   .contactus_section_contacts{
        display: grid;
        grid-template-columns: 492px 492px ;
        grid-template-rows: 120px 120px ;
    
    }
    .contactus_section_contents{
       
        grid-template-columns: 560px 560px ;
        grid-template-rows: 100px 100px ;
    }
    .contact_icons{
        width:30px;
        height: 30px;
    }
}


@media screen and (max-width:1440px){
    .contactus_section{

        height:95vh;
        
        }
        .contactus_section_contents{
            margin-top: 40px;
        }
    .contactus_section_contacts div a{
       
        padding: 10px 28px;
        font-size: 0.8rem;
    }
   
    
    

    .contactus_section_contents{
        left:3%;
        grid-template-columns: 436px 436px ;
        grid-template-rows: 100px 100px ;
    }
    .contactus_section_contacts{
        display: grid;
        grid-template-columns: 392px 392px ;
        grid-template-rows: 120px 120px ;
    
    }
    .contact_icons{
        width:20px;
        height: 20px;
    }
    .contactus_section_header h1{
        font-family: "Anton", sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 3.63rem;
    }
    
    

}
@media screen and (max-width:1360px){
    .contactus_section{

        height:90vh;
        }

        .contactus_section_socials{
            margin-top:160px;
            margin-left: 112px;
           
            display: grid;
            grid-template-columns:75px 75px 75px ;
            grid-template-rows:60px 60px  ;
            gap:0;
            width: 320px;
            height: 130px;
            flex-direction: column;
        }
        .contactus_section_socials a{
            height: 40px;
            
        }
        .contactus_section_socials a img{
            height: 85%;
            width: 35px;
        }
        .contactus_section_socials a:nth-child(2) img,
        .contactus_section_socials a:nth-child(3) img{
            height: 80%;
            width: 35px;
        }
        .contactus_section_socials a:nth-child(4){
            grid-row-start: 2;
            grid-row-end: 2;
            grid-column-start: 1;
            grid-column-end: 1;
            margin-left: 60px;
        }
        .contactus_section_socials a:nth-child(4) img{
            height: 70%;
            width:30px;
        }
        .contactus_section_socials a:nth-child(5){
            grid-row-start: 2;
            grid-row-end: 2;
            grid-column-start: 2;
            grid-column-end: 2;
            margin-left: 40px;
        }
        .contactus_section_socials a:nth-child(5) img{
            width:70px;
            height: 75%;
        }
        
       
}
@media screen and (max-width:1130px){
        .contactus_section{
            height:45vh;
            }

    .contactus_section_contacts div a{
        padding: 10px 28px;
        font-size: 0.8rem;
    }
    
   
    .contactus_section_contents{
        left:3%;
        grid-template-columns: 436px 436px ;
        grid-template-rows: 100px 100px ;
    }
    .contactus_section_contacts{
        display: grid;
        grid-template-columns: 392px  ;
        grid-template-rows: 80px 80px 80px;
    
    }
    .contactus_section_contacts div:last-child{
        grid-column-start: 1;
        grid-column-end: 1;
        grid-row-start:2 ;
        grid-row-end:2 ;
    }
   
    .contact_icons{
        width:20px;
        height: 20px;
    }
    .contactus_section_header h1{
        font-size: 3.4rem;
    }
}

@media screen and (max-width:880px){
    .contactus_section{

        height:50vh;
        
        }
        .contactus_section_contents{
            margin-top: 0px;
        }
        .contactus_section_contents{
            left:3%;
            grid-template-columns: 346px 346px ;
            grid-template-rows: 100px 100px ;
        }
        .contactus_section_header h1{
            font-size:2.8rem;
        } 
        .contactus_section_socials{
            margin-top:100px;
            margin-left: 72px;
           
            }
    }
@media screen and (max-width:482px){
    .contactus_section_header{
        margin-left: -33px;
    }
    .contactus_section{
        height: 85vh;

}
.contactus_section_socials{
    margin-top:50px;
    margin-left: 85px;
   
    display: grid;
    grid-template-columns:70px 70px 70px  ;
    grid-template-rows:60px 60px ;
    gap:0;
    width: 100%;
    height: 120px;
}
.contactus_section_socials a{
    height: 35px;
    
}
.contactus_section_socials a img{
    height: 85%;
    width: 35px;
}
.contactus_section_socials a:nth-child(2) img,
.contactus_section_socials a:nth-child(3) img{
    height: 80%;
    width: 35px;
}
.contactus_section_socials a:nth-child(4){
    grid-row-start: 2;
    grid-row-end: 2;
    grid-column-start: 1;
    grid-column-end: 1;
    margin-left: 60px;
}
.contactus_section_socials a:nth-child(4) img{
    height: 80%;
    width:40px;
}
.contactus_section_socials a:nth-child(5){
    grid-row-start: 2;
    grid-row-end: 2;
    grid-column-start: 2;
    grid-column-end: 2;
    margin-left: 40px;
}
.contactus_section_socials a:nth-child(5) img{
    width:90px;
    height: 75%;
}
.contactus_section_socials a:nth-child(4){
    margin-left: 45px;
           }
   .contactus_section_socials a:nth-child(5){
    margin-left: 35px;
           }
    .contactus_section_contacts div a{
       
        padding: 10px 28px;
        font-size: 0.8rem;
    }
  
   
    .contactus_section_contents{
        left:3%;
        grid-template-columns: 436px  ;
        grid-template-rows: 220px 220px 220px;
    }
    .contactus_section_contacts{
        display: grid;
        grid-template-columns: 392px  ;
        grid-template-rows: 80px 80px 80px;
        grid-column-start: 1;
        grid-column-end: 1;
        grid-row-start: 2;
        grid-row-end: 2;
        align-self: center;
        margin-left: 33px;
    
    }
    .contactus_section_contacts div:last-child{
        grid-column-start: 1;
        grid-column-end: 1;
        grid-row-start:2 ;
        grid-row-end:2 ;
    }
    
    .contact_icons{
        width:18px;
        height: 18px;
    }
    .contactus_section_header h1{
        font-size: 2.8rem;
    }
    .contactus_section_header span{
        height: 2px;
        width: 72px;
        margin-top: 26px;
    }
}
@media screen and (max-width:394px){

    .contactus_section{
        height: 88vh;
    
    }
    .contactus_section_socials{
        margin-top:50px;
        margin-left:85px;
    }
}
@media screen and (max-width:378px){
    .contactus_section{
        height: 90vh;

}
    .contactus_section_header{
        margin-left: -63px;
    }
    .contactus_section_header h1{
           font-size: 2.4rem;
       }
       .contactus_section_contacts div{
        display: flex;
        align-items: center;
        gap:33px;
        width: 670px;
        height: 49px;
    }
       .contactus_section_contacts div a{
      
        font-size: 0.7rem;
    }
    .contactus_section_socials a{
        height: 35px;
        
    }
    .contactus_section_socials a img{
        height: 85%;
        width:35px;
    }
    .contactus_section_socials a:nth-child(2) img,
    .contactus_section_socials a:nth-child(3) img{
        height: 85%;
        width: 40px;
    }
    .contactus_section_socials a:nth-child(4){
        grid-row-start: 2;
        grid-row-end: 2;
        grid-column-start: 1;
        grid-column-end: 1;
         margin-left: 50px;
    }
    .contactus_section_socials a:nth-child(4) img{
        height: 90%;
        width:40px;
    }
    .contactus_section_socials a:nth-child(5){
        grid-row-start: 2;
        grid-row-end: 2;
        grid-column-start: 2;
        grid-column-end: 2;
        margin-left: 40px;
    }
    .contactus_section_socials a:nth-child(5) img{
        width:80px;
        height: 95%;
    }
    .contactus_section_socials{
        margin-top:40px;
        margin-left:50px;
       
        display: grid;
        grid-template-columns:75px 75px 75px  ;
        grid-template-rows:55px 55px ;
    }

}
    @media screen and (max-width:364px){
        .contactus_section{
            height: 96vh;
    
    }
   

   
}
    @media screen and (max-width:340px){
        .contactus_section{
            height: 100vh;
            padding-top: 0;
            
        } 
    .contactus_section_header{
        margin-left: -133px;
    }
    .contactus_section_header h1{
           font-size: 2rem;
       }
       .contactus_section_contacts div{
        display: flex;
        align-items: center;
        gap:24px;
        width: 670px;
        height: 40px;
    }
       .contactus_section_contacts div a{
      
        font-size: 0.6rem;
    }
      .contactus_section_contacts{
       
        margin-left: 26px;
    }
    .contactus_section_contents{
        left:3%;
        grid-template-columns: 436px  ;
        grid-template-rows: 170px 170px 170px;
    }
    .contactus_section_contacts{
        display: grid;
        grid-template-columns: 392px  ;
        grid-template-rows: 66px 66px 66px;
        margin-top: 0px;
    }


    .contactus_section_socials a{
        height: 20px;
        
    }
    .contactus_section_socials a img{
        height: 75%;
        width:20px;
    }
    .contactus_section_socials a:nth-child(2) img,
    .contactus_section_socials a:nth-child(3) img{
        height: 80%;
        width: 15px;
    }
    .contactus_section_socials a:nth-child(4){
        grid-row-start: 2;
        grid-row-end: 2;
        grid-column-start: 1;
        grid-column-end: 1;
         margin-left: 25px;
    }
    .contactus_section_socials a:nth-child(4) img{
        height: 80%;
        width:20px;
    }
    .contactus_section_socials a:nth-child(5){
        grid-row-start: 2;
        grid-row-end: 2;
        grid-column-start: 2;
        grid-column-end: 2;
        margin-left: 25px;
    }
    .contactus_section_socials a:nth-child(5) img{
        width:65px;
        height: 90%;
    }
    .contactus_section_socials{
        margin-top:15px;
        margin-left:70px;
       
        display: grid;
        grid-template-columns:45px 45px 45px  ;
        grid-template-rows:35px 35px ;
    }
}