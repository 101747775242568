@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Domine:wght@400..700&family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');

@media screen and (max-width:2225px){
.serviceAndOffers{
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:74px;
width:100vw;
background-color: #EDEDED;



}
.serviceAndOffers_top{
    display: flex;
    flex-direction: column;
    gap:6px;
    align-items: flex-start;
width: 100%;
text-align: start;
height: 30%;
padding: 50px 74px;
font-size: 1.3rem;
margin-top: 90px;
border-bottom: 1px solid rgba(172, 170, 170, 0.737);
}
.serviceAndOffers_top div{
    display: flex;
    flex-direction: column;
    gap:4px;
    font-size: 1.8rem;
    
}

.serviceAndOffers_bottom{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70%;
    width: 100%;
    padding: 7px 33px 7px 33px  ;
    padding-bottom: 147px  ;
}
.serviceAndOffers_bottom ul{
width: 100%;
height: 100%;
display: flex;
flex-wrap: wrap;
gap:24px
}
.serviceAndOffers_bottomlists{
    background-color: #f1f1f1;
    width:30%;
    height: 394px;
    list-style: none;
    border-radius: 24px;
    box-shadow: 0 0 18px rgba(91, 91, 91, 0.495);
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap:22px;
    text-align: start;
    padding: 44px 0;
    padding-left: 49px;
}
.serviceAndOffers_bottom ul li:first-child {
background-color: #03045E;
color: white;

}
.serviceAndOffers_bottom ul li:hover {
cursor: pointer;
margin-top:-20px;
}
.serviceAndOffers_bottomlists div{
    display: flex;
    flex-direction: column;
    gap:18px;
    border-bottom: 1px solid grey;
    width: 100%;
    padding-bottom: 22px;
    font-size: 1rem;
    height: 45%;
    
}
.serviceAndOffers_bottomlists div h2{
    font-family: "Playfair Display", serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
  width: 90%;
}

.serviceAndOffers_top{
    
height: 20%;
}
.serviceOffers_icons{
    width:68px;
    height: 66px;
    color:#d5b8ce
}
.serviceAndOffers_bottomlists p{
    
    width: 85%;
    font-size: 1rem;
}
}


@media screen and (max-width:1968px){
    .serviceAndOffers_bottomlists{
        width:30%;
            height: 494px;
    }
    .serviceAndOffers_bottomlists p{
    
        width: 80%;
        font-size: 0.9rem;
        line-height: 1.6rem;
        height: 45%;
        
    }
    .serviceAndOffers_top{
        
    height: 20%;
}

}


@media screen and (max-width:1568px){
    .serviceAndOffers_bottomlists{
        width:30%;
            height: 494px;
    }
    .serviceAndOffers_bottomlists p{
    
        width: 80%;
        font-size: 0.8rem;
        line-height: 1.6rem;
        height: 45%;
        
    }

    .serviceAndOffers_top{
        margin-top: 72px;
    }
}


@media screen and (max-width:1105px){
    .serviceAndOffers_bottom{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 70%;
        width: 100%;
        padding-bottom: 147px ;
        padding-left: 0;
        padding-right: 0;
    }
    .serviceAndOffers_bottom ul{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap:62px
    }
    .serviceAndOffers_bottomlists{
        width:80%;
        height: 394px;
        list-style: none;
        border-radius: 24px;
        box-shadow: 0 0 18px rgba(91, 91, 91, 0.495);
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap:22px;
        align-self: center;
        text-align: start;
        padding: 44px 0;
        padding-left: 49px;
    }
    .serviceAndOffers_bottom ul li:first-child {
    background-color: #03045E;
    color: white;
    
    }
    .serviceAndOffers_bottom ul li:hover {
    cursor: pointer;
    margin-right:-20px;
    }
    .serviceAndOffers_bottomlists div{
        display: flex;
        flex-direction: column;
        gap:18px;
        border-bottom: 1px solid grey;
        width: 100%;
        padding-bottom: 22px;
        font-size: 1.2rem;
        height: 45%;
        
    }
    .serviceAndOffers_bottomlists div h2{
        font-family: "Playfair Display", serif;
      font-optical-sizing: auto;
      font-weight: 300;
      font-style: normal;
    }
    
    .serviceAndOffers_top{
        
    height: 20%;
    }
    .serviceOffers_icons{
        width:112px;
        height: 114px;
        color:#d5b8ce
    }
    .serviceAndOffers_bottomlists p{
        
        width: 80%;
        font-size: 1.4rem;
    }
    }


@media screen and (max-width:482px){
    .serviceAndOffers_bottom{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 70%;
        width: 100%;
        padding-bottom: 147px ;
        padding-left: 0;
        padding-right: 0;
    }
    .serviceAndOffers_bottom ul{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap:62px
    }
    .serviceAndOffers_bottomlists{
        width:85%;
        height: 534px;
        height: fit-content;
        list-style: none;
        border-radius: 24px;
        box-shadow: 0 0 18px rgba(91, 91, 91, 0.495);
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap:22px;
        align-self: center;
        text-align: start;
        padding: 44px 0;
        padding-left: 49px;
    }
    .serviceAndOffers_bottom ul li:first-child {
    background-color: #03045E;
    color: white;
    
    }
    .serviceAndOffers_bottom ul li:hover {
    cursor: pointer;
    margin-right:-20px;
    }
    .serviceAndOffers_bottomlists div{
        display: flex;
        flex-direction: column;
        gap:18px;
        border-bottom: 1px solid grey;
        width: 100%;
        padding-bottom: 22px;
        font-size: 0.9rem;
        height: 45%;
        
    }
    .serviceAndOffers_bottomlists div h2{
        font-family: "Playfair Display", serif;
      font-optical-sizing: auto;
      font-weight: 300;
      font-style: normal;
    }
    
    .serviceAndOffers_top{
        
    height: 20%;
    }
    .serviceOffers_icons{
        width:82px;
        height: 84px;
        color:#d5b8ce
    }
    .serviceAndOffers_bottomlists p{
        
        width: 85%;
        font-size: 0.7rem;
    }
    .serviceAndOffers_top div{
        
        font-size: 1rem;
        
    }.serviceAndOffers_top{
       
        font-size: 0.9rem;
    }
    }
    @media screen and (max-width:398px){
        .serviceAndOffers_top div{
               
               font-size: 0.9rem;
               
           }
        }
    @media screen and (max-width:378px){
        .serviceAndOffers_top div{
               
               font-size: 0.8rem;
               
           }.serviceAndOffers_top{
       gap:19px;
            font-size: 0.8rem;
        }
        }
    @media screen and (max-width:358px){
        .serviceAndOffers_top div{
               
               font-size: 0.6rem;
               
           }.serviceAndOffers_top{
       gap:19px;
            font-size: 0.7rem;
        }
        .serviceAndOffers_bottomlists p{
        
            width: 80%;
            font-size: 0.8rem;
        }
        .serviceOffers_icons{
            width:42px;
            height: 44px;
            color:#d5b8ce
        }
        }