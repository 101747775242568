@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&family=Darker+Grotesque:wght@300..900&display=swap');

@media screen and (max-width:2225px){
    .blog-postContent{
      height:fit-content;
      padding: 42px 62px;
      width: 100vw;
      display: flex;
      justify-content: space-between;
       background-color:#EDEDED;
      
    }
   
    
    .blog-title {
      margin-top: 0;
    }
    
    .blog-content {
      line-height: 2.9;
      font-family: "Anton", sans-serif;
  font-weight: lighter;
    }
    .blog-post {
         font-family: "Anton", sans-serif;
  font-weight: 400;
  font-style: normal;
display: flex;
flex-direction: column;
      max-width: 70%;
      background-color:transparent;
      padding: 40px;
      font-family: Arial, sans-serif;
      border-radius: 8px;
      align-self: flex-start;
      text-align: start;
      margin-top: 60px;
    }
    .shadow-lg .intoBlogCont{
      margin: 43px 0 20px 0;
      font-size: 2.6rem;
      font-family: "Anton", sans-serif;
  font-weight: lighter;
    }
    .shadow-lg h1{
      font-size: 3.6rem;
      font-family: "Anton", sans-serif;
  font-weight: lighter;
    }
    .blogContentAside{
      width: 25%;
      height: fit-content;
      padding: 18px 8px;
      background-color:white;
      display: flex;
      flex-direction: column;
      gap:8px;
      border-radius: 8px;
      margin-top: 41px; 
      margin-top: 120px;
  }
  .blogContentAside ul{
      align-self: center;
  width: 80%;
  color: black;
    }
    .blogContentAside h3{
      color: black;
      font-size: 1.6rem;
      font-family: "Anton", sans-serif;
  font-weight: lighter;
    }
    .blogContentAside ul li {
        font-size: 1rem;
      text-align: start;
      width:100%;
      margin-top: 8px;
      list-style: disc;
  
    }
    .blogContentAside ul li a{
        text-decoration: underline;
        color: black;
    }
    .blogContentAside ul li a:hover{
        color:blue;
    }

    .blogCntentText h1 {
      font-size: 26px;
      margin-bottom: 20px;
      color: #333;
    }
    
   
    
    
    .introduction {
      margin-top: 20px;
    }
    
     .shadow-lg h2 {
      font-size: 30px;
      margin-bottom: 0px;
      color: #555;font-family: "Anton", sans-serif;
  font-weight: lighter;
    }
    .shadow-lg2 h2 {
        font-weight: lighter;
      }
    .blogCntentText p {
      line-height: 1.9;
      color: #646262;
      font-size: 1.2rem;
      
    }
    .rowBlog{
      display: flex;
      flex-direction: column;
      gap: 40px;
    }
    .rowBlogHeadquestion{
      margin-top: 22px;
    }
    .shadow-lg ul {
      width:95%;
      
    }
    .shadow-lg p{
        font-size: 1.2rem;
    }
    .shadow-lg ul li{
      margin-left: 42px;
      
      
    }
      .blogCntentText{
          display: flex;
          flex-direction: column;
          gap:14px;
      }
    .blogCntentText p,  .shadow-lg ul li{
      line-height: 1.9rem;
      margin-top: 8px;
    }
    .blogCntentText p {
  width: 80%;
    }
    .shadow-lg p:nth-child(1){
      margin-top: 28px;
    }
   .WhyPartner{
    background-color: white;
    align-self: center;
    width:90%;
    position: relative;
    left:20%;
    padding: 40px;
    border-radius: 8px;
    margin-top:82px;
    }
    .WhyPartnertext{
        width:80%;
    }
    .blogCntentText2 h3{
        margin-top: 24px;
        font-size: 1.8rem;
    }
    .blogCntentText{
        gap:8px
    }
    .WhyPartnertext h1{
        font-size: 3.4rem;
    }.blogCntentText p{
        font-size: 1.4rem;
    }
  }




@media screen and (max-width:1795px){

    .blog-post {
        padding: 60px;
      }
      .WhyPartner{
          width: 95%;
      
      }
}
@media screen and (max-width:1565px){
    .WhyPartner{
        width: 100%;
    
    }.WhyPartnertext{
        width:95%;
    }
    .blogCntentText2 h3{
        margin-top: 24px;
        font-size: 1.4rem;
    }
    .blogCntentText{
        gap:8px
    }
    .WhyPartnertext h1{
        font-size: 3rem;
    }.blogCntentText p{
        font-size: 1.2rem;
    }
    
}
@media screen and (max-width:1365px){

  .blog-postContent{
    height:fit-content;
    padding: 42px 48px;
    width: 100vw;
    display: flex;
    justify-content: space-between;
     background-color:#EDEDED;
  }
 
  
  .blog-title {
    margin-top: 0;
  }
  
  .blog-content {
    line-height: 1.6;
  }
  .blog-post {
    max-width: 70%;
    padding: 40px;
    font-family: Arial, sans-serif;
    border-radius: 8px;
    align-self: flex-start;
    text-align: start;
  }
  .shadow-lg .intoBlogCont{
    margin: 43px 0 10px 0;
    font-size: 1.4rem;
  }
  .shadow-lg h1{
    font-size: 2.8rem;
  }
  
  .blogContentAside{
    width: 25%;
    height: fit-content;
    padding: 14px 8px;
    display: flex;
    flex-direction: column;
    gap:8px;
    border-radius: 8px;
}
.blogContentAside ul{
    align-self: center;
width: 80%;
color: black;
  }
  .blogContentAside h3{
    color: black;font-size: 0.9rem;
  }
  .blogContentAside ul li {
    font-size: 0.64rem;
    text-align: start;
    width:100%;
    margin-top: 8px;

  }
  .blogContentAside ul li a{
    color: black;
    text-decoration: underline;
  }
  .blogCntentText h1 {
    font-size: 26px;
    margin-bottom: 20px;
    color: #333;
  }
  
 
  
  
  .introduction {
    margin-top: 20px;
  }
  
   .shadow-lg h2 {
    font-size: 18px;
    margin-bottom: 0px;
    color: #555;
  }
  
  .blogCntentText p {
    line-height: 1.6;
    color: #646262;
    font-size: 0.9rem;
    
  }
  .rowBlog{
    display: flex;
    flex-direction: column;
    gap: 40px;
  }
  .rowBlogHeadquestion{
    margin-top: 22px;
  }
  .shadow-lg ul {
    width:95%
  }
  .shadow-lg ul li{
    margin-left: 42px;
    font-size: 0.8rem;
  }
    .blogCntentText{
        display: flex;
        flex-direction: column;
        gap:14px;
    }
  .blogCntentText p,  .shadow-lg ul li{
    line-height: 1.6rem;
    margin-top: 8px;
  }
  .blogCntentText p {
width: 85%;
  }
  .shadow-lg p:nth-child(1){
    margin-top: 28px;
  }
  .blogContentSocialsList{
display: flex;
flex-direction: column;
align-items: center;
margin-top: 8px;
  }
 
  
 
.WhyPartner{
background-color: white;
align-self: center;
width:100%;
position: relative;
left:20%;
padding: 40px;
border-radius: 8px;
margin-top:82px;
}
.WhyPartnertext{
    width:80%;
}
.WhyPartnertext h3{
    margin-top: 16px;
} .blogCntentText2 h3{
    font-size: 1.2rem;
}
 
.blogCntentText{
    gap:0px
}
.WhyPartnertext h1{
    font-size: 2.2rem;
}.blogCntentText p{
    font-size: 0.9rem;
}.blogCntentText2 p{
    font-size: 0.8rem;
}
}

@media screen and (max-width:1125px){
    .blog-post {
        max-width: 65%;
    }
    .shadow-lg .intoBlogCont{
        margin: 28px 0 10px 0;
        font-size: 1.4rem;
      }
     .shadow-lg h1{
        font-size: 1rem;
      }
     .blogContentAside{
        width: 30%;
}.shadow-lg h2 {
    font-size: 18px;
}
    .shadow-lg p{
        font-size: 0.9rem;
    }
    .shadow-lg ul li{
        font-size: 0.7rem;
    }.blogContentSocials  img{
        width:70px;
        height: 40px;
    }
    
    .blogContentSocials a:nth-last-child(2) img{
        width:42px;
        height: 23px;
    }
    .blogContentSocials img:nth-child(2){
        height: 12px;
        width: 23px;
    }.WhyPartner{
        background-color: white;
        align-self: center;
        width:100%;
        position: relative;
        left:20%;
        padding: 40px;
        border-radius: 8px;
        margin-top:82px;
        }
}

@media screen and (max-width:482px){
    .blog-postContent{
        height:fit-content;
        padding: 28px 22px 48px 22px;
        display: flex;
        flex-direction: column;
        gap:42px;
      }
    .blog-post {
        max-width: 100%;
        order: 2;
    }
    .shadow-lg .intoBlogCont{
        margin: 28px 0 10px 0;
        font-size: 1.2rem;
      }
     .shadow-lg h1{
        font-size: 1.8rem;
      }
     .blogContentAside{
        width: 220px;
        padding-left:23px ;

        margin-top: -44px;
        order: 3;
        background-color: transparent;
}.shadow-lg h2 {
    font-size: 18px;
}
    .shadow-lg p{
        font-size: 0.8rem;
    }
    .shadow-lg ul li{
        font-size: 0.7rem;
    }.blogContentAside h3{
        align-self: flex-start;
    }
    
    .shadow-lg h2{
        font-size: 1rem;
    }.WhyPartner{
        background-color: white;
        align-self: center;
        width:85vw;
        position: relative;
        left:0%;
        }
        .WhyPartnertext{
            width:95%;
        }
        .WhyPartnertext h3{
            margin-top: 22px;
        } .blogCntentText2 h3{
            font-size: 1rem;
        }
         
        
        .WhyPartnertext h1{
            font-size: 1.8rem;
            
        }.blogCntentText2 p{
            font-size: 0.8rem;
        }
}


@media screen and (max-width:400px){
    
            .WhyPartnertext h1{
                font-size: 1.4rem;
                
            }
    }
@media screen and (max-width:350px){
    
            .WhyPartnertext h1{
                font-size: 1.1rem;
                
            }.shadow-lg h2 {
                font-size: 0.8rem;
            }.WhyPartnertext p{
               line-height: 1.3rem;
            }
    }

