

@media screen and (max-width:2225px){
  
.signup-log {
  background-image: url('./components/images/Newfolder/worksample2.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  
  
}
.form-group {
  margin-bottom: 1rem;
}

.signuponly form{
  background-color: rgb(71, 75, 106);
  height: fit-content;
  width: 43%;
  
  border-radius: 8px;
}
.loginlink{
  color: rgb(214, 131, 72);
}
.forogtpass{
  display: flex;
  justify-content: flex-start;
  font-size: 1.4rem;
  margin-left: 7px;
 
}
.forogtpass a{
  color:rgb(167, 166, 166)
}

.signup-log form{
  background-color: rgb(71, 75, 106);
  width: 44%;
 
  border-radius: 8px;
}
.signlo-formgroup{
  border-radius: 8px;
  background-color: rgb(71, 75, 106);
  padding: 0 40px 45px 40px;
  
  display: flex;
  flex-direction: column;
  gap: 25px;
}
.reset-div button{
  width: -moz-fit-content;
  width:fit-content;
}
.signup-log form .signuploginform-head{
  align-self: flex-start;
  margin-bottom: 25px;
  color: rgb(186, 185, 185);
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 8px;
  gap:7px;
  background-color: rgb(61, 65, 91);
  height: 42px;
}


.sign-lab-inp{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.em-samp{
  margin-left: 10px;
  font-size: 1.3rem;
  color: rgb(186, 185, 185);
}
.signup-log form .signuploginform-head p:first-child{
  margin-left: 50px;
}
.form-label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
}
.em-samp{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.signup-btn{
  border-radius: 13px;
  border: none;
  padding: 4px 10px;
  width:100%;
  background-color: #007bff;
}
.signup-btn:hover{
  cursor: pointer;
  background-color: #495057;
  
}
.reset-div{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap:20px;
  width: 100%;
}

.reset-div div{
  width:40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap:20px
}
.reset-div div input{
  width:100%
}
.navto-login{
  background-color: black;
  color:white;
  border-radius: 8px;
  padding: 4px 10px;
  margin-left: 11px;
}
.navto-login:hover{
box-shadow: -4px 4px 8px rgba(49, 49, 49, 0.527);

}
.form-control {
  display: block;
  width: 20%;
  padding: 0.375rem 0.75rem;
  font-size: 1.9rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip:padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}

.loginlink{
  color: grey;
}
.signuplink{
color: rgb(205, 118, 51);
}
.inp-btn{
display: flex;
flex-direction: column;
gap:20px
}
.inp-btn button{
width:70%;
align-self: center;
}
.signup-log-FORGET .signup-log-FORGETform{
height: fit-content;
}
}




@media screen and (max-width:1360px){

.signup-log {
    background-image: url('./components/images/Newfolder/worksample2.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    
    
  }
  .form-group {
    margin-bottom: 1rem;
  }
  
  .signuponly form{
    background-color: rgb(71, 75, 106);
    height: fit-content;
    width: 40%;
    padding-bottom: 0px;
    border-radius: 8px;
  }
  .loginlink{
    color: #03045E;
  }
  .forogtpass{
    display: flex;
    justify-content: flex-start;
    font-size: 0.8rem;
    margin-left: 7px;
   
  }
  .forogtpass a{
    color:rgb(167, 166, 166)
  }
  
  .signup-log form{
    background-color: rgb(71, 75, 106);
    height: fit-content;
    width: 40%;
   
    border-radius: 8px;
  }
  .signlo-formgroup{
    border-radius: 8px;
    background-color: rgb(71, 75, 106);
    padding: 0 40px 25px 40px;
    
    display: flex;
    flex-direction: column;
    gap: 25px;
  }
  .reset-div button{
    width: -moz-fit-content;
    width:fit-content;
  }
  .signup-log form .signuploginform-head{
    align-self: flex-start;
    margin-bottom: 25px;
    color: rgb(186, 185, 185);
    display: flex;
    align-items: center;
    width: 100%;
    border-radius: 8px;
    gap:7px;
    background-color: rgb(61, 65, 91);
    height: 42px;
  }
  
  
  .sign-lab-inp{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .em-samp{
    margin-left: 10px;
    font-size: 0.9rem;
    color: rgb(186, 185, 185);
  }
  .signup-log form .signuploginform-head p:first-child{
    margin-left: 50px;
  }
  .form-label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: bold;
  }
  .em-samp{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .signup-btn{
    border-radius: 13px;
    border: none;
    padding: 4px 10px;
    width:100%;
  }
  .signup-btn:hover{
    cursor: pointer;
    background-color: #495057;
    
  }
  .reset-div{
    display: flex;
    flex-direction: column;
    gap:20px
  }
  .navto-login{
    background-color: black;
    color:white;
    border-radius: 8px;
    padding: 4px 10px;
    margin-left: 11px;
  }
  .navto-login:hover{
  box-shadow: -4px 4px 8px rgba(49, 49, 49, 0.527);

  }
  .form-control {
    display: block;
    width: 20%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  
  .btn-primary {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
  }
  
  .btn-primary:hover {
    color: #fff;
    background-color: #010101;
  }
  
  .loginlink{
    color: grey;
  }
.signuplink{
  color: #03045E;
}
.inp-btn{
  display: flex;
  flex-direction: column;
  gap:20px
}
.inp-btn button{
  width:70%;
  align-self: center;
}
.signup-log-FORGET .signup-log-FORGETform{
height: fit-content;
}
 }
 
 
 
 @media screen and (max-width:482px){
    .signup-log form{
      background-color: rgb(71, 75, 106);
      height: 335px;
      width: 84%;
      
      
      border-radius: 8px;
    }
    .signuponly form{
      margin-bottom:215px;
    }
    
  }


  @media screen and (max-width:387px){
    .signup-log form{
      background-color: rgb(71, 75, 106);
      
      height: 335px;
      width: 84%;
      
      border-radius: 8px;
    }
    .signuponly form{
      margin-bottom:315px;
      height: 235px;
    }
    .signuponly form .em-samp{
     font-size: 0.7rem;
    }
    .signuponly form input{
     height: 28px;
    }
    
  }


  @media screen and (max-width:337px){
   
    .signuponly form{
      margin-bottom:315px;
      height: 85px;
    }
    .signuponly form .em-samp{
     font-size: 0.54rem;
    }
    .signuponly form input{
     height: 18px;
      }
    .signuponly form .signup-btn{
     height: 22px;
     font-size: 0.6rem;
     margin-top: -9px;
      }
    
  }
 
