
*{
  margin:0;
  padding:0;
  box-sizing: border-box;
}
a{
  text-decoration: none;
  color: white;
}
.App {
  text-align: center;
  margin:0;
  padding:0;
  box-sizing: border-box;
  overflow-x: hidden;
}
.btn:hover{
  cursor:pointer;
  background-color: #282c34;
  color:white;
  transition: 0.6s;
}
.loaderr{
    
    
  margin:250px 0 0 48%;
}
.lockscreen {
  display: none;
}

@media screen and (min-width: 365px) and (max-width: 757px) and (orientation: landscape) {
  .lockscreen {
    display: block;
    font-size: 0.5rem;
    width: 360px;
    align-self: center;
    flex-wrap: wrap;
    margin: 30px 50px;
  }
  
  .App {
    display: none;
    overflow: hidden;
  }
  .hidden {
    display: block;
  }
}

@media screen and (min-width: 320px) and (max-width: 868px) and (orientation: portrait) {
  .lockscreen {
    display: none;
  }

  .App {
    display: block;
  }
  .loaderr{
    
    
    margin:250px 0 0 48%;
  }
}



@media (prefers-reduced-motion: no-preference) {

}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
