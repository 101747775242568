@import url('https://fonts.googleapis.com/css2?family=Old+Standard+TT&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Yeseva+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Namdhinggo:wght@400;500;600;700;800&family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&family=Darker+Grotesque:wght@300..900&display=swap');


@media screen and (max-width:2225px){
    .hero {
        height: 100vh;
        width: 100vw;
        margin: 0;
        padding: 0;
        background-color: white;
    }
    .heroImgandbg{
        width: 100%;
        height: 100%;
        background-image: url('../../components/images/Newfolder/SimienMountains3.jpg'); /* Replace 'your-image.jpg' with your image URL */
        background-size: cover;
        background-position:center;
        position: relative;
        background-repeat: no-repeat;
    }
    /* Full-screen background image */
    
    
    /* Blue overlay with less opacity */
    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        /* Blue color with 50% opacity */
        background-color:  rgba(3, 4, 94, 0.76); /* Blue color with 50% opacity */
    }
    .heroContents{
        z-index: 49;
        position: absolute;
        bottom: 0;
        left:10%;
        display: flex;
        gap:48px;
        align-items: flex-start;
        height: 77%;
        width: 90%;
        color: white;
    }

    .heroContents img{
        height: 100%;
        width: 724px;
    }
    .heroContentTexts{
        display: flex;
        flex-direction: column;
        gap:42px;
        align-items: center;
        text-align: center;
        }
        .heroContenttext {
            width:70%;
            font-size: 2.2rem;
            text-align: center;
    }
    .heroContenttext p{
        width:90%;
        font-family: "Darker Grotesque", sans-serif;
        font-optical-sizing: auto;
        font-weight: 300;
      line-height: 2.2rem;
        
    }
    @import url('https://fonts.googleapis.com/css2?family=Chocolate+Classical+Sans&family=Noto+Sans+JP:wght@100..900&display=swap');
    @import url('https://fonts.googleapis.com/css2?family=Chocolate+Classical+Sans&display=swap');
    @import url('https://fonts.googleapis.com/css2?family=Chocolate+Classical+Sans&family=DM+Serif+Text:ital@0;1&family=Noto+Sans+JP:wght@100..900&display=swap');
    
    
    
    .heroSlogan{
        font-size: 94px; 
        font-family: "Darker Grotesque", sans-serif;
      font-optical-sizing: auto;
      font-weight: 300;
      font-family: "Chocolate Classical Sans", sans-serif;
      font-weight: 400;
      font-style: normal;
    
      font-family: "Noto Sans JP", sans-serif;
      font-optical-sizing: auto;
      font-weight: 300;
      font-style: normal;
    
      font-family: "DM Serif Text", serif;
      font-weight: 400;
      font-style: normal;
    }
    .scrolldown{
        position: absolute;
        left: 51%;
        bottom: 25px;
        align-items: center;
        display: flex;
        flex-direction: column;
        color: white;
       z-index: 59;
    }
    .scrolldown span{
        font-size: 1.4rem;
        
    }
    .scrolldownbtn{
        width:50px;
        position: relative;
        height: 75px;
        color: white;
        display: flex;
        align-items: end;
        justify-content: center;
        background-color: rgba(33, 33, 33, 0.874);
        border: 2px solid rgb(255, 255, 255);
        border-radius: 18px;
        cursor: pointer;
        z-index:88 ;
    }
    .scrolldownbtn:hover{
        background-color: rgb(255, 255, 255);
        border: 2px solid rgb(0, 0, 0);
        color:black;
        animation: upAndDown 1s infinite alternate;
    }
    .curved-text{
        margin-top: 0px;
    }
    @keyframes upAndDown {
        from {
            bottom: 2px;
        }
        to {
            bottom: 7px;
        }
    }
    .scrolldownbtn span{
        position: relative;
        /* animation: upAndDown 2s infinite alternate; */
    }
    .heroContentbtn{
        display: flex;
        justify-content: space-between;
        width:699px;
        margin-top: 26px;
    }
    .heroContentbtn button{
        width:206px;
        height: fit-content;
        padding: 22px 38px;
        border-radius: 8px;
        border: 1px solid white;
        background-color: transparent;
    cursor: pointer;
    font-size: 2rem;
    }
    .heroContentbtn button a{
        color: white;
        text-decoration: none;
    }
    .heroContentbtn button:hover{
        background-color: rgb(1, 1, 78);
    }
    .heroBottomContent{
        display: flex;
        align-items: center;
        gap:20px;
        position: absolute;
        font-style: normal;
      font-family: "Anton", sans-serif;
      font-weight: lighter;
        bottom: -104px;
        letter-spacing: 0.2rem;
        left:45%;
         font-size: 1.4rem; 
    
    }
    .heroBottomContent img{
        width:123px;
        height: 100px;
    }
    .displaypopupApply{
        position: absolute;
        bottom:40%;
        background-color: white;
        border-top-right-radius: 8px;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        padding: 8px 22px;
        color: black;
    }
    
    .customer-support{
        position: fixed;
        top:86%;
        right:0%;
        background-color: #03045E;
       
        border: 1px solid white;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 170px;
        height: 140px;
        z-index: 8;
        
    }
    .customersup-icon{
    font-size:47px;
    transform: rotate(20deg);
    color: white;
    }
    .customer-support:hover{
        cursor: pointer;
        margin-left: 3px;
        margin-top: -3px;
        transition: 0.4s;
    }
    
    
    
    .companiesfeedback{
        position: fixed;
        top:24%;
        left:35%;
        background-color: white;
        border: 1px solid black ;
        border-radius: 7px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap:7px;
        height: fit-content;
        width: 430px;
        z-index: 60;
        padding: 20px 35px;
        box-shadow: -8px 8px 8px rgba(82, 81, 81, 0.749);
        background-image: url('../images/Newfolder/vectorbg.png');
        background-position: center;
        background-size: cover;
    }
    .companiesfeedback p{
        font-size: 0.9rem;
        display: flex;
        align-items: center;
    }
    .companiesfeedback h2{
        font-family: "Playfair Display", serif;
        font-optical-sizing: auto;
        font-weight: bolder;
        
    }
    
    .companiesfeedback textarea{
        margin-top: 14px;
        padding: 7px 10px;
        width:320px;
    }
    .feedback_emojy{
        width:30px;
        height: 25px;
        color:orange;
        margin-left: 4px;
    }
    .success-message-infeedback{
        color: rgb(237, 119, 16);
    
        z-index: 197;
    }
    
    .cancel-ok{
        display: flex;
        justify-content: space-between;
        width:130px;
        align-self: flex-end;
        font-size: 0.7rem;
        margin: 5px 0;
    }
    
    .cancel-ok button{
        background-color: rgb(244, 39, 39);
        color: white;
        border-radius: 4px;
    padding: 2px 15px;
    }
    .cancel-ok button:hover{
        cursor: pointer;
    }
    
    }

    @media screen and (max-width:1945px) {
.hero {
    height: 100vh;
    width: 100vw;
    margin: 0;
    padding: 0;
    background-color: white;
}

/* Full-screen background image */


/* Blue overlay with less opacity */
.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.heroContents{
    z-index: 49;
    position: absolute;
    bottom: 0;
    left:10%;
    display: flex;
    gap:48px;
    align-items: flex-start;
    height: 77%;
    width: 90%;
    color: white;

}
.heroContents img{
    height: 100%;
    width: 724px;
}
.heroContentTexts{
    display: flex;
    flex-direction: column;
    gap:28px;
    align-items: center;
    text-align: center;
    }
    .heroContenttext {
        width:70%;
        font-size: 2rem;
        text-align: center;
}
.heroContenttext p{
    width:90%;
    font-family: "Darker Grotesque", sans-serif;
    font-optical-sizing: auto;
    font-weight: 300;
  line-height: 2.2rem;
    
}
@import url('https://fonts.googleapis.com/css2?family=Chocolate+Classical+Sans&family=Noto+Sans+JP:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Chocolate+Classical+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Chocolate+Classical+Sans&family=DM+Serif+Text:ital@0;1&family=Noto+Sans+JP:wght@100..900&display=swap');



.heroSlogan{
    font-size: 92px; 
    font-family: "Darker Grotesque", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-family: "Chocolate Classical Sans", sans-serif;
  font-weight: 400;
  font-style: normal;

  font-family: "Noto Sans JP", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;

  font-family: "DM Serif Text", serif;
  font-weight: 400;
  font-style: normal;
}
.scrolldown{
    position: absolute;
    left: 47.2%;
    bottom: 25px;
    align-items: center;
    display: flex;
    flex-direction: column;
    color: white;
   z-index: 59;
}
.scrolldown span{
    font-size: 1.4rem;
    
}
.scrolldownbtn{
    width:50px;
    position: relative;
    height: 75px;
    color: white;
    display: flex;
    align-items: end;
    justify-content: center;
    background-color: rgba(33, 33, 33, 0.874);
    border: 2px solid rgb(255, 255, 255);
    border-radius: 18px;
    cursor: pointer;
    z-index:88 ;
}
.curved-text{
    margin-top: 0px;
}
@keyframes upAndDown {
    from {
        bottom: 2px;
    }
    to {
        bottom: 7px;
    }
}
.scrolldownbtn span{
    position: relative;
    /* animation: upAndDown 2s infinite alternate; */
}
.heroContentbtn{
    display: flex;
    justify-content: space-between;
    width:538px;
    margin-top: 18px;
}
.heroContentbtn button{
    width:186px;
    height: fit-content;
    padding: 16px 32px;
    border-radius: 8px;
    border: 1px solid white;
    background-color: transparent;
cursor: pointer;
font-size: 1.7rem;
}

.heroContentbtn button:hover{
    background-color: rgb(1, 1, 78);
}
.heroBottomContent{
    display: flex;
    align-items: center;
    gap:20px;
    position: absolute;
    font-style: normal;
  font-family: "Anton", sans-serif;
  font-weight: lighter;
    bottom: -104px;
    letter-spacing: 0.2rem;
    left:45%;
     font-size: 1.4rem; 

}
.heroBottomContent img{
    width:123px;
    height: 100px;
}
.displaypopupApply{
    position: absolute;
    bottom:40%;
    background-color: white;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    padding: 8px 22px;
    color: black;
}

.customer-support{
    position: fixed;
    top:86%;
    right:0%;
    background-color: #03045E;
   
    border: 1px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 110px;
    z-index: 8;
    
}
.customersup-icon{
font-size:27px;
transform: rotate(20deg);
color: white;
}
.customer-support:hover{
    cursor: pointer;
    margin-left: 3px;
    margin-top: -3px;
    transition: 0.4s;
}



.companiesfeedback{
    position: fixed;
    top:24%;
    left:35%;
    background-color: white;
    border: 1px solid black ;
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:7px;
    height: fit-content;
    width: 430px;
    z-index: 60;
    padding: 20px 35px;
    box-shadow: -8px 8px 8px rgba(82, 81, 81, 0.749);
    background-image: url('../images/Newfolder/vectorbg.png');
    background-position: center;
    background-size: cover;
}
.companiesfeedback p{
    font-size: 0.9rem;
    display: flex;
    align-items: center;
}
.companiesfeedback h2{
    font-family: "Playfair Display", serif;
    font-optical-sizing: auto;
    font-weight: bolder;
    
}

.companiesfeedback textarea{
    margin-top: 14px;
    padding: 7px 10px;
    width:320px;
}
.feedback_emojy{
    width:30px;
    height: 25px;
    color:orange;
    margin-left: 4px;
}
.success-message-infeedback{
    color: rgb(237, 119, 16);

    z-index: 197;
}

.cancel-ok{
    display: flex;
    justify-content: space-between;
    width:130px;
    align-self: flex-end;
    font-size: 0.7rem;
    margin: 5px 0;
}

.cancel-ok button{
    background-color: rgb(244, 39, 39);
    color: white;
    border-radius: 4px;
padding: 2px 15px;
}
.cancel-ok button:hover{
    cursor: pointer;
}

}


@media screen and (max-width:1845px){
    .scrolldown{
        position: absolute;
        left: 50%;
       
    }
    .scrolldownbtn{
        width:35px;
        position: relative;
        height: 55px;
    }
    .scrolldown span{
        font-size: 1.1rem;
        
    }
}
@media screen and (max-width:1765px){
    .heroBottomContent{
        display: flex;
        align-items: center;
        gap:20px;
        position: absolute;
        font-style: normal;
      font-family: "Anton", sans-serif;
      font-weight: lighter;
        bottom: -90px;
        letter-spacing: 0.2rem;
        left:47%;
         font-size: 1.4rem; 
    
    }
   
    .heroBottomContent img{
        width:93px;
        height: 80px;
    }
    .heroContenttext {
        width:70%;
}
.scrolldown{
    position: absolute;
    left: 52%;
   
}


}
@media screen and (max-width:1690px){
    .heroBottomContent{
        
        bottom: -87px;
        letter-spacing: 0.2rem;
        left:50%;
         font-size: 1.4rem; 
    
    }
    
    .heroContenttext {
        width:70%;
        font-size: 1.9rem;
}
.heroSlogan{
    font-size: 50px; 
}
.heroContentbtn{
    display: flex;
    justify-content: space-between;
    width:440px;
    margin-top: 12px;
}
.heroContentbtn button{
    width:164px;
    height: fit-content;
    padding: 14px 33px;
    border-radius: 8px;
    border: 1px solid white;
    background-color: transparent;
cursor: pointer;
font-size: 1.6rem;
}
.customer-support{
  right:2%;
    width: 100px;
    height: 90px;
    z-index: 8;
    
}
}


@media screen and (max-width:1575px){
    .scrolldown{
        position: absolute;
        left: 49%;
       
    }
    .scrolldownbtn{
        width:25px;
        position: relative;
        height: 55px;
    }
    .scrolldown span{
        font-size: 1.1rem;
        
    }
    .heroContenttext {
        width:70%;
        font-size: 1.3rem;
    }
.heroSlogan{
    font-size: 40px; 
}
.heroContentbtn{
    display: flex;
    justify-content: space-between;
    width:402px;
    margin-top: 18px;
}
.heroContentbtn button{
    width:136px;
    height: fit-content;
    padding: 12px 22px;
    border-radius: 8px;
    border: 1px solid white;
    background-color: transparent;
cursor: pointer;
font-size: 1.3rem;
}
.heroContents{
   
    left:15%;
}
}
@media screen and (max-width:1365px){
.hero {
    height: 100vh;
    width: 100vw;
    margin: 0;
    padding: 0;
    background-color: white;
}
.heroImgandbg{
    width: 100%;
    height: 100%;
    
}
/* Full-screen background image */


/* Blue overlay with less opacity */
.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
   

}
.heroContents{
    z-index: 49;
    position: absolute;
    bottom: 0;
    left:10%;
    display: flex;
    gap:48px;
    align-items: flex-start;
    height: 75%;
    width: 80%;
    color: white;

}
.heroContents img{
    height: 100%;
    width: 472px;
}
.heroContentTexts{
    display: flex;
    flex-direction: column;
    gap:14px;
    align-items: center;
    text-align: center;
    }
    .heroContenttext {
        width:60%;
        font-size: 1.2rem;
        text-align: center;
}
.heroContenttext p{
    width:100%;
    font-family: "Darker Grotesque", sans-serif;
    font-optical-sizing: auto;
    font-weight: 300;
  line-height: 1.6rem;
    
}
.customer-support{
   
    width: 70px;
    height: 70px;
    z-index: 8;
    
}
@import url('https://fonts.googleapis.com/css2?family=Chocolate+Classical+Sans&family=Noto+Sans+JP:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Chocolate+Classical+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Chocolate+Classical+Sans&family=DM+Serif+Text:ital@0;1&family=Noto+Sans+JP:wght@100..900&display=swap');



.heroSlogan{
    font-size: 42px; 
    font-family: "Darker Grotesque", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-family: "Chocolate Classical Sans", sans-serif;
  font-weight: 400;
  font-style: normal;

  font-family: "Noto Sans JP", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;

  font-family: "DM Serif Text", serif;
  font-weight: 400;
  font-style: normal;
}
.heroContentbtn{
    display: flex;
    justify-content: space-between;
    width:390px;
    margin-top: 12px;
}
.heroContentbtn button{
    width:132px;
    height: fit-content;
    padding: 12px 28px;
    border-radius: 8px;
    border: 1px solid white;
    background-color: transparent;
cursor: pointer;
font-size: 1.2rem;
}

.heroContentbtn button:hover{
    background-color: rgb(1, 1, 78);
}
.heroBottomContent{
    display: flex;
    align-items: center;
    gap:20px;
    position: absolute;
    font-style: normal;
  font-family: "Anton", sans-serif;
  font-weight: lighter;
    bottom: -54px;
    letter-spacing: 0.2rem;
    left:45%;
     font-size: 0.8rem; 

}
.heroBottomContent img{
    width:60px;
    height: 48px;
}
.displaypopupApply{
    position: absolute;
    bottom:40%;
    background-color: white;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    padding: 8px 22px;
    color: black;
}
.scrolldown{
    position: absolute;
    left: 48%;
    bottom: 15px;
    align-items: center;
    display: flex;
    flex-direction: column;
    color: white;
   
}
.scrolldown span{
    font-size: 0.6rem;
    
}
.scrolldownbtn{
    width:20px;
    position: relative;
    height: 35px;
    color: white;
    display: flex;
    align-items: end;
    justify-content: center;
    background-color: rgba(33, 33, 33, 0.874);
    border: 2px solid rgb(255, 255, 255);
    border-radius: 18px;
    cursor: pointer;
    z-index:88 ;
}
.curved-text{
    margin-top: 0px;
}
@keyframes upAndDown {
    from {
        bottom: 2px;
    }
    to {
        bottom: 7px;
    }
}
.scrolldownbtn span{
    position: relative;
    /* animation: upAndDown 2s infinite alternate; */
}
}

@media screen and (max-width:860px){
    .hero {
        height: 66rem;
    }
    .heroContents{
        z-index: 49;
        position: absolute;
        bottom: 0;
        left:5%;
        display: flex;
        flex-direction: column;
        gap:48px;
        align-items: center;
        height: 77%;
        width: 90%;
        color: white;

    
    }
    .heroContentTexts{
        order: 1;
        margin-top: -70px;
    }
    .heroContents img{
        height: 80%;
        width: 762px;
        order: 2;
    }
    .heroSlogan{
        font-size: 48px; 
    }
    .heroContenttext {
        width:85%;
        font-size: 1.8rem;
        margin-left: 46px;
        text-align: center;
}
.heroContenttext p{
    width:90%;
    font-family: "Darker Grotesque", sans-serif;
    font-optical-sizing: auto;
    font-weight: 300;
  line-height: 2.4rem;
    
}.heroContentbtn button{
    width:122px;
    height: fit-content;
    padding: 13px 26px;
    border-radius: 8px;
    border: 1px solid white;
    background-color: transparent;
cursor: pointer;
font-size: 1.3rem;
}
}


@media screen and (max-width:482px){
    .hero {
        height: 100vh;
        overflow-y: hidden;
    }
    .heroContents{
        z-index: 49;
        position: absolute;
        bottom: 0;
        left:5%;
        display: flex;
        flex-direction: column;
        gap:28px;
        align-items: center;
        height: 77%;
        width: 90%;
        color: white;
        

    
    }
    .heroBottomContent{
        display: none;
    }
    .heroContentTexts{
        order: 1;
        margin-top: -40px;

        }
         .heroContenttext{
            width: 100%;
         }
        .heroContenttext p{
        font-size: 1rem;
        width:85%;
        font-family: "Darker Grotesque", sans-serif;
        font-optical-sizing: auto;
        font-weight: 300;
      line-height: 2rem;
        
    }
    .heroImgandbg{
        width: 100%;
        height: 100%;
    }
   
    .heroContents img{
        display: none;
    }
    .heroSlogan{
        font-size: 28px; 
    }
   
    .heroContentTexts{
        gap:38px;
        }
        .curved-text{
            margin-top: 4px;
        }
        .heroContentbtn{
            display: flex;
            justify-content: space-between;
            width:290px;
            margin-top: 12px;
        }
        .heroContentbtn button{
            width:102px;
            height: fit-content;
            padding: 8px 16px;
            border-radius: 8px;
            border: 1px solid white;
            background-color: transparent;
        cursor: pointer;
        font-size: 1rem;
        }
        
    
.companiesfeedback{
    left:10%;
    width: 340px;
    z-index: 59;
    padding: 20px 15px;
    
}
.companiesfeedback p{
    font-size: 0.5rem;
}
.companiesfeedback h2{
    font-size: 0.9rem;
}
.companiesfeedback textarea{
    width:195px;
}

}

@media screen and (max-width:413px){
    .heroSlogan{
            font-size: 22px; 
        }
        .heroContentTexts{
            gap:22px;
            }
    }
@media screen and (max-width:397px){
    .heroContenttext p{
        font-size: 0.9rem;
        line-height: 1.7;
    }
     .companiesfeedback{
        
        width: 295px;
        padding: 20px 15px;
        
    }
    .scrolldown{
        position: absolute;
        left: 46%;
        bottom: 65px;
        
       
    }
}


@media screen and (max-width:350px){
    .heroContentTexts{
        
        margin-top: 0px;

        }
    .heroContenttext p{
        font-size: 0.7rem;
        line-height: 1.5rem;
        width: 70%;
    }
    .heroSlogan{
        font-size: 20px; 
    }
    .heroContentbtn{
        width:270px;
    }
    .heroContentbtn button{
        width:78px;
        height: fit-content;
        padding: 6px 16px;
    font-size: 0.7rem;
    }
    .scrolldownbtn{
        width:15px;
        position: relative;
        height: 25px;
        
    }
    .scrolldown{
       bottom: 10px;
        left: 46%;
    }
    .heroContentbtn{
        display: flex;
        justify-content: space-between;
        width:230px;
        margin-top: 12px;
    }
    }
    
@media screen and (max-width:336px){
    .heroSlogan{
        font-size: 18px; 
    }
}