.usersetmessage{
    position: absolute;
    top: 40%;
    left:27%;
    width:40%;
    height: fit-content;
    padding: 20px 17px 37px 17px;
    background-color: rgb(227, 51, 80);
    border: 2px solid rgb(255, 255, 255);
    box-shadow: -8px 12px 27px rgba(62, 60, 60, 0.251);
    border-radius: 8px;
    color: white;
    display: flex;
    flex-direction: column;
    gap:10px;
    align-items: center;
}
.closebtn_usrdash{
    background-color: blue;
    color: white;
    cursor: pointer;
    border-radius: 8px ;
    padding: 6px 14px;
}
.userdash_set  .userdash_main_settings{
    padding: 22px 0;
    height: 67%;
    box-shadow: -8px 12px 27px rgba(62, 60, 60, 0.251);
}

.userdash_main_settings h2{
    
    text-align: start;
}
.backnavigationandhead{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 43px;
    gap:17px
}
.backnavigationandhead a{
color: black;
}


.usersetmessage p:nth-child(1){
   border-bottom: 1px solid black;
   padding-bottom: 14px;
   width: 70%;
   font-size: 1rem;
}
.usersetmessage p:nth-child(2){
    font-size: 0.8rem;
    font-weight: lighter;
}
.usersetmessage p:nth-child(1) span a{
    font-size: 0.67rem;
    font-weight: lighter;
    color:white;
    background-color: blue;
    border-radius: 8px;
    padding: 3px 8px 4px 8px;
    align-self: center;
    width: 50px;
    font-size: 0.6rem;
    cursor: pointer;
}
.usersetmessage p:nth-child(2) span a:hover{
    background-color:rgb(5, 5, 142) ;
}

.userdash_main_settings form{
    padding: 27px 20px;
    height: 93%;
    display: grid;
    grid-template-columns: 240px 240px 240px;
    grid-template-rows: 70px 70px 70px ;
}

.userdash_main_settings form div input{
    background-color: rgb(227, 226, 226);
    border-radius: 6px;
    padding: 4px 12px;
    outline: none;
    border: none;
}
.usersettingbutton{
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 3;
    grid-row-end: 3;
    margin: 15px 90px 0 0;
}

.usersettingbutton button{
    background-color: blue;
    color: white;
    padding: 7px 18px;
    border-radius: 6px;
    margin-top: -80px;
}




@media screen and (max-width:439px){
    .usersettingbutton2{
        
        margin: -70px 90px 0 0;
    }
    .usersetmessage{
       top: 60%;
        left:20%;
        width:60%;
        height: fit-content;
        z-index: 99;
       gap:60px
    }
    .usersetmessage p:nth-child(1){
        font-size: 0.9rem;
    }
        .usersetmessage p:nth-child(2){
font-size: 0.7rem;
margin-bottom: -17px;
        }
    .glasseffect_settings .userdash_main{
        overflow-y: hidden;
        height: 33rem;
    }
    .glasseffect_settings .userdash_main form div label{
        font-size: 0.8rem;
    }.usersettingbutton2 input{
margin-top: -170px;
    }
}


@media screen and (max-width:379px){
    .glasseffect_settings .userdash_main form{
        margin-left: -27px;
    }
}
@media screen and (max-width:330px){
    .glasseffect_settings{
        height: 94rem;
    }
    .glasseffect_settings .userdash_main{
        height: 32rem;

    }

    .usersetmessage{
        top: 60%;
         left:10%;
         width:70%;
        gap:60px
     }
     .usersetmessage p:nth-child(1){
         font-size: 0.6rem;
     }
         .usersetmessage p:nth-child(2){
 font-size: 0.45rem;
 margin-bottom: -17px;
         }
}