.success-messagee{
    top:70px
}
.sendmess_fromusers_form div{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:10px;
}

.sendmess_fromusers_form div input{
    width:170px
}


@media screen and (max-width:769px){
    .glasseffect_sendmessage{
        height: 90rem;
    }
   
.userdash_main form{
    display: grid;
    grid-template-columns: 330px 330px;
    grid-template-rows: 90px 90px 90px  90px ;
}
.userdash_main form div{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:10px
}
.userdash_main form .usersettingbutton{
    grid-row-start: 4;
    grid-row-end: 4;
}
}



@media screen and (max-width:439px){
    
    .glasseffect_sendmessage{
         padding: 40px 0 30px 0;
    }
   
.userdash_main form{
    display: grid;
    grid-template-columns: 130px;
    grid-template-rows: 90px 90px 90px  90px 80px 90px 90px;
    padding: 44px 0 4px 120px;
}
.userdash_main form div{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:10px
}
.userdash_main form .usersettingbutton{
    grid-row-start: 7;
    grid-row-end: 7;
}
.dash_side_bottom_mess{
    padding-left: 40px;
    
   }
   .glasseffect_sendmessage .userdash_main{
    overflow-y: hidden;
    background-color: rgb(43, 90, 132);
    height: fit-content;
    padding-bottom: 120px;

}
}
@media screen and (max-width:379px){
    .dash_side_bottom_mess{
        padding-left: 17px;
       }
}

@media screen and (max-width:392px){
    .dash_side_bottom_mess{
        padding-left: 10px;
        
       }
}
@media screen and (max-width:369px){

    .userdash_main form{
        padding: 44px 0 4px 100px;
    }
    .userdash_main form div{
        gap:10px
    }
    
}
@media screen and (max-width:330px){
    .userdash_main form{
        padding: 44px 0 4px 90px;
    }
    .userdash_main form div{
        gap:18px;
    }
    .dash_side_bottom_mess{
        padding-left: 0px;
        
       }
}