@import url('https://fonts.googleapis.com/css2?family=Acme&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Acme&family=Bentham&display=swap');


@media screen and (max-width:2233px){
    
    .blogsSection{
    width: 100vw;
    height: fit-content;
    display: flex;
    justify-content: center;
background-color: #EDEDED;
    align-items: flex-start;
    padding: 84px 0;
    padding-top: 70px;    
}

.blogsSection_left{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: -243px;
    gap:36px;
    width: 80%;margin-top: 70px;
}
.blogsSection_left ul{
    display: flex;
    flex-wrap: wrap;
    width:90%;
    gap:63px;

}
.blogsSection_left ul li{
width: 25%;
list-style: none;
height: 560px;
border-radius: 8px;
display: flex;
flex-direction: column;
background-color: white;
box-shadow: -4px 4px 18px rgba(90, 90, 90, 0.395);
}
.blogListIcons:hover{
    position: relative;
    left:4px;
    bottom: 4px;
    cursor: pointer;
transform: 0.9s;
}
.blogsSection_left ul li div {
    gap:14px;
    align-items: center;
    text-align: start;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 44%;
    align-items: flex-end;
    padding-top: 12px ;
}
.blogsSection_left h1{
    text-align: start;
}
.blogListIcons{
    width:42px;
    height: 38px;
    background-color: #2840b8c4;
    border-radius: 50%;
    color: white;
    margin-bottom: -44px;
}
.blogsSection_left ul li img{
    width: 100%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    height: 40%;
    
}
.blog_heads{
    color: #071B82;
    font-family: "Bentham", serif;
  font-weight: 400;
  font-style: normal;
  align-self: flex-start;
}
.blogsSection_aside{
    display: flex;
    flex-direction: column;
    gap:33px;
    width: 12%;margin-top: 70px;

}
.blogsSection_aside ul{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 260px;
}
.blogsSection_aside ul li{
    list-style: none;
    display: flex;
    width:100%;
    gap:26px;
    align-items: center;
    font-family: "Bentham", serif;
    font-weight: 400;
    font-style: normal;
    

}

.blogsSection_aside ul li p:nth-child(1){
    background-color: #071B82;
    padding: 12px 24px;
    font-size: 1rem;
    color: white;
}
.blogsSection_aside ul li p:nth-child(2){
    
    font-size: 1.1rem;
}
.blogsSection_left ul li div h2{
    font-family: "Acme", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.blogsSection_left ul li div p{
    font-family: "Bentham", serif;
  font-weight: 400;
  line-height: 1.6rem;
  font-style: normal;
}
}


@media screen and (max-width:1760px){
   
    .blogsSection_left ul li{
            width: 25%;
            list-style: none;
            height:482px;
            }.blogListIcons{
                width: 32px;
                height: 33px;
            }
    }
    @media screen and (max-width:1450px){
        .blogsSection_left{
            margin-top: 40px;
        }
        .blogsSection_aside{
           margin-top: 40px;
        
        }
        .blogsSection_left ul li div p {
            font-size: 1rem;
                }
                .blogsSection_left ul li div h2 {
            font-size: 1.1rem;
                }
                
    }
    
@media screen and (max-width:1360px){
    .blogsSection_left ul li div p {
font-size: 0.9rem;
    }
    .blogsSection_left ul li div h2 {
font-size: 0.9rem;
    }.blogsSection_aside{
        display: flex;
        flex-direction: column;
        gap:33px;
        width: 12%;
    
    }.blogsSection_left{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-right: -243px;
        gap:36px;
        width: 90%;
    }
    .blogsSection_left ul{
        display: flex;
        flex-wrap: wrap;
        width:80%;
        gap:43px;
    
    }
    .blogsSection_aside ul{
        height: 180px;
    }
        .blogsSection_left ul li img{
            
            height: 40%;
            
        }
        .blogsSection_left ul li{
            width: 28%;
            list-style: none;
            height: 430px;
            }
            .blogsSection_aside ul li p:nth-child(1){
                background-color: #071B82;
                padding: 8px 16px;
                font-size: 0.7rem;
            }
            .blogsSection_aside ul li p:nth-child(2){
                
                font-size: 0.7rem;
            }
            .blog_heads{
              font-size: 1.2rem;
            }.blogListIcons{
                width:28px;
                height: 28px;
            }
}


@media screen and (max-width:1260px){
    .blogsSection_left ul li{
        height:432px;
        }
    .blogsSection_left ul li div p {
        font-size: 0.9rem;
            }
            .blogsSection_left ul li div h2 {
        font-size: 0.9rem;
            }
}
@media screen and (max-width:1160px){
    .blogsSection_aside{
        width: 10%;
    
    }
    .blogsSection_left{
        margin-right: -183px;
        gap:36px;
        width: 90%;
    }.blogsSection_aside ul li p:nth-child(1){
        background-color: #071B82;
        padding: 8px 18px;
        font-size: 0.9rem;
        color: white;
    }
    .blogsSection_aside ul li p:nth-child(2){
        
        font-size: 0.9rem;
    }
    .blogsSection_left ul li{
        height:432px;
        width: 35%;
        }
    .blogsSection_left ul li div p {
        font-size: 0.9rem;
            }
            .blogsSection_left ul li div h2 {
        font-size: 0.9rem;
            }
}

@media screen and (max-width:789px){
    .blogsSection_aside{
        width: 10%;
    
    }
    .blogsSection_left{
        margin-right: -183px;
        gap:36px;
        width: 90%;
    }.blogsSection_aside ul li p:nth-child(1){
        background-color: #071B82;
        padding: 8px 18px;
        font-size: 0.9rem;
        color: white;
    }
    .blogsSection_aside ul li p:nth-child(2){
        
        font-size: 0.9rem;
    }
    .blogsSection_left ul li{
        height:432px;
        width: 35%;
        }
    .blogsSection_left ul li div p {
        font-size: 0.8rem;
            }
            .blogsSection_left ul li div h2 {
        font-size: 0.9rem;
            }.blog_heads{
                font-size: 0.8rem;
              }
}
@media screen and (max-width:482px){
    .blogsSection_left{
        margin-right: 0;
        gap:36px;
        width: 100%;
        text-align: start;
    }
    .blogsSection_left ul{
        justify-content: center;
        display: flex;
        flex-wrap: wrap;
        width:100%;
        gap:63px;
    
    }.blog_heads1{
        margin-left: 70px;
    }.blogListIcons:hover{
        margin-right: -8px;
        margin-top: -8px;
        cursor: pointer;
    }
    .blogsSection_left ul li{
        height:482px;
        width: 70%;
        } 
        .blogsSection_left ul li img{
            
            height: 40%;
            
        }
           .blogsSection_left ul li div p {
            font-size: 1rem;
                }
                .blogsSection_left ul li div h2 {
            font-size: 1rem;
                }
    .blogsSection_aside{
        display: flex;
        flex-direction: column;
        gap:14px;
        width: 100%;
        padding-bottom: 43px;
        margin-left: 42px;
    
    }
    .blogsSection_aside ul{
        display: grid;
        grid-template-columns: 230px 230px;
        grid-template-rows: 60px 60px;
        height: 120px;
        width: 90%;
    }.blogsSection_aside ul li p:nth-child(1){
        background-color: #071B82;
        padding: 6px 12px;
        font-size: 0.7rem;
        color: white;
    }
    .blogsSection_aside ul li p:nth-child(2){
        
        font-size: 0.8rem;
    }
    .blogsSection{
        width: 100vw;
        height: fit-content;
        display: flex;
        flex-direction: column;
    background-color: #EDEDED;
        align-items: flex-start;
        padding: 84px 0;
        padding-top: 70px;
    
    }
    .blogsSection_left{
        order: 1;
    }
    .blogsSection_aside{
        display: flex;
        order: -1;
    }
}

@media screen and (max-width:395px){
    .blogsSection_left{
        margin-right: 0;
        gap:36px;
        width: 100%;
        text-align: start;
    }
    .blogsSection_left ul{
        justify-content: center;
        display: flex;
        flex-wrap: wrap;
        width:100%;
        gap:63px;
    
    }.blog_heads1{
        margin-left: 70px;
    }
    .blogsSection_left ul li{
        height:482px;
        width: 70%;
        } 
        .blogsSection_left ul li img{
            
            height: 40%;
            
        }
           .blogsSection_left ul li div p {
            font-size: 1rem;
                }
                .blogsSection_left ul li div h2 {
            font-size: 1rem;
                }
    .blogsSection_aside{
        display: flex;
        flex-direction: column;
        gap:14px;
        width: 100%;
        padding-bottom: 43px;
        margin-left: 42px;
    
    }
    .blogsSection_aside ul{
        display: grid;
        grid-template-columns: 180px 180px;
        grid-template-rows: 60px 60px;
        height: 120px;
        width: 90%;
    }.blogsSection_aside ul li p:nth-child(1){
        background-color: #071B82;
        padding: 4px 8px;
        font-size: 0.6rem;
        color: white;
    }
    .blogsSection_aside ul li p:nth-child(2){
        
        font-size: 0.7rem;
    }
    .blogsSection_aside ul li{
        gap:20px;
    } 
}
@media screen and (max-width:380px){
    .blogsSection_left ul li div p {
        font-size: 0.8rem;
            }
            .blogsSection_left ul li div h2 {
                font-size: 0.9rem;
                    }
}

@media screen and (max-width:350px){
    .blogsSection_aside ul{
        display: grid;
        grid-template-columns: 142px 142px;
        grid-template-rows: 60px 60px;
        height: 120px;
        width: 90%;
    }.blogsSection_aside ul li p:nth-child(1){
        background-color: #071B82;
        padding: 4px 8px;
        font-size: 0.5rem;
        color: white;
    }
    .blogsSection_aside ul li p:nth-child(2){
        
        font-size: 0.6rem;
    }
}