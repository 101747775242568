@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&family=Darker+Grotesque:wght@300..900&display=swap');

@media screen and (max-width:2225px){
    .blog-postContent{
      height:fit-content;
      padding: 42px 62px;
      width: 100vw;
      display: flex;
      justify-content: space-between;
       background-color:#EDEDED;
      
    }
   
    
    .blog-title {
      margin-top: 0;
    }
    
    .blog-content {
      line-height: 2.9;
      font-family: "Anton", sans-serif;
  font-weight: lighter;
    }
    .blog-post { font-family: "Anton", sans-serif;
  font-weight: 400;
  font-style: normal;

      max-width: 70%;
      background-color:white;
      padding: 40px;
      font-family: Arial, sans-serif;
      border-radius: 8px;
      align-self: flex-start;
      text-align: start; margin-top: 40px;
    }
    .shadow-lg .intoBlogCont{
      margin: 43px 0 20px 0;
      font-size: 2.6rem;
      font-family: "Anton", sans-serif;
  font-weight: lighter;
    }
    .shadow-lg h1{
      font-size: 2.8rem;font-family: "Anton", sans-serif;
  font-weight: lighter;
    }
    .blogContentAside{
      width: 25%;
      height: fit-content;
      padding: 28px 8px;
      background-color:white;
      display: flex;
      flex-direction: column;
      gap:8px;
      border-radius: 8px;
  }
  .blogContentAside ul{
      align-self: center;
  width: 80%;
  color: black;
    }
    .blogContentAside h3{
      color: black;
      font-size: 1.6rem;
      font-family: "Anton", sans-serif;
  font-weight: lighter;
    }
    .blogContentAside ul li {
        font-size: 1rem;
      list-style-type: decimal;
      text-align: start;
      width:100%;
      margin-top: 8px;
  
    }
    .blogCntentText h1 {
      font-size: 26px;
      margin-bottom: 20px;
      color: #333;
    }
    
   
    
    
    .introduction {
      margin-top: 20px;
    }
    
     .shadow-lg h2 {
      font-size: 30px;
      margin-bottom: 0px;
      color: #555;font-family: "Anton", sans-serif;
  font-weight: lighter;
    }
    .shadow-lg2 h2 {
        font-weight: lighter;
      }
    .blogCntentText p {
      line-height: 1.9;
      color: #646262;
      font-size: 1.2rem;
      
    }
    .rowBlog{
      display: flex;
      flex-direction: column;
      gap: 40px;
    }
    .rowBlogHeadquestion{
      margin-top: 22px;
    }
    .shadow-lg ul {
      width:95%;
      
    }
    .shadow-lg p{
        font-size: 1.2rem;
    }
    .shadow-lg ul li{
      margin-left: 42px;
      list-style-type:disc;
      
      
    }
      .blogCntentText{
          display: flex;
          flex-direction: column;
          gap:14px;
      }
    .blogCntentText p,  .shadow-lg ul li{
      line-height: 1.9rem;
      margin-top: 8px;
    }
    .blogCntentText p {
  width: 80%;
    }
    .shadow-lg p:nth-child(1){
      margin-top: 28px;
    }
    .blogContentSocialsList{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 8px;
  width:100%;
    }
    .blogContentSocials {
      margin-top: 14px;
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
  }
  
  .blogContentSocials  img{
      width:134px;
      height: 71px;
  }
  
  .blogContentSocials a:nth-last-child(2) img{
      width:62px;
      height: 36px;
  }
  .blogContentSocials img:nth-child(2){
      height: 24px;
      width: 34px;
  }
  }




@media screen and (max-width:1785px){

.blog-post {
  padding: 60px;
  
}
.blog-post { 
  margin-top: 60px;
    }
    
}
@media screen and (max-width:1665px){

    
}
@media screen and (max-width:1365px){

  .blog-postContent{
    height:fit-content;
    padding: 42px 48px;
    width: 100vw;
    display: flex;
    justify-content: space-between;
     background-color:#EDEDED;
  }
 
  
  .blog-title {
    margin-top: 0;
  }
  
  .blog-content {
    line-height: 1.6;
  }
  .blog-post {
    max-width: 70%;
    background-color:white;
    padding: 40px;
    font-family: Arial, sans-serif;
    border-radius: 8px;
    align-self: flex-start;
    text-align: start;
  }
  .shadow-lg .intoBlogCont{
    margin: 43px 0 10px 0;
    font-size: 1.4rem;
  }
  .shadow-lg h1{
    font-size: 1.8rem;
  }
  
  .blogContentAside{
    width: 25%;
    height: fit-content;
    padding: 28px 8px;
    background-color:white;
    display: flex;
    flex-direction: column;
    gap:8px;
    border-radius: 8px; 
}
.blogContentAside ul{
    align-self: center;
width: 80%;
color: black;
  }
  .blogContentAside h3{
    color: black;font-size: 0.9rem;
  }
  .blogContentAside ul li {
    font-size: 0.64rem;
    list-style-type: decimal;
    text-align: start;
    width:100%;
    margin-top: 8px;

  }
  .blogCntentText h1 {
    font-size: 26px;
    margin-bottom: 20px;
    color: #333;
  }
  
 
  
  
  .introduction {
    margin-top: 20px;
  }
  
   .shadow-lg h2 {
    font-size: 24px;
    margin-bottom: 0px;
    color: #555;
  }
  
  .blogCntentText p {
    line-height: 1.6;
    color: #646262;
    font-size: 0.7rem;
    
  }
  .rowBlog{
    display: flex;
    flex-direction: column;
    gap: 40px;
  }
  .rowBlogHeadquestion{
    margin-top: 22px;
  }
  .shadow-lg ul {
    width:95%
  }
  .shadow-lg ul li{
    margin-left: 42px;
    list-style-type:disc;
    font-size: 0.8rem;
  }
    .blogCntentText{
        display: flex;
        flex-direction: column;
        gap:14px;
        margin-top: -40px;
    }
  .blogCntentText p,  .shadow-lg ul li{
    line-height: 1.6rem;
    margin-top: 8px;
  }
  .blogCntentText p {
width: 95%;

  }
  .shadow-lg p:nth-child(1){
    margin-top: 28px;
  }
  .blogContentSocialsList{
display: flex;
flex-direction: column;
align-items: center;
margin-top: 8px;
  }
 
  /* .blogContIntorText li{
    font: 8.9rem;
  } */
  .blogContentSocials {
   
    margin-top: 14px;
    display: flex;
    justify-content: space-between;
    width: 90%;
    align-self: center;
    align-items: center;
}

.blogContentSocials  img{
    width:94px;
    height: 51px;
}

.blogContentSocials a:nth-last-child(2) img{
    width:42px;
    height: 23px;
}
.blogContentSocials img:nth-child(2){
    height: 14px;
    width: 29px;
}
}

@media screen and (max-width:1125px){
    .blog-post {
        max-width: 65%;
    }
    .shadow-lg .intoBlogCont{
        margin: 28px 0 10px 0;
        font-size: 1.4rem;
      }
     .shadow-lg h1{
        font-size: 1rem;
      }
     .blogContentAside{
        width: 30%;
}.shadow-lg h2 {
    font-size: 18px;
}
    .shadow-lg p{
        font-size: 0.9rem;
    }
    .shadow-lg ul li{
        font-size: 0.7rem;
    }.blogContentSocials  img{
        width:70px;
        height: 40px;
    }
    
    .blogContentSocials a:nth-last-child(2) img{
        width:42px;
        height: 23px;
    }
    .blogContentSocials img:nth-child(2){
        height: 12px;
        width: 23px;
    }
}

@media screen and (max-width:482px){
    .blog-postContent{
        height:fit-content;
        padding: 42px 22px;
        display: flex;
        flex-direction: column;
        gap:42px;
        padding: 20px;
        padding-bottom: 60px;
      }
    .blog-post {
        max-width: 100%;
        order: 2;
    }
    .shadow-lg .intoBlogCont{
        margin: 28px 0 10px 0;
        font-size: 1.2rem;
      }
     .shadow-lg h1{
        font-size: 1rem;
      }
     .blogContentAside{
        width: 100%;
        order: 1;
}.shadow-lg h2 {
    font-size: 18px;
}
    .shadow-lg p{
        font-size: 0.8rem;
    }
    .shadow-lg ul li{
        font-size: 0.7rem;
    }
    .blogContentSocials {
   
        width: 75%;
    }
    .blogContentSocialsList{
        margin-top: 24px;
    }
    .blogContentSocials  img{
        width:90px;
        height: 53px;
    }
    
    .blogContentSocials a:nth-last-child(2) img{
        width:42px;
        height: 23px;
    }
    .blogContentSocials img:nth-child(2){
        height: 12px;
        width: 23px;
    }
}

