.container input{
margin-left: 7px;
height: 14px;
width: 70px;


}
.container {
    font-size: 0.7rem;
}
.searchby{
    font-size: 0.6rem;
    width: 70px;
    height: 20px;
    margin-left: 7px;
}
.container form{
    display: grid;
    grid-template-columns: 140px 140px 140px;
    grid-template-rows: 100px ;
    width:180px;
    gap:10px;
    border: none;
    display: flex;
    align-items: center;
}
.container form div{
    width:160px;
    height: 46px;
    display: flex;
    align-items: center;
}
.container button{
    border: 1px solid rgba(216, 215, 215, 0.886);
    font-size: 0.7rem;
    padding: 2px 3px;
border-radius: 4px;
width:70px;
height: 20px;
}

.container button:hover{
cursor: pointer;
}