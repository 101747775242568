.home{
    margin:0;
  padding:0;
  overflow-x: hidden;
  
}
/* .body {
  font-family: Arial, sans-serif;
  background-color: #f0f0f0;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.container {
  text-align: center;
}
.construction-image {
  width: 200px;
  height: auto;
}
.h1 {
  color: #333;
  margin-top: 20px;
}
.p {
  color: #666;
  margin-bottom: 20px;
} */