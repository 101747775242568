@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abel&family=Lora:ital,wght@0,400..700;1,400..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&family=Roboto+Slab:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap');

@media screen and (max-width:2225px){

  
.userdash{
  height: 100vh;
  width: 100vw;
  background:#EDEDED;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap:30px;
   overflow-y: hidden;
  }
  .navuser {
    z-index: 80;
    align-self: flex-start;
    
  }
  
  .navuser .homenav{
  
    height:120px;
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .navuser .homenav .nav-links div h2 a, .navuser .homenav .nav-links div h2 span a{
    font-size: 1.2rem;
  }
  .navuser .homenav .nav-links div h2 span a{
    color: rgba(139, 69, 19, 0.509);
  }
  .glasseffect{
      position: absolute;
      width: 93%;
      height: 100%;
      background-color: rgba(255,255,255,.1);
      
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(10px);
      display: flex;
  justify-content: center;
  align-items: center;
  
  }
  .glasseffect .userdash_main,.dash_side{
    border: 1px solid rgba(138, 137, 137, 0.553);
    border-radius: 18px;
   
  }
  .glasseffect .userdash_main{
    width: 100%;
    height: 80%;
    margin-top: 44px;
    display: flex;
    flex-direction: column;
    gap:20px;
    z-index: 2;
    background-color: white;
    box-shadow: 7px 7px 27px rgba(108, 107, 107, 0.251);
    margin-right: 18px;
  }
  .userdash_main_top{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 173px;
  }
  .userdash_main_top .userdash_main_top_welcome{
    width: 340px;
    height: 73%;
    margin-left: 35px;
    text-align: start;
    padding: 6px 13px;
  }
  .userdash_main_top_welcome p{
    font-size: 1.2rem;
    font-family: "Abel", sans-serif;
    font-weight: 700;
    font-style: normal;
    /* color: gray; */
  }
  .userdash_main_top_welcome h2{
    font-family: "Lora", serif;
    font-optical-sizing: auto;
    font-weight: 700px;
    font-size: 2.4rem;
  }
  .userdash_bottom{
    display: flex;
    flex-direction: column;
    gap:16px;
    height: 680px;
  }
  .userdash_bottom h3{
  align-self: flex-start;
  margin-left: 40px;
  }
  .userdash_bottom_contents{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap:30px; /* Adjust the maximum height as needed */
  height: 100%;
    overflow-y: auto;
  
  }
  .message_actionicons{
    display: flex;
    gap:20px;
  }
  
  .message_actionicons a,.message_actionicons button{
    width:30px;
    height: 25px;
  }
  
  .userdash_bottom_contents li{
    width: 92%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 17px;
    background-color: rgba(215, 213, 213, 0.212);
    border-radius: 8px;
  }
  .userdash_message p{
    font-family: "Roboto Slab", serif;
     
      font-size: 1.6rem;
      color: rgb(114, 114, 114);
  }
  .userdash_message{
    width: 60%;
    text-align: start;
    display: flex;
    flex-direction: column;
    gap:7px;
    margin-left: -47px;
    padding: 4px 12px;
    color: black;
  
  }
  .userdash_message h4{
    font-size: 1.6rem;
    font-family: "Oswald", sans-serif;
  }
  .userdash_bottom_contents li img{
    width:76px;
    height: 74px;
  }
  .dash_side{
    height: 80%;
    margin-top: 44px;
    width:38%;
   
    background-color: white;
    display: flex;
    align-items: center;
     background: linear-gradient(325deg,  rgba(240, 128, 128, 0.13),white 30%); 
  }
  .dash_side_cont{
    width: 96%;
    height: 93%;
  display: flex;
  flex-direction: column;
  gap:20px;
  
  }
  .dash_side_profile h4{
    margin-top:-8px;
  }
  .dash_side_cont img{
   width:77px;
   height: 82px;
   border-radius: 50%;
  }
  .dash_side_profile{
    display: flex;
    flex-direction: column;
    gap:0px;
    align-items: center;
    font-family: "PT Serif", serif;
  height: 200px;
  }
  .dash_side_profile_info p span{
    
    font-size: 1rem;
    font-weight: bold;
    font-family: "PT Serif", serif;
  }
  .dash_side_profile_info p{
    display: flex;
    flex-direction: column;
    gap:4px;
    font-size: 1.2rem;
    font-weight: lighter;
    margin-top: 16px;
  }
  .dash_side_profile_info{
    display: flex;
    gap:20px;
    width: 100%;
    font-size: 1.2rem;
    justify-content: center;
  }
  .dash_side_bottom{
   height: 74%;
   display: flex;
   flex-direction: column;
   gap:23px;
   align-items: flex-end;
   padding: 22px 22px;
   
  }
  .dash_side_bottom li p{
    font-size: 1.1rem;
  }
  .dash_side_bottom li{

    background-color: rgba(174, 174, 176, 0.215);
    width:92%;
    height: 70px;
    padding: 10px 12px;
    list-style-type: none;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
   
    color:black
  }
  .dash_side_bottom li a{
    display: flex;
    gap:48px;
    align-items: center;
  }
  .samp{
    width: 84%;
    margin-left: 43px;
    box-shadow: -12px 10px 12px rgba(185, 176, 176, 0.347);
   border: none;
   border-radius: 10px;
  background-color: white;
   padding: 12px 22px;
   display: flex;
   gap:15px;
   align-items: center;
   font-size: 1.4rem;
   margin-top: -20px;
  }
  .samp img{
    width:82px;
    height: 118px;
  }
  .dash_side_bottom li a{
    color: black;
    font-size: 0.7rem;
    font-weight: bolder;
    font-family: "PT Serif", serif;
  }
  .dashuser-icons{
    width:32px;
    height: 26px;
  }
  .dash_side_bottom li:hover{
    background-color: rgb(186, 185, 185);
    color:white;
    transition: 0.4s;
    cursor: pointer;
  }
  
  
  .glass-card{
      pointer-events: none;
      -webkit-user-select: none;
      user-select: none;
  }
  
    /* ... (previous styles) */
  
  /*
  .cursor-circle {
      position: absolute;
     
      border: 2px solid red;
      border-radius: 50%;
      width: 65px;
      height: 60px;
      pointer-events: none;
      transform: translate(-120%, -90%) scale(0.8); 
      transition: width 0.2s, height 0.2s, transform 0.2s;
      mix-blend-mode: exclusion; 
      z-index: 222;
    }
    
     */
    
    
    
    .secret-text {
      position: relative;
      color: rgba(255, 192, 203, 0.205);
      font-size: 16px;
      margin-top: 20px;
      transition: color 0.3s;
      z-index: 5;
    }
    
    .usersmessagesee{
      width:240px;
  padding: 0 19px;
    word-break: break-all;
    }
    .isMesgAvtivatemessg{
      position: absolute;
      top:30%;
      left: 40%;
      background-color:rgb(255, 255, 255);
      width:539px;
      background: url('../../components/images/Newfolder/messageicon.png');
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      height:fit-content;
      padding:28px 42px;
      border: 1px solid grey;
      border-radius: 12px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap:76px;
      background-color: #e8e8e8;
      
    }
    .seemsgbtn1{
      display: flex;
      justify-content: center;
      align-items: center;
      }
      .seemsgbtn{
      background-color: green;
      padding: 8px 14px;
      cursor: pointer;
      border-radius: 6px;
      font-size: 0.8rem;
      color: white;
      width: -moz-fit-content;
width: fit-content;margin-right: -46px;
      height: fit-content;
    }
    .isMesgAvtivatemessg div, .isMesgAvtivatemessg1 div{
      display: flex;
      flex-direction: column;
      align-items: center;
      width:100%;
      gap:8px;
      

    } 
    .isMesgAvtivatemessg div hr,  .isMesgAvtivatemessg1 div hr{
     
      height: 1px;
      border:none;
      background-color:rgb(236, 236, 236); ;
      width:10%
    }
    .seemsgbtn:hover{
      background-color: rgb(0, 75, 0);
    }
    .isMesgAvtivatemessg div p, .isMesgAvtivatemessg1 div p{
      width: 85%;
      word-break: break-all;
      color: #111111;
      margin-top:24px;text-align: start;
      font-size: 1rem;
    }  
    .isMesgAvtivatemessg div h3,  .isMesgAvtivatemessg1 div h3{
      
      color: #03045E;;
      font-size:1.8rem ;
    }  
    .isMesgAvtivatemessg button, .isMesgAvtivatemessg1 button{
      width: 68px;
      align-self: flex-end;
      border-radius: 8px;
      border: none;
      cursor: pointer;
      height: 28px;
      font-size: 0.9rem;
      background-color: rgb(255, 56, 56);
      color: white;
    }.isMesgAvtivatemessg button:hover, .isMesgAvtivatemessg1 button:hover{
      background-color: rgb(255, 34, 34);
    }
    .message_actionicons p {
      
      cursor: pointer;
      font-size: 20px;
      margin-left: 4px;
      border: 1px solid rgb(56, 56, 56);
      border-radius: 8px;
      font-size: 0.7rem;
      padding: 4px 10px;
      width: 100%;
      word-break: break-all;
      color: rgb(56, 56, 56);
    }
    .message_actionicons p:hover{
      background-color: rgb(236, 235, 235);
      color: black;
    }
  
    
  
  .logout-cont{
    position: absolute;
    top:30%;
    left:33%;
    width: 385px;
    height: 140px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
    padding: 12px 25px 10px 0px ;
    box-shadow: -4px 4px 8px rgba(54, 54, 54, 0.644);
    background-color: #e5e2e4 ;
    border-radius: 8px;
    border: 1px solid rgb(182, 180, 180);
    color: rgb(255, 255, 255);
    z-index: 760;
    font-size: 0.78rem;
  }
  .yesyes{
    margin-right: -85px;
  }
  .logout-cont h2{
    border-bottom: 2px solid rgb(52, 52, 52);
    width:80%;
    padding-bottom: 5px;
    color: rgb(39, 39, 39);
    
    align-self: center;
  }
  .logout-cont div{
    display: flex;
    justify-content: space-between;
    width:120px;
    align-self: flex-end;
    font-size: 0.6rem;
  }
  .logout-cont div button{
  border: 1px solid rgb(108, 40, 40);
  padding: 2px 15px;
  width: -moz-fit-content;
width: fit-content;
  height: fit-content;
  font-size: 0.6rem;
  border-radius: 4px;
  }
  .logout-cont div a{
    background-color: rgb(152, 152, 152);
    color: white;
    border-radius: 4px;
    height: fit-content;
  padding: 2px 15px;
  
  }
  .logout-cont div button:hover{
    cursor: pointer;
  }
  .usermoduel{
    background-color: rgba(8, 8, 95, 0.874);
    color:white;
    display: flex;
    
    flex-direction: column;
    align-items: center;
    gap:7px;
    padding: 0 0;
    margin: 0 20px 0 0;
  
  }
  .usermoduel h3{
    background-color: rgba(8, 8, 86, 0.877);
    width: 100%;
    padding: 5px 12px;
    font-size: 0.7rem;
  }
  .usermoduel:hover{
    border-bottom: 1px solid rgb(148, 141, 241);
    border-left: 1px solid rgb(116, 149, 211);
    transition: 0.7s ease;
  }
  .usermoduel-icon{
  width:90px;
  height: 50px;
  }
  .accountname{
    font-size: 1.2rem;
  }
  
  
  .userdash_bot_headi{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap:12px;
  width: 100%;
  }
  .userdash_bot_outlines{
    display: grid;
    grid-template-columns: 380px 380px;
    grid-template-rows: 170px 170px;
    height: 100%;
    align-self: center;
    width: 83%;
  
  }
  .userdash_bot_outlines li{
    display:flex;
    gap:27px;
    width: 100%;
    
  }
  .userdash_bot_outlines li .outlinid{
    width:44px;
    height: 36px;
    background-color: rgb(84, 180, 140);
    margin-top: 17px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
  }
  .userdash_bot_headi p{
    font-size: 0.77rem;
  }
  .userdash_bot_outlines li div{
   display: flex;
   flex-direction: column;
   align-items: flex-start;
    margin-top: 20px;
    gap:16px;
    width: 100%;
  }
  .userdash_bot_outlines li div p{
    font-size: 0.8rem;
    width: 70%;
    color: rgb(101, 100, 100);
    text-align: start;
  }
  
  .userdash_bot_outlines li div h3{
    font-size: 0.9rem;
    padding: 0;
    margin-left: -10px;
    text-align: start;
    align-self: flex-start;
  }
  
  .blurr {
    position: absolute;
    top: 440px;
    left:40px;
    width:740px;
    height: 130px;
    -webkit-backdrop-filter:blur(4px);
    backdrop-filter: blur(4px);
    background-color: rgba(98, 98, 98, 0.078);
    z-index: 300;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    border-radius: 8px;
  }
  .blurr span{
  
    width: 90%;
    height: 80%;
    display: flex;
    align-items: center;
    border: none;
    justify-content: center;
    
  }
  
  .message_actionicons a, .message_actionicons button{
    color: white;
    background-color: blue;
    border-radius: 8px;
    width:90px;
    
  }
  
  .message_actionicons button{
    background-color: rgb(213, 17, 17);
    
    
  }
  
  .popupfor_redirct {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, #ff7e5f, #feb47b);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }
  
  .popup-redirect-content {
    text-align: center;
    padding: 20px;
    background: white;
    border-radius: 8px;
    max-width: 300px; /* Adjusted for smaller screens */
    width: 90%;
  }
  
  .popupfor_redirct p {
    margin-bottom: 20px;
  }
  
  .popup-redirect-content a {
    background-color: #4CAF50;
    border: none;
    color: white;
    width: -moz-fit-content;
width: fit-content;
    padding: 7px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 0.8rem;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .popup-redirect-content a:hover {
    background-color: #45a049;
  }

}



@media screen and (max-width:1760px){
  .userdash_bottom{
    display: flex;
    flex-direction: column;
    gap:16px;
    height: 480px;
  }
.dash_side_bottom li p{
    font-size: 0.9rem;
  }
  .dash_side_bottom li{
  height: 63px;
  }
 .samp img{
    width:74px;
    height: 72px;
  }
}
@media screen and (max-width:1650px){
  .userdash_bottom{
    display: flex;
    flex-direction: column;
    gap:16px;
    height: 430px;
  }
  .samp{
    font-size: 1rem;
  }
  .dashuser-icons{
    width:22px;
    height: 24px;
  }

.dash_side_bottom li p{
    font-size: 0.8rem;
  }
  .dash_side_bottom li{
  height: 52px;
  }
 .samp img{
    width:66px;
    height: 60px;
  }
  .userdash_message p{
      font-size: 1.2rem;
  }
  .userdash_message h4{
    font-size: 1.2rem;
  }
}



@media screen and (max-width:1510px){
  .glasseffect .userdash_main{
    height: 77%;
  }

 .dash_side{
    height: 77%;
  }
  .userdash_bottom{
    display: flex;
    flex-direction: column;
    gap:16px;
    height: 348px;
  }
  .samp{
    font-size: 1rem;
  }
  .dashuser-icons{
    width:18px;
    height: 20px;
  }

.dash_side_bottom li p{
    font-size: 0.8rem;
  }
  .dash_side_bottom li{
  height: 41px;
  }
 .samp img{
    width:66px;
    height: 60px;
  }
  .userdash_message p{
      font-size: 1rem;
  }
  .userdash_message h4{
    font-size: 1rem;
  }
  .userdash_bottom_contents li img{
    width:30px;
    height: 45px;
  }
}


@media screen and (max-width:1360px){
.userdash{
height: 100vh;
width: 100vw;
display: flex;
flex-direction: column;
align-items: center;
gap:30px;
 overflow-y: hidden;
}
.navuser {
  z-index: 80;
  align-self: flex-start;
  
}

.navuser .homenav{

  height: 62px;
  padding-top: 14px ;
  padding-bottom: 14px ;
}
.navuser .homenav .nav-links div h2 a, .navuser .homenav .nav-links div h2 span a{
  font-size: 0.84rem;
}
.navuser .homenav .nav-links div h2 span a{
  color: rgba(139, 69, 19, 0.509);
}
.glasseffect{
    position: absolute;
    width: 93%;
    height: 100%;
    background-color: rgba(255,255,255,.1);
    
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    display: flex;
justify-content: center;
align-items: center;

}
.glasseffect .userdash_main,.dash_side{
  border: 1px solid rgba(138, 137, 137, 0.553);
  border-radius: 18px;
 
}
.glasseffect .userdash_main{
  width: 100%;
  height: 80%;
  margin-top: 44px;
  display: flex;
  flex-direction: column;
  gap:20px;
  z-index: 2;
  background-color: white;
  box-shadow: 7px 7px 27px rgba(108, 107, 107, 0.251);
  margin-right: 18px;
}
.userdash_main_top{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 120px;
}
.userdash_main_top .userdash_main_top_welcome{
  width: 340px;
  height: 73%;
  margin-left: 35px;
  text-align: start;
  padding: 6px 13px;
}
.userdash_main_top_welcome p{
  font-size: 0.8rem;
  font-family: "Abel", sans-serif;
  font-weight: 700;
  font-style: normal;

 
  /* color: gray; */
}
.userdash_main_top_welcome h2{
  font-family: "Lora", serif;
  font-optical-sizing: auto;
  font-weight: 700px;
}
.userdash_bottom{
  display: flex;
  flex-direction: column;
  gap:16px;
  height: 360px;
}
.userdash_bottom h3{
align-self: flex-start;
margin-left: 40px;
}
.dash_side_bottom li p{
  font-size: 0.7rem;
}
.userdash_bottom_contents{
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
gap:30px; /* Adjust the maximum height as needed */
height: 100%;
  overflow-y: auto;

}
.message_actionicons{
  display: flex;
  gap:20px;
}
.message_actionicons a,button{
  width:30px;
  height: 25px;
}

.userdash_bottom_contents li{
  width: 92%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 17px;
  background-color: rgba(215, 213, 213, 0.212);
  border-radius: 8px;
}
.userdash_message p{
  font-family: "Roboto Slab", serif;
   
    font-size: 0.83rem;
    color: rgb(114, 114, 114);
}
.userdash_message{
  width: 60%;
  text-align: start;
  display: flex;
  flex-direction: column;
  gap:7px;
  margin-left: -47px;
  padding: 4px 12px;
  color: black;

}
.userdash_message h4{
  font-size: 0.86rem;
  font-family: "Oswald", sans-serif;
}
.userdash_bottom_contents li img{
  width:40px;
  height: 55px;
}
.dash_side{
  height: 80%;
  margin-top: 44px;
  width:33%;
 
  background-color: white;
  display: flex;
  align-items: center;
   background: linear-gradient(325deg,  rgba(240, 128, 128, 0.13),white 30%); 
}
.dash_side_cont{
  width: 96%;
  height: 93%;
display: flex;
flex-direction: column;
gap:20px;

}
.dash_side_profile h4{
  margin-top:-8px;
}
.dash_side_cont img{
 width:77px;
 height: 82px;
 border-radius: 50%;
}
.dash_side_profile{
  display: flex;
  flex-direction: column;
  gap:0px;
  align-items: center;
  font-family: "PT Serif", serif;
height: 200px;
}
.dash_side_profile_info p span{
  
  font-size: 0.6rem;
  font-weight: bold;
  font-family: "PT Serif", serif;
}
.dash_side_profile_info p{
  display: flex;
  flex-direction: column;
  gap:4px;
  font-size: 0.7rem;
  font-weight: lighter;
  margin-top: 16px;
}
.dash_side_profile_info{
  display: flex;
  gap:20px;
  width: 100%;
  font-size: 0.7rem;
  justify-content: center;
}
.dash_side_bottom{
 height: 74%;
 display: flex;
 flex-direction: column;
 gap:12px;
 align-items: flex-end;
 padding: 22px 22px;
 
}
.dash_side_bottom li{
  background-color: rgba(174, 174, 176, 0.215);
  width:92%;
  height: 43px;
  padding: 10px 12px;
  list-style-type: none;
  border-radius: 6px;
 
  color:black
}
.dash_side_bottom li a{
  display: flex;
  gap:28px;
  align-items: center;
}
.samp{
  width: 77%;
  margin-left: 43px;
  box-shadow: -12px 10px 12px rgba(185, 176, 176, 0.347);
 border: none;
 border-radius: 10px;
background-color: white;
 padding: 12px 22px;
 display: flex;
 gap:15px;
 align-items: center;
 font-size: 0.67rem;
 margin-top: -20px;
}
.samp img{
  width:53px;
  height: 52px;
}
.dash_side_bottom li a{
  color: black;
  font-size: 0.7rem;
  font-weight: bolder;
  font-family: "PT Serif", serif;
}
.dashuser-icons{
  width:14px;
  height: 12px;
}
.dash_side_bottom li:hover{
  background-color: rgb(186, 185, 185);
  color:white;
  transition: 0.4s;
  cursor: pointer;
}


.glass-card{
    pointer-events: none;
    -webkit-user-select: none;
    user-select: none;
}

  /* ... (previous styles) */

/*
.cursor-circle {
    position: absolute;
   
    border: 2px solid red;
    border-radius: 50%;
    width: 65px;
    height: 60px;
    pointer-events: none;
    transform: translate(-120%, -90%) scale(0.8); 
    transition: width 0.2s, height 0.2s, transform 0.2s;
    mix-blend-mode: exclusion; 
    z-index: 222;
  }
  
   */
  
  
  
  .secret-text {
    position: relative;
    color: rgba(255, 192, 203, 0.205);
    font-size: 16px;
    margin-top: 20px;
    transition: color 0.3s;
    z-index: 5;
  }
  
  
  .message_actionicons p {
    
    cursor: pointer;
    font-size: 20px;
    margin-left: 4px;
    border: 1px solid rgb(199, 198, 198);
    border-radius: 8px;
    font-size: 0.7rem;
    padding: 4px 10px;
    background-color: rgb(236, 235, 235);
    color: black;
  }
  .message_actionicons p:hover{
    background-color: rgb(236, 235, 235);
    color: black;
  }

  

.logout-cont{
  position: absolute;
  top:30%;
  left:33%;
  width: 385px;
  height: 140px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  padding: 12px 25px 10px 0px ;
  box-shadow: -4px 4px 8px rgba(54, 54, 54, 0.644);
  
  border-radius: 8px;
  border: 1px solid rgb(182, 180, 180);
  color: rgb(255, 255, 255);
  z-index: 760;
  font-size: 0.78rem;
}
.yesyes{
  margin-right: -85px;
}
.logout-cont h2{
  border-bottom: 2px solid rgb(52, 52, 52);
  width:80%;
  padding-bottom: 5px;
  color: rgb(39, 39, 39);
  
  align-self: center;
}
.logout-cont div{
  display: flex;
  justify-content: space-between;
  width:120px;
  align-self: flex-end;
  font-size: 0.6rem;
}
.logout-cont div button{
border: 1px solid rgb(108, 40, 40);
padding: 2px 15px;
width: -moz-fit-content;
width:fit-content;
font-size: 0.6rem;
border-radius: 4px;
}
.logout-cont div a{
  background-color: rgb(152, 152, 152);
  color: white;
  border-radius: 4px;
padding: 2px 15px;

}
.logout-cont div button:hover{
  cursor: pointer;
}
.usermoduel{
  background-color: rgba(8, 8, 95, 0.874);
  color:white;
  display: flex;
  
  flex-direction: column;
  align-items: center;
  gap:7px;
  padding: 0 0;
  margin: 0 20px 0 0;

}
.usermoduel h3{
  background-color: rgba(8, 8, 86, 0.877);
  width: 100%;
  padding: 5px 12px;
  font-size: 0.7rem;
}
.usermoduel:hover{
  border-bottom: 1px solid rgb(148, 141, 241);
  border-left: 1px solid rgb(116, 149, 211);
  transition: 0.7s ease;
}
.usermoduel-icon{
width:90px;
height: 50px;
}
.accountname{
  font-size: 1.2rem;
}


.userdash_bot_headi{
display: flex;
flex-direction: column;
align-items: center;
gap:12px;
width: 100%;
}
.userdash_bot_outlines{
  display: grid;
  grid-template-columns: 380px 380px;
  grid-template-rows: 170px 170px;
  height: 100%;
  align-self: center;
  width: 83%;

}
.userdash_bot_outlines li{
  display:flex;
  gap:27px;
  width: 100%;
  
}
.userdash_bot_outlines li .outlinid{
  width:44px;
  height: 36px;
  background-color: rgb(84, 180, 140);
  margin-top: 17px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
.userdash_bot_headi p{
  font-size: 0.77rem;
}
.userdash_bot_outlines li div{
 display: flex;
 flex-direction: column;
 align-items: flex-start;
  margin-top: 20px;
  gap:16px;
  width: 100%;
}
.userdash_bot_outlines li div p{
  font-size: 0.8rem;
  width: 70%;
  color: rgb(101, 100, 100);
  text-align: start;
}

.userdash_bot_outlines li div h3{
  font-size: 0.9rem;
  padding: 0;
  margin-left: -10px;
  text-align: start;
  align-self: flex-start;
}

.blurr {
  position: absolute;
  top: 440px;
  left:40px;
  width:740px;
  height: 130px;
  -webkit-backdrop-filter:blur(4px);
  backdrop-filter: blur(4px);
  background-color: rgba(98, 98, 98, 0.078);
  z-index: 300;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  border-radius: 8px;
}
.blurr span{

  width: 90%;
  height: 80%;
  display: flex;
  align-items: center;
  border: none;
  justify-content: center;
  
}

.message_actionicons a, .message_actionicons button{
  color: white;
  background-color: blue;
  border-radius: 8px;
  width:90px;
  
}

.message_actionicons button{
  background-color: rgb(213, 17, 17);
  
  
}

.popupfor_redirct {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #ff7e5f, #feb47b);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.popup-redirect-content {
  text-align: center;
  padding: 20px;
  background: white;
  border-radius: 8px;
  max-width: 300px; /* Adjusted for smaller screens */
  width: 90%;
}

.popupfor_redirct p {
  margin-bottom: 20px;
}

.popup-redirect-content a {
  background-color: #4CAF50;
  border: none;
  color: white;
  width: -moz-fit-content;
width: fit-content;
  padding: 7px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 0.8rem;
  border-radius: 5px;
  cursor: pointer;
}

.popup-redirect-content a:hover {
  background-color: #45a049;
}.isMesgAvtivatemessg{
  position: absolute;
  top:20%;
  left: 25%;
  background-color:rgb(255, 255, 255);
  width:539px;
  background: url('../../components/images/Newfolder/messageicon.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height:fit-content;
  padding:28px 42px;
  border: 1px solid grey;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap:76px;
  background-color: #e8e8e8;
  
}

.isMesgAvtivatemessg div p{
  
  font-size: 1rem;
}  .seemsgbtn{
  background-color: green;
  padding: 4px 8px;
  cursor: pointer;
  border-radius: 6px;
  font-size: 0.7rem;
}

}




@media screen and (max-width:1026px){
  .dash_side_profile_info p span{
    font-size: 0.4rem;
  }
  .dash_side_bottom li a{
    font-size: 0.56rem;
    }
    
}
@media screen and (max-width:835px){
  .glasseffect{
    position: absolute;
    width: 98%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap:20px;
    padding: 80px 0;
    height: fit-content;

}
.dash_side{
  order: -1;
  width: 94%;
  height: 57.3%;
  padding: 37px 0;

}
 
  .glasseffect .userdash_main{
    width: 94%;
    height: 63%;
    margin-top: 44px;
    display: flex;
    flex-direction: column;
    gap:20px;
    z-index: 2;
    background-color: white;
    box-shadow: 7px 7px 27px rgba(108, 107, 107, 0.251);
    margin-right: 18px;
  }
  .userdash_bottom{
    display: flex;
    flex-direction: column;
    gap:16px;
    height: 492px;
  }
  
  .blurr {
    position: absolute;
    top: 76rem;
    left:40px;
    width:740px;
    height: 170px;
    -webkit-backdrop-filter:blur(4px);
    backdrop-filter: blur(4px);
    background-color: rgba(98, 98, 98, 0.078);
    z-index: 300;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    border-radius: 8px;
  }
  
  .userdash_bot_outlines li div p{
    font-size: 0.8rem;
    width: 60%;
    color: rgb(101, 100, 100);
    text-align: start;
  }
  
  .userdash_bot_outlines li div h3{
    font-size: 0.9rem;
    padding: 0;
    margin-left: -10px;
    text-align: start;
    align-self: flex-start;
    width:60%
  }
}


@media screen and (max-width:769px){
  .userdash_bottom{
   
    height: 392px;
  }
  .blurr{
    top:73rem
  }


  
  
}
@media screen and (max-width:439px){

  .glasseffect{
    position: absolute;
    width: 93%;
    height: 100%;
    background-color: rgba(255,255,255,.1);
    padding: 40px 0 30px 0;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    display: flex;
    flex-direction: column;
gap:10px;
align-items: center;

}
.dash_side{
  order: -1;
  width: 98%;
  height: fit-content;
  
 
  /* background-color: white;
  display: flex;
  align-items: center; */
}

  
  .userdash_bot_outlines{
    display: grid;
    grid-template-columns: 380px ;
    grid-template-rows: 170px 170px;
    height: 100%;
    align-self: center;
    width: 83%;
  
  }

  .popup-redirect-content {
    max-width: 250px; /* Adjusted for even smaller screens */
  }
  
.dash_side_cont{
  
gap:2px;

} 
.dash_side_profile h4{
  font-size: 0.8rem;
}
.dash_side_cont img{
 width:47px;
 height: 57px;
}

.dash_side_profile_info p span{
  
  font-size: 0.48rem;
}
.dash_side_profile_info p{

  font-size: 0.6rem;
}
.dash_side_profile_info{
  gap:60px;
  
}

.dash_side_bottom{
 height: 27%;
 display: grid;
grid-template-columns: 133px 133px;
grid-template-rows: 70px 70px;
 gap:12px;
 padding: 20px 0px;
 padding-left: 6px;
 margin: -43px 0 22px 0;
 
}
.dash_side_bottom li{
  background-color: rgba(174, 174, 176, 0.215);
  width:42%;
  height: 34px;
  padding: 10px 12px;
  list-style-type: none;
  border-radius: 6px;
 
  color:black
}
.dash_side_bottom li a{
  display: flex;
  gap:28px;
  align-items: center;
  font-size: 0.4rem;
}
.samp{
  display: none;
}



.glasseffect .userdash_main{
  width: 97%;
  height:fit-content;
  margin-top: 24px;
  margin-left: 14px;
  display: flex;
  flex-direction: column;
  gap:0px;
  z-index: 2;
  background-color: white;
  box-shadow: 7px 7px 27px rgba(108, 107, 107, 0.251);
  margin-right: 18px;
  overflow-y: hidden;
  padding-bottom: 220px;
}

.glasseffect .userdash_main_notif{
  width: 97%;
  height:40rem;
  margin-top: 24px;
  margin-left: 14px;
  display: flex;
  flex-direction: column;
  gap:0px;
  z-index: 2;
  background-color: white;
  box-shadow: 7px 7px 27px rgba(108, 107, 107, 0.251);
  margin-right: 18px;
  overflow-y: auto;
}
.userdash_main_top{
  display: flex;
  align-items: center;
  width: 100%;
  height: 120px;
}
.userdash_main_top .userdash_main_top_welcome{
  width: 340px;
  height: 73%;
  margin-left: 35px;
  text-align: start;
  padding: 6px 13px;
}
.userdash_main_top_welcome p{
  font-size: 0.8rem;
  font-family: "Abel", sans-serif;
  font-weight: 700;
  font-style: normal;

 
  /* color: gray; */
}
.blurr{
  width:310px;
  left:40px;
  top:69rem;
  height: 249px;

}
.userdash_main_top_welcome h2{
  font-family: "Lora", serif;
  font-optical-sizing: auto;
  font-weight: 700px;
  
}
.userdash_bottom{
  display: flex;
  flex-direction: column;
  gap:16px;
  height: 360px;
}
.userdash_bottom h3{
align-self: flex-start;
margin-left: 40px;
}
.userdash_bottom_contents{
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
gap:30px; /* Adjust the maximum height as needed */
height: 100%;
  overflow-y: auto;

}
.message_actionicons{
  display: flex;
  gap:20px;
}
.message_actionicons a,.message_actionicons button{
  width:30px;
  height: 25px;
}

.userdash_bottom_contents li{
  width: 88%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 17px;
  background-color: rgba(215, 213, 213, 0.212);
  border-radius: 8px;
}
.userdash_message p{
  font-family: "Roboto Slab", serif;
   
    font-size: 0.63rem;
    color: rgb(114, 114, 114);
}
.userdash_message{
  width: 60%;
  text-align: start;
  display: flex;
  flex-direction: column;
  gap:7px;
  margin-left: 12px;
  padding: 4px 12px;
  color: black;

}
.userdash_message h4{
  font-size: 0.86rem;
  font-family: "Oswald", sans-serif;
}
.userdash_bottom_contents li img{
  width:28px;
  height: 35px;
}
.message_actionicons p{
  font-size: 0.54rem;
  padding: 6px 8.7px;
}

.glasseffect .userdash_main{

  height: 140rem;
  
}

.glasseffect{
  height: calc(100% + 610px);
  padding-bottom:80px;
}
.userdash_bottom{
  display: flex;
  flex-direction: column;
  gap:16px;
  height: 98%;
}
}




@media screen and (max-width:439px){
  .dash_side_bottom{
    height: 27%;
    display: grid;
   grid-template-columns: 133px 133px;
   grid-template-rows: 70px 70px;
    gap:12px;
    padding: 20px 0px;
    padding-left: 26px;
    /* margin: -43px 0 22px 0; */
    
  }
  .userdash_message p{
    font-family: "Roboto Slab", serif;
    
    font-size: 0.58rem;
    color: rgb(114, 114, 114);
  }
  .userdash_message{
    width: 54%;
   
    
  }
  .userdash_message h4{
    font-size: 0.8rem;
    font-family: "Oswald", sans-serif;
  }
  
  .message_actionicons p{
    font-size: 0.5rem;
    padding: 6px 8.7px;
  }
  .dash_side_bottom{
    
    margin: -62px 0 22px 0;
    
  }
  .dash_side_bottom li{
    width: 96%;
    
  }
  .dash_side{
    width: 98%;
    padding: 0 20px;
  }
  .dash_side_bottom li p{
    width:100%;
    font-size: 0.4rem;
  }


  .logout-cont{
    top:30%;
    left:14%;
    width: 305px;
    height: 140px;
    
  }
  
  .logout-cont h2{
    font-size: 1rem;
  }
  .logout-cont div{
    font-size: 0.5rem;
  }
  .message_actionicons a{
    width:54px;
    font-size: 0.6rem;
    padding-top: 5px;
  }
  .message_actionicons button{
    
    width:54px;
    font-size: 0.4rem;
    padding: 2px 6px;
  }
  .dash_noData{
    margin-top:42px;
  }
}

@media screen and (max-width:393px){
  .logout-cont{
    top:30%;
    left:10%;
    width: 270px;
    
  }
  .popup-redirect-content {
    max-width: 230px; /* Adjusted for even smaller screens */
  }
  .userdash_bot_headi p{
    width:280px;
  }
  .userdash_bottom2{
    padding-top: 70px;
  }
  .userdash_bottom{
    margin-top: 40px;
  }
}

@media screen and (max-width:380px){
  .dash_side_bottom{
    
    margin-left:-18px
    
  }
  .userdash_main_top_welcome h2{
    font-size: 2rem;
  }
}

@media screen and (max-width:332px){
  .dash_side_bottom{
    grid-template-columns: 120px 110px;
    
  }
  .dash_side_bottom li p{
    font-size: 0.37rem;
  } 
  .dash_side{
    width: 97%;
    padding: 0 20px;
  }
  .dash_side_bottom li{
    width: 90%;
    
  }
  .samp{
    margin-left: 27px;
  }
  .userdash_main{
    font-size: 0.6rem;
  }
  .userdash_bot_headi p{
    font-size: 0.6rem;
  }
  .userdash_bot_outlines li div{
   display: flex;
   flex-direction: column;
   align-items: flex-start;
    margin-top: 20px;
    gap:16px;
    width: 100%;
  }

  .popup-redirect-content {
    max-width: 200px; /* Adjusted for even smaller screens */
  }

  .userdash_bot_outlines li div p{
    font-size: 0.7rem;
    width: 49%;
    color: rgb(101, 100, 100);
    text-align: start;
  }
  .userdash_bot_headi p{
    width: 220px;
  }
  .userdash_bot_outlines li div h3{
    font-size: 0.7rem;
    padding: 0;
    margin-left: -10px;
    text-align: start;
    align-self: flex-start;
  }

  .logout-cont{
    top:30%;
    left:7%;
    width: 260px;
    
  }
  .message_actionicons{
    display: flex;
    flex-direction: column;
  }
  .message_actionicons a{
    width:47px;
    font-size: 0.6rem;
    padding-top: 5px;
  }
  .message_actionicons button{
    
    width:47px;
    font-size: 0.4rem;
    padding: 2px 6px;
  }
  
  

}