
@import url('https://fonts.googleapis.com/css2?family=Lora:ital@1&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Old+Standard+TT&display=swap');


@media screen and (max-width:2225px){

    
.road {
    background-color: #c3a6a0;
    height: 100vh;
    display:flex;
    flex-direction: column;
    gap:80px;
    justify-content: center;
    align-items: center;

}
.road h1{
    font-size: 3.4rem;
    font-family: 'Lora', serif;
}
.road .road-container{
    display:flex;
    justify-content: space-between;
    z-index: 1;
    height: -moz-fit-content;
    height: fit-content;
    width: 80vw;
    font-size: 26px;
}
.road-sections{

display: flex;
flex-direction: column;
gap: 45px;
text-align: start;
z-index: 1;
font-family: 'Old Standard TT', serif;
}
.road-sections li:nth-child(1), .road-sections li:nth-child(2){
width:421px;
}

.road-sections {
    list-style: none;
}
.road-sections li:nth-child(1)::before,.road-sections li:nth-child(2)::before{
    content: "\2022";
    width:1.6rem;
   color:red;
   display:inline-block;
margin-left: -2rem;
    
}

.road-sections .image-container li::before{
  
    display: none;
  
    
}

.road-sections li img{
    width:430px;
    height: 450px;
}
.wiggly-road{
    width: -moz-fit-content;
    width: -moz-fit-content;
width: fit-content;
    position: absolute;
    top:102rem;
    left:-60px;
    
opacity: 0.12;
}
.wiggly-road img{
    width:280px;
    height: 372px;
}

.image-container {
    position: relative;
    display: inline-block;
    list-style: none;
}

.image-container img {
    max-width: 100%;
    height: auto;
    transition: opacity 0.3s ease, transform 0.5s ease;
}

.image-container:hover img {
    opacity: 0.7;
    transform: scale(0.85);
}
.image-container li{
    list-style: none;
}
.image-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.7);
    color: #c9c7c7;
    padding: 10px;
    font-size: 1.3rem;
    opacity: 0;
    transition: opacity 0.3s ease;
    width:460px;
    height: 440px;
    border-radius: 10px;
    display: flex;
    align-items: flex-end;
    padding: 32px 30px;
    list-style: none;

}

.image-container:hover .image-text {
    opacity: 1;
}

.road-container ul:nth-child(3) img{
    margin-top: 45px;
}
}




    @media screen and (max-width:1519px){
.road {
    background-color: #c3a6a0;
    height: 100vh;
    display:flex;
    flex-direction: column;
    gap:80px;
    justify-content: center;
    align-items: center;

}
.road h1{
    font-size: 30px;
    font-family: 'Lora', serif;
}
.road .road-container{
    display:flex;
    justify-content: space-between;
    z-index: 1;
    height: -moz-fit-content;
    height: fit-content;
    width: 70vw;
    font-size: 16px;
}
.road-sections{

display: flex;
flex-direction: column;
gap: 25px;
text-align: start;
z-index: 1;
font-family: 'Old Standard TT', serif;
}
.road-sections li:nth-child(1), .road-sections li:nth-child(2){
width:221px;
}

.road-sections {
    list-style: none;
}
.road-sections li:nth-child(1)::before,.road-sections li:nth-child(2)::before{
    content: "\2022";
    width:1.6rem;
   color:red;
   display:inline-block;
margin-left: -2rem;
    
}

.road-sections .image-container li::before{
  
    display: none;
  
    
}

.road-sections li img{
    width:230px;
    height: 250px;
}
.wiggly-road{
    width: -moz-fit-content;
    width: -moz-fit-content;
width: fit-content;
    position: absolute;
    top:102rem;
    left:-60px;
    
opacity: 0.12;
}
.wiggly-road img{
    width:280px;
    height: 372px;
}

.image-container {
    position: relative;
    display: inline-block;
    list-style: none;
}

.image-container img {
    max-width: 100%;
    height: auto;
    transition: opacity 0.3s ease, transform 0.5s ease;
}

.image-container:hover img {
    opacity: 0.7;
    transform: scale(0.85);
}
.image-container li{
    list-style: none;
}
.image-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.7);
    color: #c9c7c7;
    padding: 10px;
    font-size: 0.8rem;
    opacity: 0;
    transition: opacity 0.3s ease;
    width:260px;
    height: 240px;
    border-radius: 10px;
    display: flex;
    align-items: flex-end;
    padding: 25px 15px;
    list-style: none;

}

.image-container:hover .image-text {
    opacity: 1;
}

.road-container ul:nth-child(3) img{
    margin-top: 45px;
}
    }


@media screen and (max-width:835px){
    .road .road-container{
        
        width: 85vw;
        font-size: 16px;
    }
    .road-sections li img{
        width:200px;
    }
   
    .wiggly-road{
       
        top:128rem;
        left:-60px;
        
    opacity: 0.12;
    }
    .wiggly-road img{
        width:280px;
        height: 372px;
    }
}

@media screen and (max-width:790px){
    

    .road .road-container{       
       gap:47px;
        width: 90vw;
        font-size: 0.8rem;
    }
   
}


@media screen and (max-width:482px){
    .road {
        background-color: #c3a6a0;
        height: 92rem;
    }
    .wiggly-road{
       
        top:144rem;
        left:-40px;
    }
    .wiggly-road img{
        width:220px;
        height: 302px;
    }

    .road .road-container{
        display:flex;
        flex-direction: column;
        justify-content: space-between;
       gap:65px;
        width: 70vw;
        font-size: 16px;
        margin-left: 35px;
    }
   
}