@media screen and (max-width:2225px){

    .Apply{
        width:70dw;
        height: 100vh;
        display: flex;
        align-items: center;
        overflow-y: hidden;
        overflow-x: hidden;
    }
    .form-content{
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: space-between;
    }
    .headAndsearch .none{
        display: none;
    }
    .headAndsearch .block{
        display: block;
    }
    .agree span{
        color:blue
    }
    .form-content form{
        display:flex;
        flex-direction: column;
    align-items: flex-start;
    align-content: flex-start;
    background-color: rgb(255, 255, 255);
    border:none;
    width:975px;
    gap:20px;
    z-index: 2;
    padding: 40px 0px 10px 25px; 
    height: 100%;
    }

    .optional-lable{
        color: rgb(149, 149, 149);
        font-size: 0.9rem;
        font-weight: lighter;
    }

    .form-and-side{
        display: flex;
        border-radius:12px;
        box-shadow: 0px 8px 13px rgb(86, 85, 85);
        height: 94%;
    }

    .form-sidenote{
        position: relative;
        width: 520px;
        height: 100%;
        display: flex;
        align-items: center;
        color: white;
       
    }
    

    .form-sidenote-wrap{
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.322);
        backdrop-filter: blur(2px);
        -webkit-backdrop-filter: blur(2px) ; 
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-bottom: 10px;
        padding-left: 10px;
       gap:40px
    }

    .form-sidenote-wrap-bottom {
        display: flex;
        flex-direction: column;
        gap:40px;
        align-self: center;
       width: 83%;
       margin-top: 20px;
       padding-bottom: 10px;
    }
    .form-sidenote-wrap-bottom div{
        display: flex;
        flex-direction: column;
       align-items: center;
       text-align: center;
       width: 83%;
       gap:7px;
       
    }
    .form-sidenote-wrap-bottom div p{
        font-size: 1.7rem;
    }
    
    
    .form-sidenote-wrap div h2{
    font-size: 1.9rem;
    text-align: start;
    margin-top: 90px;
    
    margin-left: 27px;
    }
    .form-sidenote-wrap div .form-p{
        font-size: 1.4rem;
        align-self: flex-start;
        width: 380px;
        margin-top: 10px;
    }
    .form-content form label{
        font-size: 1.9rem;
        width: 340px;
    }
    .applyform-top, .applyform-bottom{
        display: grid;
        grid-template-columns: 460px 460px;
        grid-template-rows: 170px ;
        width: 84%;
        padding: 0;
        margin: 0;
        
    }
    .applyform-bottom{
        display: flex;
        flex-direction: column;
        width: 80%;
        align-self: center;
        margin-top: 20px;
    }
    
    .applyform-top div, .applyform-bottom div{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap:7px
    }
    .applyform-bottom div p{
        align-self: flex-start;
        width: 100%;
    }
    .applyform-bottom  hr{
        width: 70px;
        align-self: center;
        margin-top: 30px;
        border: 0.04rem solid rgba(161, 109, 12, 0.961);
    }
    .agree{
        font-size:0.7rem;
    }
    .apply-contents{
        display: flex;
        flex-direction:column;
        align-items: flex-start;
        justify-content: space-between;
        width:90%;
        height: 94%;
        padding: 10px 0 0 30px;
        z-index: 5;
        font-size: 16px;
    }
    
    .form-content form div input{
        width: 362px;
        border-radius: 8px;
        background-color: #cfcdcd;
        border: none;
        outline: none;
        opacity: 0.7;
        padding: 10px 15px;
        font-size: 1.2rem;
    }
    .applyform-top div .fileattachment{
        background-color: white;
    }
    .form-img{
        z-index: 1;
    }
    .form-img img{
        width: 673px;
        height: 922px;
        margin: 425px 0 0 0 ;
    }
    .success-message2{
        color: white;
        position: absolute;
        top:35%;
        left:25%;
        width:570px;
        height: fit-content;
        background-color: rgb(157, 175, 157);
        border: 1px solid rgb(147, 146, 146);
        box-shadow: -7px 7px 12px rgba(63, 63, 63, 0.605);
        border-radius: 7px;
        padding: 14px 20px;
        z-index: 377;
        display: flex;
        flex-direction: column;
        gap:22px
    }
    
    
    .success-message2 div{
        display: flex;
        gap:40px
    }
    .success-message2 a{
        background-color: rgb(0, 0, 0);
        padding: 10px 17px;
        box-shadow: -8px 8px 10px rgba(50, 50, 50, 0.452);
        width:110px;
        font-size: 0.9rem;
        border-radius: 8px;
    }
    .apply-ellipse{
        position:absolute;
        bottom: 320px;
        width:730px;
        height: 180px;
        z-index:-2;
        bottom: 140px;
        right: 0px;
    }
    .apply-ellipse img{
        width:100%
    }
    form .btn{
        align-self:center;
        width:175px;
        height: 78px;
        padding:16px 0px;
        font-size:1.8rem;
       margin-top: 15px;
       border-radius: 8px;
       border: none;
       color:white;
       background-color: #03045E;;
    }
    .spinner-container {
        
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh; /* Set height to viewport height */
    }
    .apply-popupbtn{
        cursor: pointer;
        outline: none;
        border-radius: 8px;
    }
    .agree{
        font-size: 1.1rem;
    }
    .sppinerdiv{
        position: absolute;
        top:40%;
        left:40%;
        width: 170px;
        height: 140px;
        z-index: 77;
    border-radius: 13px;
    padding: 12px 24px;
    display: flex;
    flex-direction: column;
    gap:17px;
    align-items: center;
    animation: spinrectangl 1s infinite linear;
    }
    .sppinerdiv h2{
    color:white;
    font-size: 1rem;
    }
    .blurry{
        background-color: white;
    visibility: hidden;
    }
    .blurry .sppinerdiv{
        visibility: visible;
    }
    
    .spinner {

        font-size: 7rem;
        animation: spin 1s infinite linear;
    }
    
    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
        
    
}

@media screen and (max-width:2145px){

    .Apply{
        width:70dw;
        height: 100vh;
        display: flex;
        align-items: center;
        overflow-y: hidden;
        overflow-x: hidden;
    }
    .form-content{
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: space-between;
    }
    .headAndsearch .none{
        display: none;
    }
    .headAndsearch .block{
        display: block;
    }
    .agree span{
        color:blue
    }
    .form-content form{
        display:flex;
        flex-direction: column;
    align-items: flex-start;
    align-content: flex-start;
    background-color: white;
    border:none;
    width:855px;
    gap:0px;
    z-index: 2;
    padding: 40px 0px 10px 25px; 
    height: 100%;
    }

    .optional-lable{
        color: rgb(149, 149, 149);
        font-size: 0.8rem;
        font-weight: lighter;
    }

    .form-and-side{
        display: flex;
        border-radius:12px;
        box-shadow: 0px 8px 13px rgb(86, 85, 85);
        height: 94%;
    }

    .form-sidenote{
        position: relative;
        width: 520px;
        height: 100%;
        display: flex;
        align-items: center;
        color: white;
       
    }
    

    .form-sidenote-wrap{
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.322);
        backdrop-filter: blur(2px);
        -webkit-backdrop-filter: blur(2px) ; 
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-bottom: 10px;
        padding-left: 10px;
       gap:40px
    }

    .form-sidenote-wrap-bottom {
        display: flex;
        flex-direction: column;
        gap:40px;
        align-self: center;
       width: 83%;
       margin-top: 20px;
       padding-bottom: 10px;
    }
    .form-sidenote-wrap-bottom div{
        display: flex;
        flex-direction: column;
       align-items: center;
       text-align: center;
       width: 83%;
       gap:7px;
       
    }
    .form-sidenote-wrap-bottom div p{
        font-size: 1.3rem;
    }
    
    
    .form-sidenote-wrap div h2{
    font-size: 1.7rem;
    text-align: start;
    margin-top: 90px;
    
    margin-left: 27px;
    }
    .form-sidenote-wrap div .form-p{
        font-size: 1.4rem;
        align-self: flex-start;
        width: 380px;
        margin-top: 10px;
    }
    .form-content form label{
        font-size: 1.4rem;
        width: 270px;
    }
    .applyform-top, .applyform-bottom{
        display: grid;
        grid-template-columns: 390px 390px;
        grid-template-rows: 170px ;
        width: 84%;
        padding: 0;
        margin: 0;
        
    }
    .applyform-bottom{
        display: flex;
        flex-direction: column;
        width: 80%;
        align-self: center;
        margin-top: 20px;
    }
    
    .applyform-top div, .applyform-bottom div{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap:7px
    }
    .applyform-bottom div p{
        align-self: flex-start;
        width: 100%;
    }
    .applyform-bottom  hr{
        width: 70px;
        align-self: center;
        margin-top: 30px;
        border: 0.04rem solid rgba(161, 109, 12, 0.961);
    }
    .agree{
        font-size:0.7rem;
    }
    .apply-contents{
        display: flex;
        flex-direction:column;
        align-items: flex-start;
        justify-content: space-between;
        width:90%;
        height: 94%;
        padding: 10px 0 0 30px;
        z-index: 5;
        font-size: 16px;
    }
    
    .form-content form div input{
        width: 362px;
        border-radius: 8px;
        background-color: #cfcdcd;
        border: none;
        outline: none;
        opacity: 0.7;
        padding: 10px 15px;
        font-size: 1.2rem;
    }
    .applyform-top div .fileattachment{
        background-color: white;
    }
    .form-img{
        z-index: 1;
    }
    .form-img img{
        width: 583px;
        height: 822px;
        margin: 425px 0 0 0 ;
    }
    .success-message2{
        color: white;
        position: absolute;
        top:35%;
        left:25%;
        width:570px;
        height: fit-content;
        background-color: rgb(157, 175, 157);
        border: 1px solid rgb(147, 146, 146);
        box-shadow: -7px 7px 12px rgba(63, 63, 63, 0.605);
        border-radius: 7px;
        padding: 14px 20px;
        z-index: 377;
        display: flex;
        flex-direction: column;
        gap:22px
    }
    
    
    .success-message2 div{
        display: flex;
        gap:40px
    }
    .success-message2 a{
        background-color: rgb(0, 0, 0);
        padding: 10px 17px;
        box-shadow: -8px 8px 10px rgba(50, 50, 50, 0.452);
        width:110px;
        font-size: 0.9rem;
        border-radius: 8px;
    }
    .apply-ellipse{
        position:absolute;
        bottom: 320px;
        width:730px;
        height: 180px;
        z-index:-2;
        bottom: 140px;
        right: 0px;
    }
    .apply-ellipse img{
        width:100%
    }
    form .btn{
        align-self:center;
        width:135px;
        height: 48px;
        padding:8px 0px;
        font-size:1.2rem;
       margin-top: 15px;
       border-radius: 8px;
       border: none;
       color:white;
       background-color: #03045E;;
    }
    .spinner-container {
        
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh; /* Set height to viewport height */
    }
    .apply-popupbtn{
        cursor: pointer;
        outline: none;
        border-radius: 8px;
    }
    .agree{
        font-size: 1.1rem;
    }
    .sppinerdiv{
        position: absolute;
        top:40%;
        left:40%;
        width: 170px;
        height: 140px;
        z-index: 77;
    border-radius: 13px;
    padding: 12px 24px;
    display: flex;
    flex-direction: column;
    gap:17px;
    align-items: center;
    animation: spinrectangl 1s infinite linear;
    }
    .sppinerdiv h2{
    color:white;
    font-size: 1rem;
    }
    .blurry{
        background-color: white;
    visibility: hidden;
    }
    .blurry .sppinerdiv{
        visibility: visible;
    }
    
    .spinner {

        font-size: 7rem;
        animation: spin 1s infinite linear;
    }
    
    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
        
    
}


@media screen and (max-width:1885px){
    .form-content form{
        gap:0px;
    }
    .applyform-top, .applyform-bottom{
        
        grid-template-rows: 140px ;
    }
    .form-img img{
        width: 373px;
        height: 522px;
        margin: 225px 0 0 0 ;
    }
}
@media screen and (max-width:1765px){

    .applyform-top, .applyform-bottom{
        display: grid;
        grid-template-columns: 390px 390px;
        grid-template-rows: 120px ;
        width: 84%;
        padding: 0;
        margin: 0;
        
    }
    .applyform-bottom{
        display: flex;
        flex-direction: column;
        width: 80%;
        align-self: center;
        margin-top: 20px;
    }
    
    .applyform-top div, .applyform-bottom div{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap:7px
    }
    .applyform-bottom div p{
        align-self: flex-start;
        width: 100%;
    }
    .applyform-bottom  hr{
        width: 70px;
        align-self: center;
        margin-top: 30px;
        border: 0.04rem solid rgba(161, 109, 12, 0.961);
    }
    .agree{
        font-size:0.7rem;
    }
    form .btn{
        align-self:center;
        width:135px;
        height: 48px;
        padding:8px 0px;
        font-size:1.2rem;
       margin-top: 15px;
       border-radius: 8px;
       border: none;
       color:white;
       background-color: #03045E;;
    }
    .spinner-container {
        
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh; /* Set height to viewport height */
    }
    .apply-popupbtn{
        cursor: pointer;
        outline: none;
        border-radius: 8px;
    }
    .agree{
        font-size: 1.1rem;
    }
    .sppinerdiv{
        position: absolute;
        top:40%;
        left:40%;
        width: 170px;
        height: 140px;
        z-index: 77;
    border-radius: 13px;
    padding: 12px 24px;
    display: flex;
    flex-direction: column;
    gap:17px;
    align-items: center;
    animation: spinrectangl 1s infinite linear;
    }
    .sppinerdiv h2{
    color:white;
    font-size: 1rem;
    }
    .blurry{
        background-color: white;
    visibility: hidden;
    }
    .blurry .sppinerdiv{
        visibility: visible;
    }
    
    .spinner {

        font-size: 7rem;
        animation: spin 1s infinite linear;
    }
    
    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
        
    
}



@media screen and (max-width:1698px){
    .form-content form{
       gap:0;
    width:745px;
}
.form-content form div input{
    width: 200px;
    height: 30px;
    border-radius: 8px;
    background-color: #cfcdcd;
    border: none;
    outline: none;
    opacity: 0.7;
    padding: 0 15px;
    font-size: 0.8rem;
}
.applyform-top, .applyform-bottom{
    
    grid-template-columns: 320px 320px;
}
form .btn{
    align-self:center;
    width:95px;
    height: 36px;
    padding:4px 0px;
    font-size:0.9rem;
   margin-top: 15px;
   
}
}
@media screen and (max-width:1485px){

    .Apply{
        width:70dw;
        height: 100vh;
        display: flex;
        align-items: center;
        overflow-y: hidden;
        overflow-x: hidden;
    }
    .form-content{
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: space-between;
    }
    .headAndsearch .none{
        display: none;
    }
    .headAndsearch .block{
        display: block;
    }
    .agree span{
        color:blue
    }
    .form-content form{
        display:flex;
        flex-direction: column;
    align-items: flex-start;
    align-content: flex-start;
    background-color: white;
    border:none;
    width:745px;
    gap:0px;
    z-index: 2;
    padding: 40px 0px 10px 25px; 
    height: 100%;
    }

    .optional-lable{
        color: rgb(149, 149, 149);
        font-size: 0.8rem;
        font-weight: lighter;
    }

    .form-and-side{
        display: flex;
        border-radius:12px;
        box-shadow: 0px 8px 13px rgb(86, 85, 85);
        height: 94%;
    }

    .form-sidenote{
        position: relative;
        width: 420px;
        height: 100%;
        display: flex;
        align-items: center;
        color: white;
       
    }
    

    .form-sidenote-wrap{
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.322);
        backdrop-filter: blur(2px);
        -webkit-backdrop-filter: blur(2px) ; 
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-bottom: 10px;
        padding-left: 10px;
       gap:40px
    }

    .form-sidenote-wrap-bottom {
        display: flex;
        flex-direction: column;
        gap:40px;
        align-self: center;
       width: 83%;
       margin-top: 20px;
       padding-bottom: 10px;
    }
    .form-sidenote-wrap-bottom div{
        display: flex;
        flex-direction: column;
       align-items: center;
       text-align: center;
       width: 83%;
       gap:7px;
       
    }
    .form-sidenote-wrap-bottom div p{
        font-size: 1rem;
    }
    
    
    .form-sidenote-wrap div h2{
    font-size: 1.3rem;
    text-align: start;
    margin-top: 90px;
    
    margin-left: 27px;
    }
    .form-sidenote-wrap div .form-p{
        font-size: 1rem;
        align-self: flex-start;
        width: 320px;
        margin-top: 10px;
    }
    .form-content form label{
        font-size: 1.1rem;
        width: 270px;
    }
    .applyform-top, .applyform-bottom{
        display: grid;
        grid-template-columns: 330px 330px;
        grid-template-rows: 112px ;
        width: 84%;
        padding: 0;
        margin: 0;
        
    }
    .applyform-bottom{
        display: flex;
        flex-direction: column;
        width: 80%;
        align-self: center;
        margin-top: 20px;
    }
    
    .applyform-top div, .applyform-bottom div{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap:7px
    }
    .applyform-bottom div p{
        align-self: flex-start;
        width: 100%;
    }
    .applyform-bottom  hr{
        width: 70px;
        align-self: center;
        margin-top: 30px;
        border: 0.04rem solid rgba(161, 109, 12, 0.961);
    }
    .agree{
        font-size:0.7rem;
    }
    .apply-contents{
        display: flex;
        flex-direction:column;
        align-items: flex-start;
        justify-content: space-between;
        width:90%;
        height: 94%;
        padding: 10px 0 0 30px;
        z-index: 5;
        font-size: 16px;
    }
    
    .form-content form div input{
        width: 274px;
        border-radius: 8px;
        background-color: #cfcdcd;
        border: none;
        outline: none;
        opacity: 0.7;
        padding: 10px 15px;
        font-size: 1.2rem;
    }
    .applyform-top div .fileattachment{
        background-color: white;
    }
    .form-img{
        z-index: 1;
    }
    .form-img img{
        width: 383px;
        height: 622px;
        margin: 25px 0 0 0 ;
    }
    .success-message2{
        color: white;
        position: absolute;
        top:35%;
        left:25%;
        width:570px;
        height: fit-content;
        background-color: rgb(157, 175, 157);
        border: 1px solid rgb(147, 146, 146);
        box-shadow: -7px 7px 12px rgba(63, 63, 63, 0.605);
        border-radius: 7px;
        padding: 14px 20px;
        z-index: 377;
        display: flex;
        flex-direction: column;
        gap:22px
    }
    
    
    .success-message2 div{
        display: flex;
        gap:40px
    }
    .success-message2 a{
        background-color: rgb(0, 0, 0);
        padding: 10px 17px;
        box-shadow: -8px 8px 10px rgba(50, 50, 50, 0.452);
        width:110px;
        font-size: 0.9rem;
        border-radius: 8px;
    }
    .apply-ellipse{
        position:absolute;
        bottom: 320px;
        width:730px;
        height: 180px;
        z-index:-2;
        bottom: 140px;
        right: 0px;
    }
    .apply-ellipse img{
        width:100%
    }
    form .btn{
        align-self:center;
        width:120px;
        height: 36px;
        padding:8px 0px;
        font-size:1rem;
       margin-top: 15px;
       border-radius: 8px;
       border: none;
       color:white;
       background-color: #03045E;;
    }
    .spinner-container {
        
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh; /* Set height to viewport height */
    }
    .apply-popupbtn{
        cursor: pointer;
        outline: none;
        border-radius: 8px;
    }
    .agree{
        font-size: 0.9rem;
    }
    .sppinerdiv{
        position: absolute;
        top:40%;
        left:40%;
        width: 170px;
        height: 140px;
        z-index: 77;
    border-radius: 13px;
    padding: 12px 24px;
    display: flex;
    flex-direction: column;
    gap:17px;
    align-items: center;
    animation: spinrectangl 1s infinite linear;
    }
    .sppinerdiv h2{
    color:white;
    font-size: 1rem;
    }
    .blurry{
        background-color: white;
    visibility: hidden;
    }
    .blurry .sppinerdiv{
        visibility: visible;
    }
    
    .spinner {

        font-size: 7rem;
        animation: spin 1s infinite linear;
    }
    
    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
        
    
}





    @media screen and (max-width:1365px){
        
        .Apply{
    width:70dw;
    height: 100vh;
    
    display: flex;
    align-items: center;
}
.form-content{
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-between;
}
.headAndsearch .none{
    display: none;
}
.headAndsearch .block{
    display: block;
}
.agree span{
    color:blue
}
.form-content form{
    display:flex;
    flex-direction: column;
align-items: flex-start;
align-content: flex-start;
border:none;
background-color:white;
width:555px;
gap:10px;
padding: 40px 0px 10px 25px; 
height: 100%;
}
.optional-lable{
    color: rgb(149, 149, 149);
    font-size: 0.68rem;
    font-weight: lighter;
}
.form-and-side{
    display: flex;
    border-radius:12px;
    box-shadow: 0px 8px 13px rgb(86, 85, 85);
    height: 94%;
}
.form-sidenote{
    position: relative;
    width: 340px;
    height: 100%;
    display: flex;
    align-items: center;
    color: white;
   
}
.form-sidenote-wrap{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.322);
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px) ;
    
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
    padding-left: 10px;
   gap:40px
}

.form-sidenote-wrap-bottom {
    display: flex;
    flex-direction: column;
    gap:40px;
    align-self: center;
   width: 70%;
   margin-top: 20px;
   padding-bottom: 10px;
}
.form-sidenote-wrap-bottom div{
    display: flex;
    flex-direction: column;
   align-items: center;
   text-align: center;
   width: 70%;
   gap:7px;
   
}
.form-sidenote-wrap-bottom div p{
    font-size: 0.8rem;
}


.form-sidenote-wrap div h2{
font-size: 0.9rem;
text-align: start;
margin-top: 90px;

margin-left: 27px;
}
.form-sidenote-wrap div .form-p{
    font-size: 0.8rem;
    align-self: flex-start;
    width: 270px;
    margin-top: 10px;
}
.form-content form label{
    font-size: 0.67rem;
    width: 270px;
}
.applyform-top, .applyform-bottom{
    display: grid;
    grid-template-columns: 240px 240px;
    grid-template-rows: 70px ;
    width: 84%;
    padding: 0;
    margin: 0;
    
}
.applyform-bottom{
    display: flex;
    flex-direction: column;
    width: 80%;
    align-self: center;
    margin-top: 20px;
}

.applyform-top div, .applyform-bottom div{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:7px
}
.applyform-bottom div p{
    align-self: flex-start;
    width: 100%;
}
.applyform-bottom  hr{
    width: 70px;
    align-self: center;
    margin-top: 30px;
    border: 0.04rem solid rgba(161, 109, 12, 0.961);
}
.agree{
    font-size:0.7rem;
}
.apply-contents{
    display: flex;
    flex-direction:column;
    align-items: flex-start;
    justify-content: space-between;
    width:90%;
    height: 94%;
    padding: 10px 0 0 30px;
    z-index: 5;
    font-size: 16px;
}

.form-content form div input{
    width: 200px;
    height: 30px;
    border-radius: 8px;
    background-color: #cfcdcd;
    border: none;
    outline: none;
    opacity: 0.7;
    padding: 0 15px;
    font-size: 0.8rem;
}
.applyform-top div .fileattachment{
    background-color: white;
}
.form-img{
    z-index: 1;
}
.form-img img{
    width: 380px;
    height: 480px;
    margin: 25px 0 0 0 ;
}
.success-message2{
    color: white;
    position: absolute;
    top:23%;
    left:25%;
    width:470px;
    height: fit-content;
    background-color: rgb(157, 175, 157);
    border: 1px solid rgb(147, 146, 146);
    box-shadow: -7px 7px 12px rgba(63, 63, 63, 0.605);
    border-radius: 7px;
    padding: 14px 20px;
    z-index: 377;
    display: flex;
    flex-direction: column;
    gap:22px
}

.success-message2{
    font-size: 0.8rem;
}

.success-message2 div{
    display: flex;
    gap:30px
}
.success-message2 a{
    background-color: rgb(0, 0, 0);
    padding: 10px 17px;
    box-shadow: -8px 8px 10px rgba(50, 50, 50, 0.452);
    width:90px;
    font-size: 0.7rem;
    border-radius: 8px;
}
.apply-ellipse{
    position:absolute;
    bottom: 320px;
    width:430px;
    height: 180px;
    z-index:-2;
    bottom: 0px;
    right: 0px;
}
.apply-ellipse img{ 
    width: 100%;
}
form .btn{
    align-self:center;
    width:85px;
    height: 38px;
    padding:8px 0px;
    font-size:0.8rem;
   margin-top: 15px;
   border-radius: 8px;
   border: none;
   color:white;
   background-color: #03045E;;
}
.spinner-container {
    
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Set height to viewport height */
}
.apply-popupbtn{
    cursor: pointer;
    outline: none;
    border-radius: 8px;
}
.sppinerdiv{
    position: absolute;
    top:40%;
    left:40%;
    width: 170px;
    height: 140px;
    z-index: 77;
border-radius: 13px;
padding: 12px 24px;
display: flex;
flex-direction: column;
gap:17px;
align-items: center;
animation: spinrectangl 1s infinite linear;
}
.sppinerdiv h2{
color:white;
font-size: 1rem;
}
.blurry{
    background-color: white;
visibility: hidden;
}
.blurry .sppinerdiv{
    visibility: visible;
}
.spinner {
   
    font-size: 7rem;
    animation: spin 1s infinite linear;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
    }



@media screen and (max-width:835px){

    

        
.apply-contents{
    display: flex;
    flex-direction:column;
    align-items: flex-start;
    justify-content: space-between;
    width:100%;
    height: 94%;
    padding: 0px 0 0 60px;
    z-index: 8;
    
}
.form-content form lable{
    width:180px
}
.form-content form input{
    width: 200px;
    height: 25px;
    
}
.form-img{
    z-index: 1;
}
.form-img img{
    width: 360px;
    height: 460px;
    margin: 25px 0 0 0 ;
}

.form-and-side{
    display: flex;
    flex-direction: column;
    border-radius:12px;
    box-shadow: 0px 8px 13px rgb(86, 85, 85);
    height: 90%;
    width: 80%;
background-color: white;
}
.form-sidenote{
    position: relative;
    width: 100%;
    height: 520px;
    display: flex;
    align-items: center;
    color: white;
}
.form-sidenote-wrap{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.322);
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px) ;
    
    width: 100%;
    display: flex;
    flex-direction: column;
    
   gap:40px
}
.form-sidenote-wrap-bottom {
    display: grid;
   grid-template-columns: 200px 200px;
   grid-template-rows: 100px ;
    align-self: center;
   width: 100%;
   margin-top: 20px;
   margin-left: 15px;
}
.form-sidenote-wrap-bottom div{
    display: flex;
    flex-direction: column;
   align-items: flex-start;
   width: 70%;
   gap:7px
}
.form-content form{
    display:flex;
    flex-direction: column;
    border:none;
    align-items: flex-start;
    
    width:100%;
    gap:10px;
    padding: 40px 10px 10px 25px; 
    
    } 


.form-sidenote-wrap div h2{
font-size: 0.9rem;
text-align: start;
margin-top: 90px;

margin-left: 27px;
}
.form-sidenote-wrap div .form-p{
    font-size: 0.8rem;
    align-self: flex-start;
    width: 270px;
    margin-top: 10px;
}


}


@media screen and (max-width:482px){
    .success-message{
       
        top:75px;
        left:5%;
        width: -moz-fit-content;
width: fit-content;
        ;
    }
    .success-message2{
       
        top:165px;
        left:15%;
        width: 240px;
        ;
    }
    .form-content{
        display: flex;
        flex-direction: column;
        height:70rem;
        margin-top: 120px;
        overflow-y: auto;
    }
    .agree{
        font-size:0.6rem;
        width:240px
    }
   
.apply-contents{
    width:100%;
    height: 100%;
    padding: 0px 0 0 40px;  
    gap:55px ;

}
.form-sidenote-wrap2 p{
    font-size: 0.7rem;
    width:230px;
    text-align: start;
}
.form-content form input{
    width: 180px;    
}
.form-content{
   margin-left: -10px;
}
.form-img img{
    width: 320px;
    height: 430px;
    margin: 25px 0 0 0 ;
}


.form-and-side{
   
    width: 95%;
    height: calc(100% + 684px);
}

.form-sidenote-wrap-bottom {
    display: grid;
   grid-template-columns: 120px 120px;
   grid-template-rows: 100px ;
    align-self: center;
   width: 100%;
   margin-top: 0px;
   margin-left: 0px;
}
.form-sidenote-wrap-bottom div{
    display: flex;
    flex-direction: column;
   align-items: flex-start;
   width: 70%;
   gap:7px
}
.form-content form{
    display:flex;
    flex-direction: column;
    border:none;
    align-items: flex-start;
    width:100%;
    height: 100%;
    gap:10px;
    padding: 40px 0px 10px 25px; 
    
    } 
    .fileattachment-lable{
        margin-top: 16px;
    }
    .form-content form div input{
        width:220px
    }


    .form-sidenote-wrap div h2{
        font-size: 0.8rem;
        text-align: start;
margin-top: 70px;

margin-left: 27px;
}
.form-sidenote-wrap-bottom div p{
    font-size: 0.67rem;
    width: 170px;
}
.applyform-top {
  display: flex;
  flex-direction: column;
  gap:22px;
  padding-left: 26px;
}
.form-sidenote{
    height:437px;
    padding-bottom: 48px;
}
.form-sidenote-wrap div{
    padding-left: 12px;
}
}


@media screen and (max-width:392px){
    .form-content form{
        width:300px;
        gap:10px;
        padding: 40px 0px 10px 25px; 
        
    } 
    .form-sidenote-wrap-bottom{
        
       width: 370px;
    }
    .form-sidenote-wrap-bottom div p{
        font-size: 0.6rem;
    }
    

    .form-content form input{
        width: 190px;    
    }
}


@media screen and (max-width:365px){
    
        .form-sidenote-wrap-bottom div p{
            font-size: 0.6rem;
            align-self: flex-start;
            width: 140px;
            margin-top: 0;
        }
        .form-sidenote-wrap-bottom{
        
            width: 325px;
         }
}
@media screen and (max-width:325px){
    .Apply{
        width:70dw;
        height: 120vh;
        display: flex;
        align-items: center;
        overflow-y: auto;
    }
.form-content form{
        width:260px;
        gap:10px;
        padding: 40px 20px 10px 65px; 
        
        } 
        .agree{
            font-size:0.6rem;
            width:200px
        }
        .form-content form textarea{
width:180px
        }
        .form-content form lable{
            font-size: 0.8rem;
        }
    .form-content form input{
        width: 160px;    
    }
    .apply-contents{
        width:100%;
        height: 80%;
        padding: 0px 0 0 20px;  
        gap:35px ;
    }
    .form-sidenote-wrap div h2{
        font-size: 0.8rem;
        text-align: start;
        margin-top: 40px;
        
        margin-left: 27px;
    }
    .form-sidenote-wrap div .form-p{
        font-size: 0.6rem;
        width:202px;
    }
    .form-sidenote-wrap-bottom{
        
        width: 305px;
        font-size: 0.6rem;
    }
    
    .form-sidenote-wrap-bottom div p{
        
        font-size: 0.5rem;
    }
    .form-content{
        margin-left: 2px;
        height: 100%;
    }
    .form-sidenote-wrap2 p{
        font-size: 0.7rem;
        width:122px;
        text-align: start;
    }
    
.applyform-top {
    padding-left: 18px;
}
}