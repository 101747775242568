@import url('https://fonts.googleapis.com/css2?family=Sedgwick+Ave&display=swap');


@media screen and (max-width:2225px){

.step{
  display:flex;
width:100%;
height:100dvh;
background-color: #ffbb98;

}
.left-half{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width:85%;
  padding: 60px 0 80px 80px;
  position: relative;
  
}
.left-half > h1{
  margin-right: -350px;
  font-family: 'Sedgwick Ave', cursive;
  font-size: 3.3rem;
}

.elipse-d{
  display: flex;
  color: #bd633b;
  
}
.elipse-d div{
 border-radius: 100%;
 box-shadow: -8px 8px 14px rgb(56, 56, 56);
 
}
.elipse1{
position: absolute;
top:70px;
left:30px;
width:65px ;
height: 65px;
background-color: #bd633b;
}
.elipse2{
  position: absolute;
  top:130px;
  left:400px;
  width:45px ;
  height: 45px;
  background-color: #bd633b;
  }
  .elipse3{
    position: absolute;
    top:520px;
    width:45px ;
    height: 45px;
    background-color: #bd633b;
  }

  .elipse4{
    position: absolute;
    top:570px;
    left: 390px;
    background-color: #bd633b;
  }

.left-content{
  display: flex;
  justify-content: space-between;
  width:85%;
}

.left-content .left-cont-left, .right-cont div{
  width:480px;
  height: 570px;
  background-color: black;
  z-index: 6;
}

.left-content .left-cont-right{
  width:580px;
  height: 670px;
  background-color: black;
   z-index: 6;
 
}
.left-content .left-cont-left{
  margin-top: 50px;
  width:490px;
  background-color: black;
}
.right-content{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width:28%;
}
.dd{
  height: 218px;
  width: 220px;
}

.djd{
  height: 127px;
  width: 120px;
}
.right-leafimg{
  margin-right: -320px;
  opacity: 0.7;
}
.right-cont{
  height: 57%;
  width:100%;
}

.right-cont div{
  margin-top:-85px;
  margin-left:-130px;

}
.left-cont-left, .left-cont-right ,.right-cont-right{
display: flex;
flex-direction: column;
align-items: center;
justify-content: space-between;
padding: 40px 0px 0 0px;
}

.left-cont-left div, .left-cont-right div ,.right-cont-right div{
padding: 0 15px;
color:white;
height:45%;
width: 100%;
background-color:#582e2ebb;
align-self: flex-end;
}
.left-cont-left div p ,.right-cont-right div p{
margin-top: -35px;
font-size: 1.2rem;
width:440px
}
.left-cont-right div p{
  margin-top: -63px;
  font-size: 1.4rem;
}
.left-cont-left img, .left-cont-right img ,.right-cont-right img{
  width:115px ;
  height:85px;
}
.left-cont-right img{
  width:140px ;
  height:140px;
}


}

@media screen and (max-width:1765px){


  .left-content .left-cont-left{
    width:430px;
    height: 530px;
    background-color: black;
    z-index: 6;
  }
  
  .left-content .left-cont-right{
    width:540px;
    height: 630px;
    background-color: black;
     z-index: 6;
   
  }
  .left-content .left-cont-left{
    margin-top: 50px;
    width:480px;
    background-color: black;
  }
  .left-cont-left div p ,.right-cont-right div p{
   
    font-size: 1rem;
    width:392px
    }
    .left-cont-left div, .left-cont-right div ,.right-cont-right div{
      padding: 0 25px;
      color:white;
      height:45%;
      width: 100%;
      background-color:#c70606bb;
      align-self: flex-end;
      }
      .right-cont div{
        margin-top:-216px;
      
      }
      .left-half > h1{
        margin-right: -350px;
        font-family: 'Sedgwick Ave', cursive;
        font-size: 3rem;
      }
}



@media screen and (max-width:1655px){


  .left-content .left-cont-left{
   height: 530px;
    background-color: black;
    z-index: 6;
  }
  
  .left-content .left-cont-right{
    width:520px;
    height: 630px;
    background-color: black;
     z-index: 6;
   
  }
  .left-content .left-cont-left{
    margin-top: 50px;
    width:430px;
    background-color: black;
  }
  .left-cont-left div p ,.right-cont-right div p{
   
    font-size: 1rem;
    width:392px
    }
    .left-cont-left div, .left-cont-right div ,.right-cont-right div{
      padding: 0 25px;
      color:white;
      height:45%;
      width: 100%;
      background-color:#c70606bb;
      align-self: flex-end;
      }
      .right-cont div{
        margin-top:-190px;
      
      }
      .left-half > h1{
        margin-right: -350px;
        font-family: 'Sedgwick Ave', cursive;
        font-size: 2.3rem;
      }
}


@media screen and (max-width:1519px){
  
  
  .left-content .left-cont-left, .right-cont div{
    width:394px;
  }
  
  .left-content .left-cont-right{
    width:480px;
  
   
  }
  .left-content .left-cont-left{
    margin-top: 50px;
    width:394px;
    background-color: black;
  }
  .right-content{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width:28%;
  }
   .left-cont-left div p ,.right-cont-right div p{
    
     font-size: 1rem;
     width:392px
     }
     .left-cont-left div, .left-cont-right div ,.right-cont-right div{
       padding: 0 25px;
       color:white;
       height:45%;
       width: 100%;
       background-color:#c70606bb;
       align-self: flex-end;
       }
       .right-cont div{
         margin-top:-180px;
       
       }
       .left-half > h1{
         margin-right: -350px;
         font-family: 'Sedgwick Ave', cursive;
         font-size: 2.3rem;
       }
       .left-cont-left div p ,.right-cont-right div p{
   
        font-size: 0.8rem;
        width:322px
        }
  }


@media screen and (max-width:1370px){
.step{
  display:flex;
width:100%;
height:100dvh;
background-color: #ffbb98;

}
.left-half{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width:85%;
  padding: 60px 0 80px 80px;
  position: relative;
  
}
.left-half > h1{
  margin-right: -320px;
  font-family: 'Sedgwick Ave', cursive;
  font-size: 1.8rem;
}
.elipse-d{
  display: flex;
  color: #bd633b;
  
}
.elipse-d div{
 border-radius: 100%;
 box-shadow: -8px 8px 14px rgb(56, 56, 56);
 
}
.elipse1{
position: absolute;
top:70px;
left:30px;
width:65px ;
height: 65px;
background-color: #bd633b;
}
.elipse2{
  position: absolute;
  top:130px;
  left:400px;
  width:45px ;
  height: 45px;
  background-color: #bd633b;
  }
  .elipse3{
    position: absolute;
    top:520px;
    width:45px ;
    height: 45px;
    background-color: #bd633b;
  }

  .elipse4{
    position: absolute;
    top:570px;
    left: 390px;
    background-color: #bd633b;
  }

.left-content{
  display: flex;
  justify-content: space-between;
  width:85%;
}

.left-content .left-cont-left, .right-cont div{
  width:290px;
  height: 300px;
  background-color: black;
  z-index: 6;
}

.left-content .left-cont-right{
  width:330px;
  height: 400px;
  background-color: black;
   z-index: 6;
 
}
.left-content .left-cont-left{
  margin-top: 50px;
}
.right-content{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width:34%;
}
.dd{
  height: 98px;
  width: 120px;
}

.djd{
  height: 77px;
  width: 70px;
}
.right-leafimg{
  margin-right: -270px;
  opacity: 0.7;
}
.right-cont{
  height: 43%;
  width:100%;
}

.right-cont div{
  margin-top:-155px;
  margin-left:0;
}
.left-cont-left, .left-cont-right ,.right-cont-right{
display: flex;
flex-direction: column;
align-items: center;
justify-content: space-between;
padding: 40px 0px 0 0px;
}

.left-cont-left div, .left-cont-right div ,.right-cont-right div{
padding: 0 25px;
color:white;
height:45%;
width: 100%;
background-color:#c70606bb;
align-self: flex-end;
}
.left-cont-left div p ,.right-cont-right div p{
margin-top: -35px;
font-size: 0.8rem;
width:220px
}
.left-cont-right div p{
  margin-top: -63px;
  font-size: 0.9rem;
}
.left-cont-left img, .left-cont-right img ,.right-cont-right img{
  width:115px ;
  height:85px;
}
.left-cont-right img{
  width:140px ;
  height:140px;
}
}




@media screen and (max-width:835px){
  .step{
    display:flex;
    flex-direction: column;
    align-items: center;
  width:100%;
  height:85rem;
  background-color: #ffbb98;
  
  }

  .left-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:50px;
    width:90%;
  
  }
  .left-half{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width:100%;
    height:60%;
    padding: 60px 0 0px 80px;
    position: relative;
   
    
  }
  .right-content{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width:100%;
    height:100%;
    padding-left:280px;
  }
  .right-leafimg{
   display: none;
  }
  .left-half > h1{
    margin-right: 0px;
    z-index: 1;
   
  }
}


@media screen and (max-width:435px){

  .right-content{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width:100%;
    height:100%;
    padding-left:90px;
  }
  .elipse3{
    position: absolute;
    top:570px;
    left:30px;
  }
}


@media screen and (max-width:390px){
  .left-half{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width:100%;
    height:60%;
    padding: 60px 0 0px 40px;
    position: relative;
   
    
  }
  .right-content{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width:100%;
    height:100%;
    padding-left:55px;
  }
}

@media screen and (max-width:362px){
  .right-content{
    padding-left:40px;
  }
}
@media screen and (max-width:320px){
  .left-half{
  
    padding: 60px 0 0px 20px;
    position: relative;
   
    
  }
  .right-content{
  
    padding-left:15px;
  }

  
}