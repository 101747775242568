/* change program height when doing small screen */
@media screen and (max-width:2225px){
    
    .program{
        display: flex;
        justify-content: center;
        width: 100%;
        height: 224rem;
        margin-top: 90px;
        background-color:white
    }
 
    .program-contents{
        height:100%;
        display:flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
       
    }
    
    .program-content-header div{
        display:grid;
        grid-template-columns: 580px 580px;
        grid-template-rows: 220px 220px 220px;
    }
    
    .program-content-header{
       display: flex;
       flex-direction: column;
       align-items: center;
       background-color: #ededed;
        height:594px;
        width:1280px;
        gap:35px;
        box-shadow: -8px 8px 13px rgb(63, 63, 63);
        padding: 35px 0px;
        border-radius: 12px;
        text-align: left;
    }
    .program-content-header ul li{
        width:420px;
        list-style-type: none;
        font-size: 1.9rem;
       
    
    }
    
    
    .info-last-div {
        list-style: none;
        padding-bottom: 65px;
        display: flex;
        flex-direction: column;
        gap:23px
    }
   
    .haravardlink a{
        color: blue;
    }
    
    
    .program-content-header img{
       width:480px;
       height:82px;
       align-self: flex-start;
       margin-left: 60px;
    
    }
    
    .whyaccekeratr div {
        font-size: 1.9rem;
        display: flex;
        flex-direction: column;
        gap:20px;
        width: 73%;
        text-align: left;
    
    }
    .whyaccekeratr {
        display: flex;
        flex-direction: column;
        gap:40px;
        align-items: center;
    }
    .whyaccekeratr div p{
        width:85%;
        font-size: 1.9rem;
        
          padding-left: 12px;
        
    }
    .whyaccekeratrsecond{
        background-color: rgba(0, 0, 0, 0.616);
        padding: 0px 24px;
        border-radius: 12px;
        color: white;
    }
    .whyaccekeratrBlue{
        border-left: 4px solid rgba(21, 0, 90, 0.646);
      padding-left: 12px;
    }
    .whathappensintro {
        font-size: 1.8rem;
    }
}


@media screen and (max-width:1975px){
    
    .program{
        display: flex;
        justify-content: center;
        width: 100%;
        height: 138rem;
        margin-top: 90px;
        background-color:white
    }
 
    .program-contents{
        height:100%;
        display:flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
       
    }
    
    .program-content-header div{
        display:grid;
        grid-template-columns: 580px 580px;
        grid-template-rows: 220px 220px 220px;
    }
    
    .program-content-header{
       display: flex;
       flex-direction: column;
       align-items: center;
       background-color: #ededed;
        height:594px;
        width:1280px;
        gap:35px;
        box-shadow: -8px 8px 13px rgb(63, 63, 63);
        padding: 35px 0px;
        border-radius: 12px;
        text-align: left;
    }
    .program-content-header ul li{
        width:420px;
        list-style-type: none;
        font-size: 1.6rem;
       
    
    }
    
    
    .info-last-div {
        list-style: none;
        padding-bottom: 65px;
        display: flex;
        flex-direction: column;
        gap:23px
    }
   
    .haravardlink a{
        color: blue;
    }
    
    
    .program-content-header img{
       width:480px;
       height:82px;
       align-self: flex-start;
       margin-left: 60px;
    
    }
    
    .whyaccekeratr div {
        font-size: 0.9rem;
        display: flex;
        flex-direction: column;
        gap:20px;
        width: 73%;
        text-align: left;
    
    }
    .whyaccekeratr {
        display: flex;
        flex-direction: column;
        gap:40px;
        align-items: center;
    }
    .whyaccekeratr div p{
        width:85%;
        font-size: 1.2rem;
        
          padding-left: 12px;
        
    }
    .whyaccekeratrsecond{
        background-color: rgba(0, 0, 0, 0.616);
        padding: 0px 24px;
        border-radius: 12px;
        color: white;
    }
    .whyaccekeratrBlue{
        border-left: 4px solid rgba(21, 0, 90, 0.646);
      padding-left: 12px;
    }
    .whathappensintro {
        font-size: 1.2rem;
    }
}

@media screen and (max-width:1768px){
    .program{
        
        height: 142rem;
    }
}
@media screen and (max-width:1598px){
    .program{
        
        height: 138rem;
    }
    .program-content-header ul li{
        width:450px;
        list-style-type: none;
        font-size: 1.2rem;
    
    }
    
    
    .program-content-header div{
        display:grid;
        grid-template-columns: 490px 490px;
        grid-template-rows: 170px 170px 170px;
    }
    
    .program-content-header{
       display: flex;
       flex-direction: column;
       align-items: center;
        height:496px;
        width:1120px;
        gap:35px;
        box-shadow: -8px 8px 13px rgb(63, 63, 63);
        padding: 35px 0px;
        text-align: left;
    }
    .program-content-header img{
        width:330px;
        height:85px;
     
     } 
     .whyaccekeratr div {
        font-size: 0.9rem;
        display: flex;
        flex-direction: column;
        gap:20px;
        width: 76%;
        
        }
     
}


    @media screen and (max-width:1396px){
        
        .program{
    display: flex;
    width: 108%;
    justify-content: center;
    height: 124rem;
    margin-top: 90px;
}
.program-leafimg img{
    width: 240px;
    height: 245px;
    
}
.program-contents{
    height:100%;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
   
}

.program-content-header div{
    display:grid;
    grid-template-columns: 400px 400px;
    grid-template-rows: 120px 120px 120px;
}
.info-last-div div li{
    line-height: 1.4rem;
}
.program-content-header{
   display: flex;
   flex-direction: column;
   align-items: center;
    
    height:360px;
    width:880px;
    gap:35px;
    box-shadow: -8px 8px 13px rgb(63, 63, 63);
    padding: 35px 0px;
    text-align: left;
}
.program-content-header ul li{
    width:350px;
    list-style-type: none;
font-size: 1rem;
margin-top: 12px;
}


.info-last-div {
    list-style: none;
    padding-bottom: 172px;
}


.haravardlink a{
    color: blue;
}


.program-content-header img{
   width:280px;
   height:45px;
   align-self: flex-start;
   margin-left: 60px;

}

.whyaccekeratr div {
    font-size: 0.9rem;
    display: flex;
    flex-direction: column;
    gap:20px;
    width: 73%;
    
  
    }
    .whyaccekeratr {
        display: flex;
        flex-direction: column;
        gap:20px;
        align-items: center;
        
        }
        .whyaccekeratr div p{
    
    width:85%;
  font-size: 0.9rem;
  padding-left: 12px;
}
.whathappensintro {
    font-size: 1rem;
}

    }

    @media screen and (max-width:1279px){
        
        .program{
    height: 130rem;
}
}


@media screen and (max-width:835px){
    .program-content-header div{
        display:grid;
        grid-template-columns: 300px 300px;
        grid-template-rows: 120px 120px 120px;
    }
    .program-content-header ul li{
        width:280px;
    
    } 
    .program-content-header{
         width:650px;
         gap:35px;
     }
     .program-leafimg img{
        width: 140px;
        height: 200px;
    }
    .program{
        height: 142rem;   
    }
}

@media screen and (max-width:800px){
        
    .program{
height: 152rem;
}
}

@media screen and (max-width:482px){

    .program{
        display: flex;
        flex-direction: column;
        height: 190rem;
       margin-top: 27px;
    }
    .program-contents{
       padding: 25px;
    }
    .program-content-header div{
        display:grid;
        grid-template-columns: 300px ;
        grid-template-rows: 120px 120px 120px 120px;
    }
    .whyaccekeratr div {
        width: 90%;
    
    }
   .info-last-div {
        list-style: none;
        padding-bottom: 45px;
        
    }
    
.program-content-header{
    display: flex;
    flex-direction: column;
    align-items: center;
     height:580px;
     width:354px;
     gap:35px;
     padding-left: 20px;
     opacity: 0.7;
     margin-left: -35px;
 }
 .program-content-header img{
    
    margin-left: 30px;
 
 }

 .program-leafimg img{
    width: 240px;
    height: 245px;
    margin: 0 0px -300px -300px;
}
}


@media screen and (max-width:418px){
    .program{
        height: 192rem;
       margin-top: 27px;
    }
}
@media screen and (max-width:392px){
    .program{
        height: 202rem;
       margin-top: 27px;
    }
.program-contents{
    font-size: 0.9rem;
    padding-left: 30px;
}
.whathappens{
    font-size: 1.6rem;
}
   
.program-content-header{
    display: flex;
    flex-direction: column;
    align-items: center;
     height:580px;
     width:325px;
     padding-left: 0px;
}
.program-content-header img{
    width:245px;
    margin-left: 40px;
 
 }
}

@media screen and (max-width:379px){
    .program{
        height: 208rem;
       margin-top: 27px;
    }
}

@media screen and (max-width:365px){
    .program{
        height: 214rem;
       margin-top: 27px;
    }
    .program-contents{
        font-size: 0.7rem;
        padding-left: 20px;
    }
    
       
    .program-content-header{
         width:320px;
         margin-left: -15px;
         height:680px;
    }
    .program-content-header div{
        display:grid;
        grid-template-columns: 300px ;
        grid-template-rows: 140px 140px 140px 140px;
    
}.program-content-header img{
    width:230px;
    height:30px;
    align-self: flex-start;
    margin-left: 26px;
 
 }
}


@media screen and (max-width:335px){ 

        .program{
            height: 246rem;
           margin-top: 27px;
        }
    .program-contents{
        font-size: 0.7rem;
        padding-left: 20px;
    }
    
       
    .program-content-header{
         width:270px;
         padding-left: 12px;
         height:880px;
    }
    .program-content-header img{
        width:175px;
        height: 30px;
        margin-left: 20px;
     
     }
     .program-content-header div ul li{
        width:180px
    }
     .program-content-header div{
        display:grid;
        grid-template-columns: 200px ;
        grid-template-rows: 180px 230px 180px 140px;
    }.whathappens{
        font-size: 1.3rem;
    }
}